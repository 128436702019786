import styled from '@emotion/styled';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { BodySmall } from '../../../text';
import Button, { ButtonProps } from '../Button';
import IconButton from '../IconButton';

export const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  z-index: 1;
  margin-right: 8px;
  margin-left: auto;
  svg {
    fill: none;
  }
`;

const ButtonChipStyled = styled(Button)`
  border-radius: 12px;
`;

const ChipText = styled(BodySmall)`
  margin: 0 16px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;

type Props = ButtonProps & {
  onClickDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

// <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M7 12H17" stroke="#333333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>

const ChipRemove = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="8" stroke="#666666" strokeWidth="2" />
    <path
      d="M9.4639 14.5359L11.9994 12.0004M14.535 9.46483L11.9994 12.0004M11.9994 12.0004L9.4639 9.46483M11.9994 12.0004L14.535 14.5359"
      stroke="#666666"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Chip: React.FC<Props> = ({
  text,
  onClick,
  onClickDelete,
  showLoading,
  disabled,
  className,
  backgroundColor,
  iconLeft,
  style,
}) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  return (
    <ButtonChipStyled
      iconLeft={iconLeft}
      onClick={onClick ? handleClick : undefined}
      className={cx(className, disabled && 'disabled')}
      backgroundColor={backgroundColor}
      style={style}
    >
      {text && <ChipText>{text}</ChipText>}

      {!!onClickDelete && (
        <StyledIconButton onClick={onClickDelete} showLoading={showLoading}>
          {ChipRemove}
        </StyledIconButton>
      )}
    </ButtonChipStyled>
  );
};

export default Chip;
