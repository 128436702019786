import { css } from '@emotion/react';

export const socialWrapperStyle = css`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  min-width: 0;
  gap: 16px;
  justify-content: center;

  @container template-sizer (width > 512px) {
    justify-content: flex-start;
  }

  @container template-sizer (width > 1024px) {
    gap: 32px;
  }
`;

export const socialIconStyle = css`
  width: 24px;
  height: 24px;
  display: flex;
  transition: opacity 0.15s ease-in-out;
  align-items: center;
  justify-content: center;

  @container template-sizer (width > 1024px) {
    width: 32px;
    height: 32px;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: var(--gray-100);
  }

  :hover {
    opacity: 0.75;
  }
`;
