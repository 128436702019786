import styled from '@emotion/styled';
import { Card, Icons, Window } from '@imus/services-ui';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const VideoCardStyled = styled(Card)`
  overflow: hidden;
  cursor: pointer;
  .react-player__preview {
    background-size: auto !important;
  }
  & > * {
    pointer-events: none;
  }
  :hover {
    opacity: 0.75;
  }
`;

const WindowStyled = styled(Window)`
  margin: 24px;
  width: 100%;
  align-self: center;
  max-width: 1280px;
  aspect-ratio: 16/9;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayIconStyled = styled(Icons.PlayIcon)`
  fill: var(--bg-1);
`;

const PlayIconWrapper = styled.div`
  background: rgba(78, 78, 78, 0.9);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const VideoCard = ({ url }: { url: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);
  return (
    <>
      <VideoCardStyled
        onClick={(event) => {
          setIsOpen(true);
          event?.stopPropagation();
          event?.preventDefault();
        }}
      >
        {hasWindow && (
          <ReactPlayer
            playIcon={
              <PlayIconWrapper>
                <PlayIconStyled />
              </PlayIconWrapper>
            }
            width="100%"
            height="100%"
            light={true}
            url={url}
          />
        )}
      </VideoCardStyled>
      <WindowStyled
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      >
        {hasWindow && (
          <ReactPlayer
            controls={true}
            width="100%"
            height="100%"
            style={{ overflow: 'hidden' }}
            url={url}
          />
        )}
      </WindowStyled>
    </>
  );
};
