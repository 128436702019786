import React, { useCallback, memo, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { useEntriesProvider, useEntries, useCustomerFeature } from 'imddata';
import { HelpWindowContext, OptionGridItem, H4 } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';
// import { AnimatePresence, motion } from 'framer-motion';
import SelectField from '../SelectField';
import type { DropdownMenuBaseProps } from '../DropdownMenu';
import { useSubscriptionUpsell } from '../../logic';
import { AmpSquareIcon } from '@imus/base-ui';

const RowWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;

  > * {
    flex: 1;
    min-width: 0%;
  }

  > *:not(:last-child) {
    margin-bottom: 0px;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;

    > * {
      min-width: 100%;
    }

    > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

type Props = DropdownMenuBaseProps<{ label: string; value: number }, false> & {
  mode?: 'inline' | 'grid';
  enableFeatureCheck?: boolean;
  valueOverride?: string;
  input: WrappedFieldProps['input'];
  meta: WrappedFieldProps['meta'];
};

const sortGenres = (genreA: EntityModels.Genre, genreB: EntityModels.Genre) => {
  if (genreA.name < genreB.name) {
    return -1;
  }
  if (genreA.name > genreB.name) {
    return 1;
  }
  return 0;
};

const query = {
  'filter.is_main_genre': 1,
};

const GenreItem = memo(
  ({
    disabled,
    value,
    onChange,
    selected,
    label,
    testId,
  }: {
    disabled?: boolean;
    value: number;
    onChange: (v: number) => void;
    selected: boolean;
    label: string;
    testId: string;
  }) => {
    const handleChange = useCallback(() => {
      onChange(value);
    }, [value, onChange]);
    return (
      <OptionGridItem
        disabled={disabled}
        onClick={handleChange}
        selected={selected}
        data-test-id={testId}
      >
        {label}
      </OptionGridItem>
    );
  }
);

GenreItem.displayName = 'GenreItem';

const GenreSelectField: React.FC<Props> = ({
  enableFeatureCheck = true,
  ...props
}) => {
  const { t } = useTranslation();

  const showHelpWindow = useContext(HelpWindowContext);
  const {
    request: { loading },
  } = useEntries<EntityModels.Genre>({ entity: 'genres', query });
  const { entries, entities } = useEntriesProvider<EntityModels.Genre>({
    entity: 'genres',
  });
  const enableClassicalGenreFeature = useCustomerFeature(
    'deliver-classical-releases'
  );
  const enableClassicalGenre =
    !enableFeatureCheck || enableClassicalGenreFeature;

  const { open: openUpsell } = useSubscriptionUpsell();

  const mapGenreForInline = (genre: EntityModels.Genre) => ({
    value: genre.id,
    icon:
      !enableClassicalGenre && genre.handler === 'classical' ? (
        <AmpSquareIcon />
      ) : undefined,
    label: genre.subGenres ? t(genre.name) : t(genre.name),
    children: genre.subGenres ? <H4>{t(genre.name)}</H4> : t(genre.name),
  });

  const sortedMainGenres = entries
    .filter((genre) => !genre.parentId)
    .sort((a, b) => a.id - b.id);

  const allInOne = sortedMainGenres
    .reduce<
      typeof sortedMainGenres
    >((acc, mainGenre) => [...acc, mainGenre, ...(mainGenre.subGenres?.map((id) => entities[id]).sort(sortGenres) || [])], [])
    .map(mapGenreForInline);

  const handleClickHelp = useCallback(() => {
    showHelpWindow(t('genre'), t('release-helptext-genre'));
  }, []);

  const input = useMemo(() => {
    return {
      ...props.input,
      onChange: (genreId: any) => {
        const genre = entities[genreId];
        if (!genre) {
          return;
        }
        if (!enableClassicalGenre && genre.handler === 'classical') {
          openUpsell({
            analytics: { detail: 'genre' },
          });
          return;
        }
        props.input.onChange(genreId);
      },
    };
  }, [props.input, entities]);

  return (
    <div>
      <RowWrapper>
        <SelectField
          label={t('genre')}
          onClickHelp={handleClickHelp}
          {...props}
          key="main-genre-menu"
          input={input}
          isLoading={loading}
          data={allInOne}
        />
      </RowWrapper>
    </div>
  );
};

export default GenreSelectField;
