import styled from '@emotion/styled';
import { Content } from '../../../text';

export type Variant = 'standart' | 'black';

export const Wrapper = styled.div<{ variant: Variant }>`
  position: relative;
  display: inline-flex;
  white-space: pre;
  flex-wrap: wrap;
  min-height: 40px;
  overflow: hidden;
  justify-content: center;

  ${({ variant = 'standart' }) =>
    variant === 'standart'
      ? `
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 12px;

`
      : `
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
`}

  * {
    user-select: none;
  }
`;

export const ItemWrapper = styled.div<{
  variant: Variant;
  selected: boolean;
}>`
  min-height: 32px;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 4px 0 4px 4px;
  cursor: pointer;
  ${({ variant, theme, selected }) =>
    variant === 'standart'
      ? `

  padding: 0 16px;
  background-color: ${selected ? 'white' : 'none'};
  box-shadow: ${selected ? '0px 3px 8px -2px rgba(0, 0, 0, 0.11)' : 'none'};
  border-radius: 8px;

  ${Content} {
    color: ${selected ? theme.foreground.primary : theme.foreground.secondary};
  }

`
      : `
  padding: 0 12px;
  background-color: ${selected ? 'white' : 'none'};
  border-radius: 20px;
  ${Content} {
    color: ${selected ? 'rgba(0, 0, 0, 0.8)' : 'white'};
  }
`}

  :last-child {
    margin-right: 4px;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: all 0.1s ease-in-out;
  }

  :hover::before,
  :focus::before {
    background: ${({ selected }) => (selected ? 'none' : 'rgba(0, 0, 0, 0.1)')};
  }
`;
