import {
  decamelize,
  camelizeKeys,
  camelize as camelizeFunc,
  decamelizeKeys,
} from 'humps';
import config from 'imdconfig';
import 'whatwg-fetch';
import qs from 'qs';
import * as R from 'ramda';
import {
  defaultGetSchema,
  defaultNormalizeResponse,
  normalizeGrouped,
} from '../schemas/responseNormalizers';
import type { AuthToken, EmptyToken } from '../utils/auth';
import { getAuth } from '../utils/auth';
import formatToAPI from './formatToAPI';

function decamelizeCallback(key: string, convert: any, options: any): string {
  switch (key) {
    case 'paymentsRequire3dSecure':
      return 'payments_require_3d_secure';
    case 'accountHolderAddress2':
      return 'account_holder_address_2';
    case 'address2':
      return 'address_2';
    default:
      return convert(key, options);
  }
}

const camelizeKeysDefault = (input: any) => {
  return camelizeKeys(input, (key, convert) => {
    if (/tier-.*/.test(key)) return key;
    if (/trial-.*/.test(key)) return key;
    if (/professional-.*/.test(key)) return key;
    if (/music-.*/.test(key)) return key;
    if (/enhanced-.*/.test(key)) return key;
    return convert(key);
  });
};

interface CallApiParams {
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  endpoint: string;
  schema?: Record<string, unknown> | ReadonlyArray<Record<string, unknown>>;
  payloadFormatter?: (data: Record<string, unknown>) => Record<string, unknown>;
  query?: any;
  selectData?: any;
  normalizer?: any;
  impersonate?: any;
  options?: any;
  headers?: any;
  bySchemas?: any;
  external?: boolean;
  camelize?: boolean;
  base: string;
}

const reduceSortBy = (
  acc: string,
  { dataKey, direction }: { dataKey: string; direction: 'asc' | 'desc' }
): string => `${(acc ? `${acc},` : acc) + decamelize(dataKey)},${direction}`;

export const handleSortBy = (query: any): any => {
  if (!query) return {};
  if (!query.sortBy || !Array.isArray(query.sortBy)) {
    return query;
  }

  const sortBy = query.sortBy.reduce(reduceSortBy, '');

  return {
    ...query,
    sortBy,
  };
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function formatPath(path: string, external: boolean | string): string {
  if (!path) return '/';
  if (typeof external === 'boolean') return path;
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  if (typeof external === 'string') return external + adjustedPath;

  return (
    config.apiUrl +
    (adjustedPath.includes('/oauth') ? '' : '/v3') +
    adjustedPath
  );
}

export function formatUrl(
  endpoint: string,
  external: boolean | string,
  query: any = {},
  impersonate?: Record<string, unknown> | null | undefined
): string {
  const formattedPath = formatPath(endpoint, external);

  const finalQuery = query ? { ...query, ...(impersonate || {}) } : impersonate;

  const queryString = R.compose(
    qs.stringify,
    decamelizeKeys,
    handleSortBy,
    R.filter((val: any) => val !== null),
    R.omit(['by'])
  )(finalQuery);

  return `${formattedPath}${query?.by ? `/by/${query?.by}` : ''}${
    queryString.length ? `?${queryString}` : ''
  }`;
}

interface SuccessfulResponse {
  json: Record<string, unknown>;
  response: Record<string, unknown>;
  rawResponse: Record<string, unknown>;
}
interface ApiErrorResponse {
  statusCode: number;
  shouldRetry: boolean;
  error: {
    message: string;
    data: Record<string, unknown> | null;
    errors: Record<string, unknown> | null;
    inputNames: string[] | null;
    validationError: Record<string, unknown> | null;
  };
}
interface FetchErrorResponse {
  error: {
    message: string;
    failedToFetch: boolean;
  };
  response: null;
  json: Record<string, unknown>;
}

const defaultPayloadFormatter = (data: any) =>
  formatToAPI(decamelizeKeys(data, decamelizeCallback));

const isAuthToken = (token: AuthToken | EmptyToken): token is AuthToken => {
  if ((token as EmptyToken).emptyToken) return false;

  return true;
};

export default function callApi({
  selectData,
  normalizer,
  endpoint,
  schema,
  query,
  bySchemas, // TODO deprecate specific usage for groupped
  impersonate,
  options = { method: 'GET' },
  camelize = true, // TODO deprecate
  headers = {},
  external = false,
  payloadFormatter = defaultPayloadFormatter,
  base,
}: CallApiParams): Promise<
  SuccessfulResponse | ApiErrorResponse | FetchErrorResponse
> {
  const authFromLocalStorage = getAuth();
  const authHeaders =
    authFromLocalStorage && isAuthToken(authFromLocalStorage) && !external
      ? {
          Authorization: `${
            authFromLocalStorage.tokenType || authFromLocalStorage.token_type
          } ${
            authFromLocalStorage.accessToken ||
            authFromLocalStorage.access_token
          }`,
        }
      : {};

  const fetchOptions: any = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    },
    mode: 'cors',
    method: options.method,
  };

  // Request with GET/HEAD method cannot have body
  if (['GET', 'HEAD'].indexOf(fetchOptions.method) === -1) {
    fetchOptions.body = JSON.stringify(payloadFormatter(options.data));
  }

  const fullUrl = formatUrl(endpoint, external || base, query, impersonate);

  return fetch(fullUrl, fetchOptions)
    .then((response) => {
      return response
        .json()
        .then((json) => ({ json, response, error: undefined }))
        .catch((error) => ({
          error,
          response,
          json: null,
        }));
    })
    .then(({ json, response, error: networkError }) => {
      if (!response?.ok) {
        // eslint-disable-next-line prefer-promise-reject-errors
        const error = { json, statusCode: response?.status };

        if (networkError) {
          console.error(networkError, fullUrl);
        }

        const messageLens = R.lensPath(['json', 'details', 'message']);
        const errorsLens = R.lensPath(['json', 'details', 'errors']);
        const errorDataLens = R.lensPath(['json', 'details', 'data']);
        const message =
          R.view(messageLens, error) || json.message || 'Unknown Error';
        const errors: Record<string, unknown> | Record<string, unknown>[] =
          R.view(errorsLens, error);
        const data: Record<string, unknown> = R.view(errorDataLens, error);
        const inputNames = errors ? Object.keys(errors).map(camelizeFunc) : [];

        if (error && error.statusCode >= 500 && window?.Sentry) {
          window.Sentry?.withScope((scope: any) => {
            scope.setExtras({ fullUrl, apiFault: true });
            scope.setExtras(fetchOptions);
            window.Sentry?.captureException(error);
          });
        }

        const validationError =
          error.statusCode === 422
            ? {
                _error: message,
                ...camelizeKeys(
                  inputNames.length && errors
                    ? R.map(
                        (errorEntries) =>
                          Array.isArray(errorEntries)
                            ? errorEntries.reduce(
                                (accErrs, errorText) =>
                                  `${accErrs}, ${errorText}`
                              )
                            : errorEntries,
                        errors
                      )
                    : {}
                ),
              }
            : null;
        return {
          statusCode: error.statusCode,
          shouldRetry: error.json.should_retry,
          error: {
            message,
            details: camelizeKeys(error.json?.details || {}),
            data: data ? camelizeKeys(data) : null,
            errors: errors ? camelizeKeys(errors) : null,
            inputNames: inputNames || null,
            validationError,
          },
        };
      }
      if (!json) {
        return {
          response: true,
        };
      }
      if (
        process.env.TARGET_ENV === 'e2e' ||
        process.env.TARGET_ENV === 'test'
      ) {
        console.log(
          fetchOptions.method,
          response.url,
          JSON.stringify(json, null, 2)
        );
        console.log(
          '========================================================='
        );
        console.log(
          '========================================================='
        );
        console.log(
          '========================================================='
        );
        console.log(
          '========================================================='
        );
      }
      if (normalizer) {
        return normalizer(json);
      }
      if (query && query.by) {
        return normalizeGrouped({
          schema,
          groupSchema: bySchemas[query.by],
          // @ts-ignore
          camelize: camelize ? camelizeKeysDefault : R.identity,
          getSchema: defaultGetSchema,
        })(json);
      }
      return defaultNormalizeResponse({
        selectData,
        schema,
        // @ts-ignore
        camelize: camelize ? camelizeKeysDefault : R.identity,
        getSchema: defaultGetSchema,
      })(json);
    })
    .catch((error) => {
      // eslint-disable-next-line no-param-reassign
      error.failedToFetch = !external && !base;

      if (window && window.Sentry) {
        window.Sentry.withScope((scope: any) => {
          scope.setExtras({ fullUrl });
          scope.setExtras(fetchOptions);
          window.Sentry?.captureException(error);
        });
      }

      return {
        error,
        response: null,
        json: {},
      };
    });
}
