import React from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  reduxForm,
  InjectedFormProps,
  SubmissionError,
  Field,
} from 'redux-form';
import { useTranslation } from 'react-i18next';
import { callAuthApi } from 'imddata';
import { setAuth } from 'imddata/utils/auth';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { Button, LinkButton, NewInputField } from 'imdshared';
import isEmail from 'validator/lib/isEmail';
import { SocialSignin } from '../../shared';

const formStyle = css`
  padding: 24px;
`;

const buttonStyle = css`
  width: 100%;
  justify-content: center;
  margin-bottom: 8px;
`;

type FormData = {
  email: string;
  password: string;
};

function SigninForm({
  autofill,
  error,
  submitting,
  handleSubmit,
}: InjectedFormProps<FormData>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const submitForm = ({ email, password }: FormData) => {
    return callAuthApi
      .login({
        password,
        username: email.trim(),
      })
      .then((result) => {
        if ('error' in result || !result.response) {
          autofill('email', email);
          throw new SubmissionError({
            _error: result.error && result.error.message,
          });
        }
        setAuth(result.response as any);
        dispatch(loadLoggedUser());
      });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)} css={formStyle}>
      <Helmet>
        <title>{t('page-title-signin')}</title>
      </Helmet>

      <Field
        key="email"
        name="email"
        label={t('email')}
        errorText={error}
        placeholder={t('please-enter-your-email')}
        component={NewInputField}
      />

      <Field
        key="password"
        name="password"
        errorText={error}
        floatingLabelText={t('password')}
        placeholder={t('please-enter-your-password')}
        type="password"
        component={NewInputField}
      />

      <Button
        primary={true}
        type="submit"
        text={t('login')}
        showLoading={submitting}
        css={buttonStyle}
      />

      <LinkButton
        to="/reset"
        text={t('forgot-password')}
        style={{ width: '100%' }}
        backgroundColor="transparent"
        css={buttonStyle}
      />

      <SocialSignin />
    </form>
  );
}

const validate = (values: FormData) => {
  const errors: Partial<Record<keyof FormData, string>> = {};
  if (!values.email) {
    errors.email = 'required';
  } else if (!isEmail(values.email)) {
    errors.email = 'invalid-email-address';
  }
  if (!values.password) {
    errors.password = 'required';
  }
  return errors;
};

export default reduxForm<FormData>({
  form: 'loginForm',
  getFormState: (state) => state.form,
  validate,
})(SigninForm);
