import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Bar, IconButton, Icons, AppTitle, Badge, Button } from 'imdui';
import { NotificationsCountProvider } from 'components/logic';

const WindowHeaderBar = styled(Bar)`
  z-index: 1;
  position: absolute;
  padding: 0 8px;
  justify-content: space-between;
`;

const WindowHeaderRow = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const NotificationsBadge = styled(Badge)`
  position: initial;
  background-color: ${({ theme }): string => theme.state.error};
`;

type Props = {
  onClickClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const NotificationsHeader: React.FC<Props> = ({
  onClickClear,
  onClickClose,
}) => {
  const { t } = useTranslation();

  return (
    <WindowHeaderBar>
      <WindowHeaderRow>
        <IconButton icon={Icons.actions.close} onClick={onClickClose} />

        <AppTitle>{t('notifications')}</AppTitle>

        <NotificationsCountProvider>
          {(unreadCount): JSX.Element => (
            <NotificationsBadge count={unreadCount} />
          )}
        </NotificationsCountProvider>
      </WindowHeaderRow>

      {onClickClear && (
        <Button
          text={t('notifications-read-all')}
          backgroundColor="transparent"
          onClick={onClickClear}
        />
      )}
    </WindowHeaderBar>
  );
};

export default NotificationsHeader;
