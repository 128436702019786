// @flow
import React, { useEffect, useContext, useMemo } from 'react';
import type { FieldArrayFieldsProps, BaseFieldProps } from 'redux-form';
import { FieldArray, FormSection, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Icons,
  Button,
  HelpWindowContext,
  FileDropField,
  NewInputField,
  EnabledField,
} from 'imdshared';
import type { EntityModels } from 'imddata';
import { useDeleteEntity, useCreateEntity, useUpdateEntity } from 'imddata';
import { FieldGroup } from 'imdui';
import { createStringValidator } from './utils';

const KitDeleteRow = styled.div`
  display: flex;
`;

const pressKitAccept = [
  {
    fileType: 'image/*',
    extension: ['.png', '.jpg', '.jpeg', '.tiff'],
    label: 'PNG, JPG, TIFF',
  },
  {
    fileType: 'image/svg+xml',
    extension: ['.svg'],
    label: 'SVG',
  },
  {
    fileType: 'text/csv',
    extension: ['.csv'],
    label: 'CSV',
  },
  {
    fileType: 'text/plain',
    extension: ['.txt'],
    label: 'TXT',
  },
  {
    fileType: 'application/pdf',
    extension: ['.pdf'],
    label: 'PDF',
  },
];

type PressFileValue = {
  id: number | string;
  file?: EntityModels.File;
  title: string;
  description: string;
};

const validateFile = (value: any) =>
  !value
    ? 'required'
    : value?.status && value?.status !== 'ok'
      ? 'upload-failed'
      : undefined;
/* eslint-disable react/no-array-index-key */

const renderPressFiles = ({
  releasePageId,
  fields,
}: {
  releasePageId: string;
  fields: FieldArrayFieldsProps<PressFileValue>;
}) => {
  const { t } = useTranslation();

  const validateTitle = useMemo(() => createStringValidator(200, t, false), []);
  const validateDescription = useMemo(
    () => createStringValidator(1000, t, false),
    []
  );

  const showHelpWindow = useContext(HelpWindowContext);
  const {
    createEntry,
    request: { creating },
    createdId,
  } = useCreateEntity({
    query: { releasePageId },
    entity: 'releasePagesPressFiles',
  });

  useEffect(() => {
    if (createdId) {
      fields.push({
        id: createdId,
        title: '',
        description: '',
      });
    }
  }, [createdId]);

  const { updateEntry } = useUpdateEntity({
    entity: 'releasePagesPressFiles',
    query: { releasePageId },
  });

  const { deleteEntry } = useDeleteEntity({
    queryHash: 'deletingPressFiles',
    entity: 'releasePagesPressFiles',
    query: { releasePageId },
  });

  return (
    <>
      {fields.map((kit: string, index: number) => {
        const { id } = fields.get(index);
        return (
          <FieldGroup key={`press${index}`}>
            <Field<React.ComponentProps<typeof NewInputField> & BaseFieldProps>
              name={`${kit}.title`}
              placeholder={t('press-file-title')}
              label={t('press-file-title')}
              validate={validateTitle}
              onChange={(_event: any, value?: string) => {
                updateEntry(
                  {
                    id,
                    data: {
                      title: value,
                    },
                  },
                  { debounce: true }
                );
              }}
              onClickHelp={() => {
                showHelpWindow(
                  t('press-file-title'),
                  t('release-page-helptext-press-file-title')
                );
              }}
              component={NewInputField}
            />
            <Field<React.ComponentProps<typeof NewInputField> & BaseFieldProps>
              name={`${kit}.description`}
              validate={validateDescription}
              placeholder={t('press-file-description')}
              label={t('press-file-description')}
              multiline={true}
              onChange={(_event?: any, value?: string) => {
                updateEntry(
                  {
                    id,
                    data: {
                      description: value,
                    },
                  },
                  { debounce: true }
                );
              }}
              onClickHelp={() => {
                showHelpWindow(
                  t('press-file-description'),
                  t('release-page-helptext-press-file-description')
                );
              }}
              component={NewInputField}
            />
            <Field
              name={`${kit}.file`}
              maxSize={5 * 1000 * 1000}
              handler="releasePagesPressFile"
              handlerData={{
                id,
                releasePageId,
              }}
              label={t('upload-the-kit')}
              multiple={false}
              required={true}
              accept={pressKitAccept}
              validate={validateFile}
              requirementText={t('release-page-press-file-file-requirement')}
              component={FileDropField}
            />
            <KitDeleteRow>
              <Button
                text={t('remove-file')}
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  deleteEntry({ id });
                  fields.remove(index);
                }}
                icon={Icons.actions.remove}
              />
            </KitDeleteRow>
          </FieldGroup>
        );
      })}
      <FieldGroup style={{ paddingBottom: '24px' }}>
        <KitDeleteRow>
          <Button
            style={{ marginLeft: 'auto' }}
            icon={Icons.actions.add}
            text={t('add-file')}
            disabled={fields.length === 8 || creating}
            onClick={() => {
              createEntry({ data: {} });
            }}
          />
        </KitDeleteRow>
      </FieldGroup>
    </>
  );
};

export type PressFormData = {
  enabled: boolean;
  pressFiles?: Array<PressFileValue>;
};

const PressForm = ({ releasePageId }: { releasePageId: string }) => {
  const { t } = useTranslation();
  return (
    <FormSection name="press">
      <FieldGroup>
        <Field
          name="enabled"
          label={t('press')}
          description={t('you-can-disable-this-page-from-landing-page')}
          component={EnabledField}
        />
      </FieldGroup>
      <FieldArray
        name="pressFiles"
        releasePageId={releasePageId}
        component={renderPressFiles}
      />
    </FormSection>
  );
};

export default PressForm;
