import styled from '@emotion/styled';

//
//
// Cover section
//

/** */
export const CoverTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #fff;

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;
/** */
export const CoverSubtitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #fff;
  opacity: 0.8;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
`;

//
//
// Content section
//

/** */
export const H2 = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.3px;
  color: var(--fg-1);
`;
/** */
export const H3 = styled.h3`
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.15px;
  color: var(--fg-1);
`;
export const ButtonText = styled.span`
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  color: var(--fg-1);
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
/** */
export const H4 = styled.h4`
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: var(--fg-1);
`;
/** */
export const BodyL = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--fg-1);
`;
/** */
export const BodyM = styled.span<{ secondary?: boolean }>`
  font-family: 'Inter', sans-serif;
  color: var(--fg-${({ secondary }) => (secondary ? '2' : '1')});
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`;
/** */
export const BodyS = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-2);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;
export const BodyXS = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-2);
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
`;

export const ContentS = styled.span`
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.15px;
`;
export const ContentM = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-1);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;

export const ContentL = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-1);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`;
