import { Card, Icons, Text } from '@imus/services-ui';
import Image from 'next/image';
import { useAudioPlayer } from 'react-use-audio-player';
import { BioCard } from './BioCard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AudioSeekBar } from './AudioSeekBar';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import type { Release } from '../types/TemplateData';

const coverPlaceholderStyle = css`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: block;
  }

  svg {
    fill: var(--fg-1);
  }
`;

const trackPlayerStyle = css`
  margin-top: auto;
  align-items: center;
  display: flex;
  gap: 12px;
`;

const playButtonStyle = css`
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  svg {
    fill: var(--fg-1);
  }
`;

const CoverCard = ({ children }: { children: React.ReactNode }) => (
  <Card css={coverPlaceholderStyle}>{children}</Card>
);

const TrackPlayer = ({
  audioUrl,
  onRequestPlay,
}: {
  audioUrl?: string;
  onRequestPlay?: () => void;
}) => {
  const {
    load,
    setVolume,
    togglePlayPause,
    playing,
    isReady,
    getPosition,
    duration,
    seek,
  } = useAudioPlayer();

  const loaded = useRef(false);
  const [requestedPlay, setRequestedPlay] = useState(false);

  const handlePlay = useCallback(() => {
    if (!audioUrl) return;
    if (loaded.current) {
      setVolume(0.5);
      togglePlayPause();
    } else {
      load(audioUrl, { autoplay: true, initialVolume: 0.5 });
      loaded.current = true;
    }
  }, [audioUrl, togglePlayPause]);

  const handleClick = useCallback(() => {
    if (!audioUrl && onRequestPlay && !requestedPlay) {
      setRequestedPlay(true);
      onRequestPlay();
    } else {
      handlePlay();
    }
  }, [requestedPlay, handlePlay]);

  useEffect(() => {
    if (audioUrl && requestedPlay) {
      handlePlay();
    }
  }, [audioUrl, requestedPlay]);

  return (
    <div css={trackPlayerStyle}>
      <button
        css={playButtonStyle}
        aria-label="play"
        type="button"
        onClick={handleClick}
      >
        {playing || (requestedPlay && !isReady) ? (
          <Icons.PauseIcon />
        ) : (
          <Icons.PlayIcon />
        )}
      </button>
      <AudioSeekBar
        playing={playing}
        getPosition={getPosition}
        duration={duration}
        seek={seek}
      />
    </div>
  );
};

export const TrackCard = ({
  title,
  release,
  url,
  onRequestPlay,
}: {
  title: string;
  url?: string;
  release?: Release;
  onRequestPlay?: () => void;
}) => {
  const coverUrl = release
    ? release.cover?.url ||
      `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${release.pageKey}/cover.jpg`
    : null;
  const [hasWindow, setHasWindow] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);
  return (
    <BioCard>
      <CoverCard>
        {coverUrl ? (
          <Image src={coverUrl} width={80} height={80} alt="" />
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.736 2.81588C19.395 2.67958 19.7245 2.61143 19.9826 2.6994C20.209 2.77655 20.4002 2.93226 20.5216 3.13831C20.66 3.37325 20.66 3.70974 20.66 4.38272V15.6459C20.66 17.8551 18.8691 19.6459 16.66 19.6459C14.4509 19.6459 12.66 17.8551 12.66 15.6459C12.66 13.4368 14.4509 11.6459 16.66 11.6459C17.3886 11.6459 18.0717 11.8407 18.66 12.181V4.87391L10.66 6.52846V17.6459C10.66 19.855 8.86914 21.6459 6.66 21.6459C4.45086 21.6459 2.66 19.8551 2.66 17.6459C2.66 15.4368 4.45086 13.6459 6.66 13.6459C7.38858 13.6459 8.07166 13.8407 8.66 14.181V6.20272C8.66 5.73835 8.66 5.50616 8.74291 5.31545C8.81602 5.14726 8.93397 5.00242 9.08386 4.89676C9.25383 4.77695 9.4812 4.72993 9.93595 4.63588L18.736 2.81588ZM18.66 15.6459C18.66 14.5413 17.7646 13.6459 16.66 13.6459C15.5554 13.6459 14.66 14.5413 14.66 15.6459C14.66 16.7505 15.5554 17.6459 16.66 17.6459C17.7646 17.6459 18.66 16.7505 18.66 15.6459ZM6.66 15.6459C7.76457 15.6459 8.66 16.5413 8.66 17.6459C8.66 18.7505 7.76457 19.6459 6.66 19.6459C5.55543 19.6459 4.66 18.7505 4.66 17.6459C4.66 16.5413 5.55543 15.6459 6.66 15.6459Z"
            />
          </svg>
        )}
      </CoverCard>
      <div>
        <Text.ContentM>{title}</Text.ContentM>
      </div>
      {release && (
        <div>
          <Text.BodyS>
            {release.title}
            {release.releaseTypeId ? ` • ${t(release.releaseTypeId)}` : ''}
          </Text.BodyS>
        </div>
      )}
      {hasWindow && (
        <TrackPlayer audioUrl={url} onRequestPlay={onRequestPlay} />
      )}
    </BioCard>
  );
};
