import { useTranslation } from 'next-i18next';
import { PressFileItem, Card, Text, LinkIconButton } from '@imus/services-ui';
import type { PressContact, PressFile, PressQuote } from '../types';
import { AutosizeGrid } from '@imus/base-ui';
import { quoteStyle, quoteText } from './styles';
import styled from '@emotion/styled';
import { getFileUrl } from '../utils/getFileUrl';
import { useBaseUrl } from '../BaseUrlContext';
import isURL from 'validator/lib/isURL';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';

const PressCard = styled(Card)`
  padding: 16px;
  display: flex;
  gap: 4px;
  flex: 1;
`;

const ContactInfo = styled.div`
  word-break: break-all;
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`;

const formatDescription = (description: string) => {
  if (isMobilePhone(description)) {
    return { url: 'tel:' + description, label: description };
  }
  if (isEmail(description)) {
    return { url: 'mailto:' + description, label: description };
  }
  if (isURL(description, { require_host: true })) {
    try {
      const url = new URL(description);
      return { url: description, label: url.hostname };
    } catch {
      return { url: description, label: description };
    }
  }
  return { url: undefined, label: description };
};

export const PressItems = ({
  contacts,
  files,
  quotes,
}: {
  contacts?: PressContact[];
  files?: PressFile[];
  quotes?: PressQuote[];
}) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();
  return (
    <>
      {!!contacts?.length && (
        <>
          <Text.H2>{t('contact-details')}</Text.H2>

          <AutosizeGrid
            gap={24}
            style={{ marginBottom: '16px' }}
            minWidth={320}
            maxColumnCount={3}
          >
            {contacts.map(({ value, name }, idx) => {
              const { label, url } = formatDescription(value);
              return (
                <PressCard key={idx}>
                  <ContactInfo>
                    <Text.BodyS>{name}</Text.BodyS>
                    <Text.ContentM>{label}</Text.ContentM>
                  </ContactInfo>
                  {url && <LinkIconButton href={url} icon="chevron" />}
                </PressCard>
              );
            })}
          </AutosizeGrid>
        </>
      )}
      {!!files?.length && (
        <>
          <Text.H2>{t('press')}</Text.H2>

          <AutosizeGrid
            gap={24}
            style={{ marginBottom: '16px' }}
            minWidth={320}
            maxColumnCount={2}
          >
            {files.map(({ file, description }, idx) =>
              file ? (
                <PressCard key={idx}>
                  <PressFileItem
                    name={description || t('press-file')}
                    extension={file.extension}
                    url={getFileUrl(file, baseUrl)}
                    size={file.size}
                  />
                </PressCard>
              ) : null
            )}
          </AutosizeGrid>
        </>
      )}
      {!!quotes?.length && (
        <>
          <Text.H2>{t('quotes')}</Text.H2>
          <AutosizeGrid
            gap={24}
            style={{ marginBottom: '16px' }}
            minWidth={320}
            maxColumnCount={2}
          >
            {quotes.map(({ origin, message }, idx) => (
              <Card
                data-test-id="PressItem-QuoteCard"
                css={quoteStyle}
                key={idx}
              >
                <span css={quoteText}>"{message}"</span>
                <Text.ContentL> – {origin}</Text.ContentL>
              </Card>
            ))}
          </AutosizeGrid>
        </>
      )}
    </>
  );
};
