import React, { useMemo, useContext, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { reverse } from 'ramda';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import type { ReduxState } from 'imddata';
import { useEntries, useQuery, useSalesStatements } from 'imddata';
import { Helmet } from 'react-helmet-async';
import { ApplicationSettingsContext } from 'components';
import { HelpWindowContext, Table, TableRow } from 'imdshared';

import {
  BodySmall,
  SecondaryButton,
  LoadingIndicator,
  Centered,
  Card,
  H3,
  Content,
  Clickable,
} from 'imdui';
import { UnavailableSales } from 'screens/Revenue/shared';
import { RevenueCharts } from './RevenueCharts';
import { BodyS, ContentM, ContentS } from '@imus/services-ui/src/Text';
import { ChevronBottomIcon, ChevronTopIcon } from '@imus/base-ui';
import type { SalesStatement } from 'imddata/types/entities';
import { useSelector } from 'react-redux';

const Row = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const INCONSISTENT_MONTHS = ['05-2021', '07-2020', '05-2024'];
const MonthWithRevenueSplitsStyled = styled(Clickable)`
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  svg {
    opacity: 0.35;
  }
`;

const MonthWithRevenueSplits = ({ cellData, rowData }: any) => {
  return (
    <>
      <MonthWithRevenueSplitsStyled onClick={rowData.onOpenSplit}>
        <ContentM>{cellData}</ContentM>

        {rowData.onOpenSplit &&
          (rowData.openedSplit ? <ChevronTopIcon /> : <ChevronBottomIcon />)}
      </MonthWithRevenueSplitsStyled>
    </>
  );
};

const fields = (
  t: TFunction,
  {
    streamsFormatter,
    revenueFormatter,
  }: {
    streamsFormatter: Intl.NumberFormat;
    revenueFormatter: Intl.NumberFormat;
  }
) => [
  {
    dataKey: 'monthId',
    label: t('date'),
    cellRenderer: (props: any) => <MonthWithRevenueSplits {...props} />,
  },
  {
    dataKey: 'releaseDownloads',
    label: t('release-downloads'),
    textAlign: 'right',
    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
  },
  {
    dataKey: 'ringtoneDownloads',
    label: t('ringtone-downloads'),
    textAlign: 'right',
    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
  },
  {
    dataKey: 'trackDownloads',
    label: t('track-downloads'),
    textAlign: 'right',
    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
  },
  {
    dataKey: 'trackStreams',
    label: t('track-streams'),
    textAlign: 'right',

    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
  },
  {
    dataKey: 'views',
    label: t('views'),
    textAlign: 'right',
    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
  },
  {
    dataKey: 'monetizations',
    label: t('monetizations'),
    cellRenderer: ({ cellData }: { cellData: number }) =>
      streamsFormatter.format(cellData),
    textAlign: 'right',
  },
  {
    dataKey: 'totalRevenuePrecise',
    label: t('total-revenues'),
    cellRenderer: ({ cellData }: { cellData: string }) =>
      revenueFormatter.format(Number(cellData)),
    textAlign: 'right',
  },
];

const TableCardWrapper = styled(Card)`
  grid-column: 1 / -1;
  overflow: auto;
`;

const Line = styled.tr`
  height: 1px;
  box-shadow: inset 0 -1px 0 0 var(--fg-4);
  width: 100%;
`;

const WarningRow = styled.td`
  box-shadow: none;
  height: auto;
  width: 100%;
  & * {
    font-size: 14px !important;
  }
  & > * {
    display: block;
    width: max-content;
  }
`;

const Section = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--fg-4);
  ${H3} {
    display: block;
    margin-bottom: 16px;
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  gap: 32px;
  margin-bottom: 256px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row;
  }
`;

const RevenueSplitStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 8px;
  column-gap: 16px;
  max-width: 300px;
`;

const RevenueSplitValue = styled(ContentS)`
  text-align: right;
`;

type SalesStatementSplit = {
  id: string;
  name: string;
  revenue: string;
  revenueFormatted: string;
};

const RevenueSplit = ({
  signature,
  totalRevenue,
  formatter,
}: {
  signature: string;
  totalRevenue: string;
  formatter: Intl.NumberFormat;
}) => {
  const { t } = useTranslation();
  const customerName = useSelector((state: ReduxState) => {
    const { auth: loggedUser } = state;
    const customerId = loggedUser.data ? loggedUser.data.customerId : undefined;
    return customerId
      ? state.entities.customers.entities[customerId]?.name
      : '';
  });
  const { query, queryHash } = useQuery({ query: { signature } });
  const {
    entries: splits,
    request: { loaded },
  } = useEntries<SalesStatementSplit>({
    entity: 'salesStatementsSplits',
    query,
    queryHash,
  });
  const accountHolderRevenue =
    Number(totalRevenue) -
    splits?.reduce((acc, { revenue }) => acc + Number(revenue), 0);
  return loaded ? (
    <RevenueSplitStyled>
      <BodyS>{`${t('you')}${customerName ? `(${customerName})` : ''}`}</BodyS>
      <RevenueSplitValue>
        {formatter.format(accountHolderRevenue)}
      </RevenueSplitValue>
      {splits?.map((e) => {
        return (
          <>
            <BodyS>{e.name}</BodyS>
            <RevenueSplitValue>
              {formatter.format(Number(e.revenue))}
            </RevenueSplitValue>
          </>
        );
      })}
    </RevenueSplitStyled>
  ) : (
    <LoadingIndicator />
  );
};

const SalesRow = ({
  summary,
  month,
  year,
  hasSplitRevenue,
  revenueFormatter,
  fields,
}: SalesStatement & { fields: any; revenueFormatter: Intl.NumberFormat }) => {
  const [openSplitRevenue, setOpenSplitRevenue] = useState(false);
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);
  const date = moment()
    .year(year)
    .month(month - 1);
  const onOpenSplit = useCallback(() => {
    setOpenSplitRevenue(!openSplitRevenue);
  }, [openSplitRevenue]);
  const item = useMemo(
    () => ({
      onOpenSplit: hasSplitRevenue ? onOpenSplit : undefined,
      openedSplit: openSplitRevenue,
      monthId: date.format('MMMM'),
      ...summary,
    }),
    [onOpenSplit, summary, openSplitRevenue, date.format('MMMM')]
  );
  return (
    <>
      {/*
       *  @ts-ignore */}
      <TableRow style={{ boxShadow: 'none' }} item={item} fields={fields} />
      {openSplitRevenue && (
        <td colSpan={100} style={{ marginBottom: '16px' }}>
          <RevenueSplit
            signature={year + '-' + month}
            totalRevenue={summary.totalRevenuePrecise}
            formatter={revenueFormatter}
          />
        </td>
      )}
      {INCONSISTENT_MONTHS.includes(date.format('MM-YYYY')) && (
        <WarningRow colSpan={100} style={{ paddingTop: 0 }}>
          <BodySmall>
            {t('inconsistent-sales-month')}{' '}
            <SecondaryButton
              onClick={() => {
                showHelpWindow(
                  t('inconsistent-sales-month'),
                  t('inconsistent-sales-month-message', {
                    context: date.format('MM-YYYY'),
                  })
                );
              }}
            >
              {t('learn-more')}
            </SecondaryButton>
          </BodySmall>
        </WarningRow>
      )}
      <Line />
    </>
  );
};

const Statements = () => {
  const { t } = useTranslation();
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const {
    request: { loaded },
    entries,
  } = useSalesStatements();

  const empty = !entries?.length;

  const currency = entries?.[0]?.summary.totalRevenueCurrency || 'EUR';
  const revenueFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });
  const streamsFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 0,
  });
  const withTranslationdFields = useMemo(
    () => fields(t, { revenueFormatter, streamsFormatter }),
    [t]
  );

  return (
    <>
      <Helmet>
        <title>{t('page-title-reporting-sales')}</title>
      </Helmet>

      <ChartsGrid data-test-id={empty && loaded ? 'EmptySalesPage' : ''}>
        <RevenueCharts />
        {!loaded && (
          <Centered style={{ gridColumn: '1 / -1' }}>
            <LoadingIndicator size="large" />
          </Centered>
        )}
        {loaded && <UnavailableSales />}

        {!empty && (
          <TableCardWrapper>
            {entries &&
              entries.map(({ year, months, summary, hasSplitRevenue }) => (
                <Section key={year}>
                  <H3>{year}</H3>
                  {/*
                   *  @ts-ignore */}
                  <Table fields={withTranslationdFields}>
                    {reverse(months).map((data, idx) => {
                      return (
                        <SalesRow
                          {...data}
                          key={idx}
                          revenueFormatter={revenueFormatter}
                          fields={withTranslationdFields}
                        />
                      );
                    })}
                  </Table>

                  <Row>
                    <Content>{t('total')}</Content>

                    <Content data-test-id={`StatementsYearSummary-${year}`}>
                      {revenueFormatter.format(
                        Number(summary.totalRevenuePrecise)
                      )}
                    </Content>
                  </Row>
                  {hasSplitRevenue && (
                    <div>
                      <RevenueSplit
                        signature={year + ''}
                        formatter={revenueFormatter}
                        totalRevenue={summary.totalRevenuePrecise}
                      />
                    </div>
                  )}
                </Section>
              ))}
          </TableCardWrapper>
        )}
      </ChartsGrid>
    </>
  );
};

export default Statements;
