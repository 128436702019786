import React, { useCallback } from 'react';
import { Content } from '../../../text';
import { Wrapper, ItemWrapper } from './styles';
import type { Variant } from './styles';

type Option = {
  value: string;
  label?: string;
  testId?: string;
  disabled?: boolean;
};

type OptionSelectCallback<T extends string> = (
  value: T,
  e: React.MouseEvent<HTMLDivElement>
) => void;

type Props<T extends Option> = {
  options: readonly T[];
  variant?: Variant;
  value?: T['value'];
  onChange: OptionSelectCallback<T['value']>;
  className?: string;
  style?: React.CSSProperties;
};

export type OptionItemProps<T extends Option> = Option & {
  children?: React.ReactNode;
  selected: boolean;
  style?: React.CSSProperties;
  variant: Variant;
  onChange: OptionSelectCallback<T['value']>;
};

export const OptionTab = <T extends Option>({
  style,
  value,
  children,
  label,
  variant = 'standart',
  testId,
  disabled,
  selected,
  onChange,
}: OptionItemProps<T>): JSX.Element => {
  const handleClick = useCallback(
    (e) => {
      if (onChange && !disabled) {
        onChange(value, e);
      }
    },
    [onChange, disabled, value]
  );

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && onChange && !disabled) {
        e.stopPropagation();
        onChange(value, e);
      }
    },
    [onChange, disabled, value]
  );

  return (
    <ItemWrapper
      style={style}
      variant={variant}
      data-test-id={testId}
      tabIndex={0}
      selected={selected}
      className={disabled ? 'disabled' : undefined}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onKeyPress={handleKeyPress}
    >
      {children}

      {label && <Content>{label}</Content>}
    </ItemWrapper>
  );
};

export const OptionTabsWrapper = Wrapper;

export const OptionTabs = <T extends Option>({
  style,
  className,
  options,
  value,
  onChange,
  variant = 'standart',
}: Props<T>) => (
  <Wrapper variant={variant} className={className} style={style}>
    {options.map((option) => (
      <OptionTab<T>
        key={option.value}
        {...option}
        variant={variant}
        selected={value === option.value}
        onChange={onChange}
      />
    ))}
  </Wrapper>
);
