import { useTranslation } from 'next-i18next';
import { Card, LinkButton, Text, TourItem } from '@imus/services-ui';
import Image from 'next/image';
import styled from '@emotion/styled';
import type { TemplateData, TourPageSplash } from '../types';
import { useBaseUrl } from '../BaseUrlContext';
import { getFileUrl } from '../utils/getFileUrl';
import { css } from '@emotion/react';

const TourCard = styled(Card)`
  display: grid;
  gap: 24px;
  padding: 24px;
`;

const cardStyle = css`
  overflow: hidden;
`;

const tourHeaderRowStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
`;

const imageStyle = css`
  max-height: 298px;
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const TourHeader = ({
  image,
  title,
  description,
  url,
}: TourPageSplash) => {
  const baseUrl = useBaseUrl();
  const { t } = useTranslation();
  return (
    <Card css={cardStyle}>
      {image && (
        <Image
          css={imageStyle}
          src={getFileUrl(image, baseUrl)}
          width={image.image?.width}
          height={image.image?.height}
          alt={description || 'gallery'}
        />
      )}
      <div css={tourHeaderRowStyle}>
        <div>
          <Text.H4>{title}</Text.H4>
          <Text.BodyS data-test-id="TourHeader-description">
            {description}
          </Text.BodyS>
        </div>
        {url && <LinkButton href={url}>{t('visit')}</LinkButton>}
      </div>
    </Card>
  );
};

export const TourInfo = ({
  tourEvents,
  locale,
  tourHighlight,
}: {
  locale: string;
  tourHighlight?: TemplateData['tour']['splash'];
  tourEvents: TemplateData['tour']['events'];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Text.H2>{t('tour')}</Text.H2>
      {tourHighlight ? <TourHeader {...tourHighlight} /> : null}

      {tourEvents?.length ? (
        <TourCard>
          {tourEvents
            .sort(
              (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
            )
            .map((te, idx) => (
              <TourItem key={idx} event={te} locale={locale} />
            ))}
        </TourCard>
      ) : null}
    </>
  );
};
