import React from 'react';
import { Caption, Card, Button } from 'imdshared';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import type { EntityModels } from 'imddata';
import { useEntry } from 'imddata';

const Row = styled(Card)`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
`;
const ActionRow = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 16px;
`;

const TextWrapper = styled.div`
  line-height: 1.4;
`;

const VoucherBlock = styled.div`
  text-align: center;
  align-items: center;
  flex: 340px;
  flex-grow: 0;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.09);
  margin: 1px;
  padding: 15px;
  @media (max-width: 400px) {
    box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.09);
  }
`;

export default function SelectedVoucherRow({
  voucherId,
  orderId,
  onAccept,
  onCancel,
}: {
  voucherId: number;
  orderId: number;
  onAccept: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { entry } = useEntry<EntityModels.CustomerVoucher>({
    entity: 'customerVouchers',
    id: voucherId,
  });
  const { t } = useTranslation();
  return entry ? (
    <Row>
      <VoucherBlock>
        <TextWrapper>
          {entry.name} <br />
          <Caption>
            {entry.code} ({entry.discount})
          </Caption>
        </TextWrapper>
      </VoucherBlock>
      <ActionRow>
        <TextWrapper>
          {t('apply-voucher-to-order')}
          <br />
          <Caption>{t('you-selected-the-voucher')}</Caption>
        </TextWrapper>
        <ButtonsWrapper>
          <Button
            disabled={!orderId}
            onClick={onAccept}
            text={t('apply')}
            style={{ marginRight: '8px' }}
            primary="paid"
          />
          <Button text={t('cancel')} onClick={onCancel} />
        </ButtonsWrapper>
      </ActionRow>
    </Row>
  ) : null;
}
