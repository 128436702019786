import 'react-tooltip/dist/react-tooltip.css';
import { css } from '@emotion/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const tooltipStyle = css`
  max-width: 300px;

  z-index: 100000;
  background: #ffffff !important;
  align-self: center;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 1;

  font-family: 'Inter';
  font-feature-settings:
    'case' on,
    'cv11' on;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  &:after,
  &:before {
    display: none;
  }
`;

export function Tooltip(props: React.ComponentProps<typeof ReactTooltip>) {
  return (
    <ReactTooltip noArrow={true} css={tooltipStyle} place="top" {...props} />
  );
}
