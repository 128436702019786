import { css } from '@emotion/react';
import type { StyledComponent } from '@emotion/styled';
import styled from '@emotion/styled';
import { HelpIcon } from '@imus/base-ui';
import React, { useCallback } from 'react';
import { Body, H4 } from '../../../text';
import IconButton from '../../button/IconButton';
import { IconsCollection, Icon } from '../../icon';

const BodySmall = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-2);
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
`;

type OverlineFC<P extends Record<string, unknown>> = StyledComponent<P> & {
  IconButton: typeof StyledIconButton;
};

type Props = React.ComponentPropsWithoutRef<'div'> & {
  htmlFor?: string;
  label?: string;
  size?: 'small' | 'large';
  required?: boolean;
  error?: boolean | string;
  onClickHelp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  components?: {
    OverlineActions?: React.FC;
  };
};

const StyledIcon = styled(Icon)`
  margin-left: 4px;

  flex-shrink: 0;
`;

const OverlineRow = styled.div<{
  error?: boolean | string;
  size?: 'small' | 'large';
}>`
  margin-bottom: ${({ size }) => (size === 'small' ? '8px' : '24px')};
`;

const TextRow = styled.label`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ErrorText = styled.span`
  display: inline-flex;
  gap: 0px;
  margin-left: 16px;
  & > ${Body}, & > ${BodySmall} {
    min-height: auto;
    color: ${({ theme }) => theme.state.error};
  }
`;

const bodySmallStyle = css`
  display: block;
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledIconButton = styled(IconButton)<{
  warning?: boolean;
  error?: boolean | string;
}>`
  width: 32px;
  margin-right: -8px;
  height: 32px;
  > svg {
    fill: ${({ theme, error, warning }): string => {
      if (error) {
        return theme.state.error;
      }
      if (warning) {
        return theme.state.warning;
      }
      return theme.foreground.secondary;
    }};
  }
`;

const OverlineTextBase: React.FC<Props> = ({
  error,
  label,
  required, // TODO: deprecate
  onClickHelp,
  components,
  size = 'small',
  id,
  className,
  htmlFor,
  style,
}) => {
  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const TextComponent = size === 'small' ? BodySmall : H4;
  const ErrorTextComponent = size === 'small' ? BodySmall : Body;

  return (
    <OverlineRow
      size={size}
      error={error}
      className={className}
      style={style}
      onClick={handleClick}
      onMouseDown={handleClick}
    >
      <TextRow htmlFor={htmlFor || id}>
        <TextComponent css={bodySmallStyle}>{label}</TextComponent>

        {error && (
          <ErrorText>
            <ErrorTextComponent>{error}</ErrorTextComponent>
          </ErrorText>
        )}
        {required && <StyledIcon d={IconsCollection.required} size={16} />}
      </TextRow>

      {(onClickHelp || components?.OverlineActions) && (
        <ButtonWrapper onClick={handleClick}>
          {components?.OverlineActions && <components.OverlineActions />}

          {onClickHelp && (
            <StyledIconButton onClick={onClickHelp} tabIndex={-1}>
              <HelpIcon />
            </StyledIconButton>
          )}
        </ButtonWrapper>
      )}
    </OverlineRow>
  );
};

// @ts-ignore
const OverlineText: OverlineFC<Props> = styled(OverlineTextBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${StyledIcon} {
    fill: ${({ theme, error }): string =>
      error ? theme.state.error : theme.foreground.secondary};
  }
`;

OverlineText.IconButton = StyledIconButton;

export default OverlineText;
