import { useTranslation } from 'next-i18next';
import { Icons, Text } from '@imus/services-ui';
import type { SocialProviders } from '../types';
import styled from '@emotion/styled';
import { BioCard } from './BioCard';

const getSocialIconColor = (name: SocialProviders): string => {
  switch (name) {
    case 'instagram':
      return 'linear-gradient(225deg, #7638FA 0%, #D300C5 25.52%, #FF0069 51.56%, #FF7A00 76.04%, #FFD600 100%);';
    case 'twitter':
      return '#57A4FF';
    case 'youtube':
      return '#FF0000';
    case 'facebook':
      return '#1877F2';
    case 'bandcamp':
      return '#000000';
    case 'soundcloud':
      return '#F26F23';
    default:
      return '#222';
  }
};

const getSocialUrl = (name: SocialProviders, id: string) => {
  switch (name) {
    case 'instagram':
      return 'https://instagram.com/' + id;
    case 'twitter':
      return 'https://twitter.com/' + id;
    // case 'deezer':
    //   return 'https://deezer.com/' + id;
    // case 'spotify':
    //   return 'https://spotify.com/' + id;
    // case 'apple_music':
    //   return 'https://music.apple.com/us/artist/' + id;
    case 'bandcamp':
      return `https://${id}.bandcamp.com/`;
    case 'youtube':
      return 'https://youtube.com/' + (id.includes('/') ? id : `@${id}`);
    case 'facebook':
      return 'https://facebook.com/' + id;
    case 'soundcloud':
      return 'https://soundcloud.com/' + id;
    default: {
      const exhustiveCheck: never = name;
      throw new Error(`unhandled social media type ${exhustiveCheck}`);
    }
  }
};

const SocialIcon = styled(Icons.Icon)<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;

  background: ${({ background }) => background};
  fill: #fff;
`;

const PillButtonLink = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 16px;
  margin: auto;

  color: var(--fg-1);
  font-weight: 600;
  font-size: 13px;
  font-family: 'Inter';
  text-transform: uppercase;

  min-height: 28px;
  background: var(--bg-1);
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;
const PillBorder = styled.span<{ background: string }>`
  display: flex;
  border-radius: 16px;
  padding: 1px;
  background: ${({ background }) => background};
`;

const IconCard = ({
  label,
  sublabel,
  urlText,
  url,
  icon,
  iconColor,
}: {
  label: string;
  sublabel?: string;
  urlText: string;
  url: string;
  icon: string;
  iconColor: string;
}) => (
  <BioCard>
    <SocialIcon background={iconColor} icon={icon}></SocialIcon>
    <div>
      <Text.ContentM>{label}</Text.ContentM>
    </div>
    {sublabel && <Text.BodyS>{sublabel}</Text.BodyS>}
    <div style={{ marginTop: 'auto' }}>
      <PillBorder background={iconColor}>
        <PillButtonLink rel="noreferrer" target="_blank" href={url}>
          {urlText}
        </PillButtonLink>
      </PillBorder>
    </div>
  </BioCard>
);

export const CustomLinkCard = ({
  name,
  url,
}: {
  name: string;
  url: string;
}) => {
  const { t } = useTranslation();
  return (
    <IconCard
      iconColor={'rgba(0, 0, 0, 0.9)'}
      icon={'link'}
      label={name}
      url={url}
      urlText={t('visit')}
    />
  );
};

const atSymbolSupported: SocialProviders[] = [
  'twitter',
  'instagram',
  'youtube',
];

export const getSocialHandlerPrefix = (
  id: SocialProviders,
  v: string | undefined
) => {
  if (id === 'youtube' && v) {
    if (v.includes('/')) return '';
    return '@';
  }
  return atSymbolSupported.indexOf(id) >= 0 ? '@' : '';
};

const formatSocialId = (id: SocialProviders, v: string | undefined) => {
  if (id === 'youtube' && v && v.includes('channel/')) return '';

  return `${getSocialHandlerPrefix(id, v)}${v}`;
};

export const SocialCard = ({
  name,
  id,
}: {
  name: SocialProviders;
  id: string;
}) => {
  const { t } = useTranslation();

  return (
    <IconCard
      iconColor={getSocialIconColor(name)}
      icon={name}
      label={t(name)}
      sublabel={formatSocialId(name, id)}
      url={getSocialUrl(name, id)}
      urlText={t('follow')}
    />
  );
};
