/* eslint-disable react/jsx-no-undef */
import React, { memo } from 'react';
import GeneralForm from './GeneralForm';
import styled from '@emotion/styled';

import type { ArtistFormData } from './ArtistForm';
import ArtistForm from './ArtistForm';
import type { PressFormData } from './PressForm';
import PressForm from './PressForm';
import type { GeneralFormData } from './GeneralForm';
import type { ReleaseFormData } from './ReleaseForm';
import ReleaseForm from './ReleaseForm';
import { BaseTourForm } from 'components/organisms/ArtistSideEditor/ArtistPageEditor/ArtistPageTourForm';

type TabId = 'release' | 'artist' | 'tour' | 'press'; // TODO reexport from release-page-template

type TourValue = {
  date: string;
  name: string;
  location: string;
  url: string;
};
type TourFormData = {
  enabled: boolean;
  useManualForm: boolean;
  events: Array<TourValue>;
};

export type FormData = {
  press: PressFormData;
  general: GeneralFormData;
  release: ReleaseFormData;
  tour: TourFormData;
  artist: ArtistFormData;
};

const SideEditorContainer = styled.div`
  flex: 1;
  background-color: #fff;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
`;

type Props = {
  artistIds: Array<number>;
  form: string;
  id: string;
  linksOnly?: boolean;
  selectedTab: TabId;
};

const FormContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const SideEditor = ({
  linksOnly,
  id: releasePageId,
  form,
  artistIds,
  selectedTab,
}: Props) => {
  if (linksOnly) {
    return (
      <SideEditorContainer data-test-id="TemplateSideEditor-FormContainer">
        <FormContainer visible={true}>
          <ReleaseForm releasePageId={releasePageId} form={form} />
        </FormContainer>
      </SideEditorContainer>
    );
  }
  return (
    <>
      <SideEditorContainer data-test-id="TemplateSideEditor-FormContainer">
        <FormContainer visible={selectedTab === 'release'}>
          <ReleaseForm releasePageId={releasePageId} form={form} />
          <GeneralForm releasePageId={releasePageId} />
        </FormContainer>
        <FormContainer visible={selectedTab === 'artist'}>
          <ArtistForm artistIds={artistIds} />
        </FormContainer>
        <FormContainer visible={selectedTab === 'tour'}>
          <div style={{ margin: '24px', display: 'grid', gap: '24px' }}>
            <BaseTourForm />
          </div>
        </FormContainer>
        <FormContainer visible={selectedTab === 'press'}>
          <PressForm releasePageId={releasePageId} />
        </FormContainer>
      </SideEditorContainer>
    </>
  );
};

export default memo(SideEditor);
