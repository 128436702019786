import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistIdContext, ListItemPlaceholder, constants } from 'imdshared';
import { Helmet } from 'react-helmet-async';
import type { ArtistNested } from 'imddata';
import { useArtists, useQuery } from 'imddata';
import {
  ArtistPagePromoCard,
  ArtistDataRow,
  ArtistSideEditor,
  LibraryHeaderTools,
  QueryBuilderInput,
} from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'imddata/actionTypes/ui/selection';
import type { ReduxState } from 'redux/reducers';
import DataRowListRenderer from 'components/molecules/DataRowListRenderer';
import { IconsCollection, Button, Window } from 'imdui';
import { LibraryWrap } from 'screens/Library/shared/LibraryWrap';
import type { RouteChildrenProps } from 'react-router';
import { useRouteMatch, useHistory } from 'react-router';
import EditItemForm from 'imdshared/src/Next/ArtistJoin/EditItemForm';

const options = constants.artists.roles.map(({ value, label }) => ({
  label,
  id: value,
  value: `role${value}`,
  prefix: 'role',
  queryKey: 'filter.row',
}));

const ArtistsBrowserFilter: React.FC = ({ value, onChange }: any) => {
  const { query, updateQuery } = useQuery();

  return (
    <QueryBuilderInput
      value={value}
      loading={false}
      options={options}
      onChange={onChange}
      query={query}
      onInputChange={updateQuery}
    />
  );
};
const noSelected: never[] = [];

const Artists = ({ match }: RouteChildrenProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [{ filterValue, searchQuery }, setFilter] = useState(() => ({
    filterValue: [],
    searchQuery: undefined,
  }));

  const onChangeFiltering = useCallback(({ value, searchQuery: newQuery }) => {
    setFilter({ filterValue: value, searchQuery: newQuery });
  }, []);

  const selected = useSelector(
    ({ ui: { selection } }: ReduxState) => selection.artists || noSelected
  );

  const prevSelection = useRef(selected);
  const dispatch = useDispatch();

  const updateSelection = useCallback((value) => {
    dispatch(actions.updateSelection(value));
  }, []);

  const onChangeSelection = useCallback((_e, id) => {
    history.replace(`${match?.path}/${id}/edit`);
  }, []);

  const { queryHash, query } = useQuery({ query: searchQuery });
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading, loaded },
  } = useArtists({ queryHash, query });

  const matchArtist = useRouteMatch<{
    artistId: string;
    tab: 'artist-page' | 'edit';
  }>({ path: `${match?.path}/:artistId/:tab` });

  useEffect(() => {
    if (prevSelection.current.length && !selected.length) {
      history.replace('/library/artists');
    }
    prevSelection.current = selected;
  }, [selected]);

  const [openForm, setOpenForm] = useState<'edit' | 'artist-page' | false>(
    false
  );

  useEffect(() => {
    const artistId = matchArtist?.params.artistId;
    if (artistId === 'new') {
      setOpenForm(matchArtist?.params.tab || 'edit');
      return;
    }
    if (artistId) {
      updateSelection({ entity: 'artists', selected: [Number(artistId)] });
      return;
    }
  }, [matchArtist?.params.artistId]);

  const defaultTab = matchArtist?.params.tab;

  return (
    <>
      <Helmet>
        <title>{t('page-title-artists')}</title>
      </Helmet>
      <div style={{ marginBottom: '32px' }}>
        <ArtistPagePromoCard />
      </div>
      <LibraryWrap>
        <LibraryHeaderTools
          value={filterValue}
          renderQueryBuilder={ArtistsBrowserFilter}
          onChangeFiltering={onChangeFiltering}
        >
          <Button
            newIcon={IconsCollection.add}
            text={t('artist')}
            onClick={() => {
              setOpenForm('edit');
            }}
          />
        </LibraryHeaderTools>
        <Window
          title={openForm === 'edit' ? t('add-artist') : t('add-artist-page')}
          isOpen={!!openForm}
          close={() => setOpenForm(false)}
        >
          <ArtistIdContext.Provider
            value={{ appleMusic: false, spotify: false }}
          >
            <EditItemForm
              onClose={() => {
                setOpenForm(false);
              }}
              onSaved={({ result }: any) => {
                history.replace(`${match?.path}/${result}/${openForm}`);
              }}
            />
          </ArtistIdContext.Provider>
        </Window>

        <DataRowListRenderer<ArtistNested>
          selected={selected}
          virtualized={false}
          onClickEntry={onChangeSelection}
          onCheckEntry={onChangeSelection}
          loading={loading}
          hasNextPage={hasMorePages}
          data={entries}
          rowRenderer={ArtistDataRow}
          loadNextPage={loadMore}
        />
        {(loading || (loaded && !entries.length)) && (
          <ListItemPlaceholder
            emptyPlaceholder={t('no-artists')}
            loading={loading}
          />
        )}
      </LibraryWrap>
      <ArtistSideEditor initialTab={defaultTab} />
    </>
  );
};

export default Artists;
