import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FieldWrapper, IconButton } from 'imdui';
import { ProcessFieldMeta } from 'imddata/providers';
import DateController from '../../Next/DateController';
import DropdownInteractive from '../../Next/DropdownInteractive';
import { ActionClearIcon, CalendarIcon } from '@imus/base-ui';

const renderValue = (date) => (date ? date.format('LL') : '');

const DatePicker = ({ value, onChange, onBlur, width, ...props }) => (
  <DateController
    {...props}
    focused={true}
    autoFocus={true}
    date={value}
    initialVisibleMonth={() => value || moment()}
    onDateChange={onChange}
    numberOfMonths={width > 560 ? 2 : 1}
  />
);

DatePicker.propTypes = {
  width: PropTypes.number,
  onSelect: PropTypes.func,
  value: PropTypes.object,
};

export const DatePickerInput = ({
  value,
  floatingLabelText,
  placeholder,
  errorText,
  onChange,
  onFocus,
  onBlur,
  clearable,
  components,
  ...rest
}) => {
  const Actions = useCallback(() => {
    return (
      <>
        {components?.Actions ? <components.Actions /> : null}

        <CalendarIcon />

        {clearable && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onChange(null);
            }}
            icon={ActionClearIcon}
          />
        )}
      </>
    );
  }, [components?.Actions, clearable]);
  const finalComponents = useMemo(
    () => ({
      ...(components || {}),
      Actions,
    }),
    [components, clearable]
  );
  return (
    <FieldWrapper>
      <DropdownInteractive
        value={value}
        renderDropdown={DatePicker}
        label={floatingLabelText}
        placeholder={placeholder}
        errorText={errorText}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        components={finalComponents}
        {...rest}
        displayValue={renderValue(value)}
      />
    </FieldWrapper>
  );
};

const DatePickerInputField = ({
  input,
  floatingLabelText,
  meta,
  onChange,
  isDayBlocked,
  isOutsideRange,
  ...other
}) => (
  <ProcessFieldMeta hasValue={!!input.value} meta={meta}>
    {({ error, warning }) => (
      <DatePickerInput
        {...input}
        {...other}
        dropdownProps={{
          isDayBlocked,
          isOutsideRange,
        }}
        onChange={(value) => input.onChange(value)}
        floatingLabelText={floatingLabelText}
        errorText={error}
        warningText={warning}
      />
    )}
  </ProcessFieldMeta>
);

export default DatePickerInputField;
