import { ReturnNavigation } from 'components/atoms';
import copy from 'copy-to-clipboard';
import {
  Body,
  ComponentIcons,
  ToggleSwitch,
  Button,
  IconButton,
  HelpWindowContext,
  Centered,
  LoadingIndicator,
  SubscriptionFeatureGateButton,
} from 'imdui';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ArtistPageTourForm } from './ArtistPageTourForm';
import { ArtistPageAboutForm } from './ArtistPageAboutForm';
import { ArtistPagePressForm } from './ArtistPagePressForm';
import { ArtistPageReleasesForm } from './ArtistPageReleasesForm';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import type { FormProps, FormValues, TabId } from './types';
import {
  ListButton,
  Item,
  ItemCollection,
  ExceededPublishedLimitDialog,
  ArtistWysiwygOverlay,
  useCustomerArtistPageLimits,
} from './components';
import { useSubscriptionUpsell } from 'imdshared';
import { useArtistPageForm } from './useAritstPageForm';
import { usePublishArtistPage } from './usePublishArtistPage';
import { TemplateLanguageField } from '../../ReleasePageEditor/components/TemplateSideEditor/GeneralForm';
import { ReleasePageEditor } from '../../ReleasePageEditor';
import { useRouteMatch } from 'react-router';

const GlobalSettingsForm = () => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);
  return (
    <Field
      name="locale"
      label={t('language')}
      onClickHelp={() => {
        showHelpWindow(t('page-language'), t('page-helptext-language'));
      }}
      component={TemplateLanguageField}
    />
  );
};

const ArtistPageForm = reduxForm<FormValues, FormProps>({})(({
  tab,
  artistPageId,
  id,
}) => {
  switch (tab) {
    case 'tour':
      return <ArtistPageTourForm artistPageId={artistPageId} />;
    case 'about':
      return <ArtistPageAboutForm id={id} />;
    case 'press':
      return <ArtistPagePressForm id={id} />;
    case 'releases':
      return <ArtistPageReleasesForm id={id} />;
    default: {
      return <GlobalSettingsForm />;
    }
  }
});

const Wrapper = styled.div`
  padding: 32px;
  display: grid;
  gap: 24px;
  ${ReturnNavigation} {
    margin: 0;
  }
`;

const ArtistPageControlButtons = styled.div`
  display: flex;
  flex: 1;
  padding: 12px 16px;
  gap: 8px;
  & > * {
    width: 100%;
    flex: 1;
  }
`;

const useClickToCopy = (): [boolean, (v: string) => void] => {
  const [clicked, setClicked] = useState(false);
  const timeout = useRef<number>();
  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);
  const handleClick = useCallback((value: string) => {
    setClicked(true);
    copy(value);
    timeout.current = window.setTimeout(() => {
      setClicked(false);
    }, 2000);
  }, []);

  return [clicked, handleClick];
};

const useArtistPageOpenedEvent = (artistId: number) => {
  const matchOrder = useRouteMatch<{ product: string; productId: string }>(
    '/order/:product/:productId'
  );
  const matchLibrary = useRouteMatch<{ place: string }>('/library/:place');
  useEffect(() => {
    if (window.analytics && (matchLibrary || matchOrder)) {
      window.analytics.track('FT Artist Page Editor Opened', {
        artistId,
        section: matchOrder ? 'order' : `library/${matchLibrary?.params.place}`,
        product: matchOrder ? matchOrder.params.product : undefined,
        productId: matchOrder ? matchOrder.params.productId : undefined,
      });
    }
    return () => {
      if (window.analytics && (matchLibrary || matchOrder)) {
        window.analytics.track('FT Artist Page Editor Closed', {
          artistId,
          section: matchOrder
            ? 'order'
            : `library/${matchLibrary?.params.place}`,
          product: matchOrder ? matchOrder.params.product : undefined,
          productId: matchOrder ? matchOrder.params.productId : undefined,
        });
      }
    };
  }, []);
};

export function ArtistPageEditor({ artistId }: { artistId: number }) {
  useArtistPageOpenedEvent(artistId);
  const artistPageLimits = useCustomerArtistPageLimits();
  const { t } = useTranslation();
  const [selectedTab, setTab] = useState<TabId | null>(null);
  const [showLimitReachedForm, setShowLimitReachedForm] = useState(false);

  const activeArtistId = useRef(artistId);
  useEffect(() => {
    if (activeArtistId.current !== artistId) {
      setTab(null);
      activeArtistId.current = artistId;
    }
  }, [artistId]);

  const showHelp = useContext(HelpWindowContext);

  const handleLayoutNavigation = useCallback((e, tab) => {
    e.preventDefault();
    e.stopPropagation();
    setTab(tab);
  }, []);

  const form = `ArtistPageEditor-${artistId}`;
  const { change, initialValues, artistPageId, artistPage } =
    useArtistPageForm(artistId);

  const {
    publish,
    published,

    limit,
    loading,
    exceededLimit,
    artistPagesToResolve,
  } = usePublishArtistPage(artistId, artistPageId);

  useEffect(() => {
    if (published) {
      setShowLimitReachedForm(false);
    }
  }, [published]);

  const { open: openUpsell } = useSubscriptionUpsell();
  const [clicked, copyValue] = useClickToCopy();
  const [selectedReleasePageId, setSelectedReleasePageId] = useState<
    string | null
  >(null);

  const [generating, setGenerating] = useState(false);
  const timeout = useRef<number>();
  useEffect(() => {
    if (!artistPage) return;
    if (artistPage.status === 'generating') {
      setGenerating(true);
      return;
    }

    if (Date.now() - new Date(artistPage.updatedAt).valueOf() > 1000 * 60) {
      setGenerating(false);
      return;
    }
    if (artistPage.status === 'generated') {
      setGenerating(true);
      timeout.current = window.setTimeout(() => {
        setGenerating(false);
      }, 1000 * 60);
    }
  }, [artistPage?.status, artistPage?.updatedAt]);

  const handleChange = useCallback<typeof change>(
    (values, dispatch, meta, prevValues) => {
      if (prevValues.name) {
        setGenerating(true);
        window.clearTimeout(timeout.current);
      }
      change(values, dispatch, meta, prevValues);
    },
    [change]
  );

  return (
    <>
      <Wrapper>
        {selectedTab === null ? (
          <>
            {artistPageLimits.upgradable && (
              <SubscriptionFeatureGateButton
                action={t('upgrade')}
                label={t('unlock-more-artist-pages')}
                sublabel={t('generate-more-artist-pages')}
                onClick={() => {
                  openUpsell({
                    analytics: { detail: 'artist-page' },
                    section: 'promo-tools',
                    feature: 'artist-hub-pre-save',
                  });
                }}
              />
            )}
            <ItemCollection>
              {(['about', 'releases', 'tour', 'press'] as const).map((tab) => (
                <Item key={tab}>
                  {artistPageLimits[tab].enabled ? (
                    <ListButton onClick={() => setTab(tab)}>
                      <Body>{t(tab)}</Body>
                      <ComponentIcons.ChevronRight
                        style={{ marginLeft: 'auto' }}
                      />
                    </ListButton>
                  ) : (
                    <ListButton>
                      <ComponentIcons.Lock style={{ marginRight: '8px' }} />
                      <Body>{t(tab)}</Body>
                    </ListButton>
                  )}
                </Item>
              ))}
            </ItemCollection>
            <ItemCollection>
              <Item>
                <ListButton
                  disabled={!publish}
                  onClick={() => {
                    if (!published && exceededLimit > 0) {
                      setShowLimitReachedForm(true);
                    } else if (publish) {
                      setGenerating(true);
                      publish();
                    }
                  }}
                >
                  <Body>{t('publish-page')}</Body>
                  <ToggleSwitch
                    checked={published}
                    style={{ marginLeft: 'auto', flexGrow: 0 }}
                  />
                </ListButton>
              </Item>
              <Item>
                <ArtistPageControlButtons>
                  <Button
                    size="small"
                    text={clicked ? t('copied') : t('copy-url')}
                    showLoading={published && !artistPage?.wasGenerated}
                    disabled={
                      !artistPage ||
                      !published ||
                      (published && !artistPage?.wasGenerated)
                    }
                    onClick={() => {
                      if (artistPage) {
                        copyValue(artistPage?.url);
                      }
                    }}
                  />
                </ArtistPageControlButtons>
              </Item>
            </ItemCollection>
          </>
        ) : (
          <ReturnNavigation onClick={() => setTab(null)} text={t(selectedTab)}>
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                showHelp(
                  t(`${selectedTab}`),
                  t(`artist-page-${selectedTab}-helptext`)
                );
              }}
            >
              <ComponentIcons.HelpIcon />
            </IconButton>
          </ReturnNavigation>
        )}
        {initialValues && artistPage ? (
          // Always render form once values are set
          <ArtistPageForm
            onChange={handleChange}
            id={artistId}
            artistPageId={artistPage.id}
            tab={selectedTab}
            form={form}
            initialValues={initialValues}
          />
        ) : (
          // Render form loading if values are not ready
          <Centered>
            <LoadingIndicator />
          </Centered>
        )}
      </Wrapper>
      <ArtistWysiwygOverlay
        status={
          artistPage
            ? artistPage?.wasGenerated
              ? generating && published
                ? 'generating'
                : 'generated'
              : 'not-generated'
            : null
        }
        onReleaseClick={(_rid, { releasePageId }) => {
          setSelectedReleasePageId(releasePageId);
        }}
        form={form}
        onNavigation={handleLayoutNavigation}
        page={selectedTab}
      />
      {selectedReleasePageId && (
        <ReleasePageEditor
          id={selectedReleasePageId}
          linksOnly={true}
          onClose={() => {
            setSelectedReleasePageId(null);
          }}
        />
      )}

      <ExceededPublishedLimitDialog
        limit={limit}
        exceededLimit={exceededLimit}
        publish={publish}
        loading={loading}
        artistPagesToResolve={artistPagesToResolve}
        isOpen={showLimitReachedForm}
        onClose={() => {
          setShowLimitReachedForm(false);
        }}
      />
    </>
  );
}
