import React, { memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconButton, Badge } from 'imdshared';

const NotificationsButtonContainer = styled.div`
  position: relative;
`;

type Props = {
  unreadCount: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Bell = () => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2.25C8 1.69772 8.44772 1.25 9 1.25C9.55229 1.25 10 1.69772 10 2.25V3.00773C10.2202 3.01443 10.3944 3.02694 10.5578 3.0503C12.4226 3.31681 13.9793 4.60879 14.5849 6.39254C14.6992 6.72915 14.7733 7.12421 14.9214 7.91432L17 19H1L3.07856 7.91436C3.22671 7.12423 3.30078 6.72916 3.41507 6.39254C4.02068 4.60879 5.57742 3.31681 7.44223 3.0503C7.60562 3.02694 7.77981 3.01443 8 3.00773V2.25ZM14.5901 17H3.40985L5.0443 8.28294C5.20444 7.42887 5.25157 7.20436 5.3089 7.03552C5.67226 5.96527 6.60631 5.19009 7.72519 5.03018C7.9017 5.00495 8.13105 5 9 5C9.86895 5 10.0983 5.00495 10.2748 5.03018C11.3937 5.19009 12.3277 5.96527 12.6911 7.03552C12.7484 7.20435 12.7956 7.42886 12.9557 8.28289L14.5901 17Z"
      fill="black"
      fillOpacity="0.8"
    />
    <path
      d="M11 20.25V20.75C11 21.8546 10.1046 22.75 9 22.75C7.89543 22.75 7 21.8546 7 20.75V20.25H11Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

const NotificationsButton: React.FC<Props> = ({ unreadCount, onClick }) => (
  <NotificationsButtonContainer>
    <IconButton icon={Bell} onClick={onClick} />

    {unreadCount !== 0 && (
      <Badge
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          background-color: red;
        `}
        count={unreadCount}
      />
    )}
  </NotificationsButtonContainer>
);

export default memo(NotificationsButton);
