import styled from '@emotion/styled';
import { BodyXS } from 'imdui';
import { useEntries, useQuery } from 'imddata';
import type { EntityModels } from 'imddata';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import type { SubscriptionId } from 'imddata/types/entities';
import { useProductPriceFormatted } from 'imdshared';

const Check = () => {
  return (
    <svg
      className="check"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8107 5.93934C20.2249 5.35355 19.2751 5.35355 18.6893 5.93934L9.75 14.8787L6.31066 11.4393C5.72487 10.8536 4.77513 10.8536 4.18934 11.4393C3.60355 12.0251 3.60355 12.9749 4.18934 13.5607L8.68934 18.0607C9.27513 18.6464 10.2249 18.6464 10.8107 18.0607L20.8107 8.06066C21.3964 7.47487 21.3964 6.52513 20.8107 5.93934Z"
        fill="#3CBEB1"
      />
    </svg>
  );
};

const Cross = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.15"
        d="M8.06066 5.43934C7.47487 4.85355 6.52513 4.85355 5.93934 5.43934C5.35355 6.02513 5.35355 6.97487 5.93934 7.56066L10.3787 12L5.93934 16.4393C5.35355 17.0251 5.35355 17.9749 5.93934 18.5607C6.52513 19.1464 7.47487 19.1464 8.06066 18.5607L12.5 14.1213L16.9393 18.5607C17.5251 19.1464 18.4749 19.1464 19.0607 18.5607C19.6464 17.9749 19.6464 17.0251 19.0607 16.4393L14.6213 12L19.0607 7.56066C19.6464 6.97487 19.6464 6.02513 19.0607 5.43934C18.4749 4.85355 17.5251 4.85355 16.9393 5.43934L12.5 9.87868L8.06066 5.43934Z"
        fill="black"
        fillOpacity="0.95"
      />
    </svg>
  );
};
const SubValue = styled(BodyXS)`
  margin-top: 4px;
  display: inline-block;
  color: var(--fg-2);
`;

// const Tip = styled.span`
//   border-bottom: 1px dashed #f24180;
//   display: inline-flex;
// `;

const useHasDelayedCommission = () => {
  const today = moment();
  const delayDate = today.subtract(3, 'months').format('YYYY-MM-DD');
  const { query, queryHash } = useQuery({
    query: {
      perPage: 1,
      'filter.release_at.to': delayDate,
      'filter.status': 4,
    },
  });

  const { entries: deliveryBundles, request } =
    useEntries<EntityModels.DeliveryBundle>({
      entity: 'deliveryBundles',
      query,
      queryHash,
    });

  return request.loaded && deliveryBundles.length >= 1;
};

type FeatureList = Array<{ label: string; value: string }>;

type SubsFeatureList = Record<SubscriptionId | 'free' | 'tier-2', FeatureList>;

export const useFeatureList = () => {
  const [t] = useTranslation();
  const hasDelayedCommission = useHasDelayedCommission();
  const singlePrice = useProductPriceFormatted(
    'single',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );

  const emailSupportPrice = useProductPriceFormatted(
    'customer-support',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const phoneConsulationPrice = useProductPriceFormatted(
    'phone-consultation',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'tier-2-2'
  );
  const playlistPitchingPrice = useProductPriceFormatted(
    'playlist-pitching',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const releaseModificationsPrice = useProductPriceFormatted(
    'release-modifications',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const labelPrice = useProductPriceFormatted(
    'premium-label',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const cidPrice = useProductPriceFormatted(
    'youtubeci',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const instantMasteringPrice = useProductPriceFormatted(
    'mastering',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    'none'
  );
  const releasePageWithPresave = (
    <>
      <Check />
      <SubValue>{t('incl-pre-save-page')}</SubValue>
    </>
  );

  return useMemo(() => {
    const FREE = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '10' }),
      },
      {
        label: t('music-distribution'),
        value: t('pay-per-release', { price: singlePrice }),
      },
    ];
    const TIER_1_LEGACY = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '5' }),
      },
      {
        label: t('music-distribution'),
        value: t('pay-per-release', { price: singlePrice }),
      },
      { label: t('artists'), value: t('artists-included-with-release') },
    ];
    const TIER_2_LEGACY = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '0' }),
      },
      {
        label: t('music-distribution'),
        value: t('pay-per-release', { price: singlePrice }),
      },
      { label: t('artists'), value: t('artists-included-with-release') },
    ];
    const TIER_1 = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '0' }),
      },
      { label: t('music-distribution'), value: t('2-releases') },
      { label: t('artists'), value: t('unlimited-artists') },
    ];
    const TIER_2_BASE = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '0' }),
      },
      {
        label: t('music-distribution'),
        value: t('unlimited-releases-included'),
      },
    ];
    const TIER_3 = [
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '0' }),
      },
      {
        label: t('music-distribution'),
        value: t('unlimited-releases-included'),
      },
      { label: t('artists'), value: t('unlimited-artists') },
    ];
    const TRIAL = [
      {
        label: t('music-distribution'),
        value: t('pay-per-release', { price: singlePrice }),
      },
      {
        label: t('commission'),
        value: t('commission-rate', { salesCommission: '0' }),
      },
      { label: t('artists'), value: t('unlimited-artists') },
    ];
    const short: SubsFeatureList = {
      free: FREE,
      'music-analytics': FREE,
      'trial-monthly': TIER_1_LEGACY,
      'trial-yearly': TIER_1_LEGACY,
      'tier-1-yearly': TIER_1_LEGACY,
      'tier-1-monthly': TIER_1_LEGACY,
      'tier-2-monthly': TIER_2_LEGACY,
      'tier-2-yearly': TIER_2_LEGACY,
      'tier-1': TIER_1,
      'tier-2': TIER_2_BASE,
      'trial-tier-2': TRIAL,
      'tier-2-2': [
        ...TIER_2_BASE,
        {
          label: t('artists'),
          value: t('artists-included', {
            count: 2,
            defaultValue: '{{count}} artists',
          }),
        },
      ],
      'tier-2-5': [
        ...TIER_2_BASE,
        {
          label: t('artists'),
          value: t('artists-included', {
            count: 5,
            defaultValue: '{{count}} artists',
          }),
        },
      ],
      'tier-2-15': [
        ...TIER_2_BASE,
        {
          label: t('artists'),
          value: t('artists-included', {
            count: 15,
            defaultValue: '{{count}} artists',
          }),
        },
      ],
      'tier-2-25': [
        ...TIER_2_BASE,
        {
          label: t('artists'),
          value: t('artists-included', {
            count: 25,
            defaultValue: '{{count}} artists',
          }),
        },
      ],
      'tier-3': TIER_3,
      'professional-unlimited': TIER_3,
      'professional-unlimited-analytics': TIER_3,
      vip: TIER_3,
    };

    return {
      short,
      full: [
        {
          label: '200-plus-shops',
          section: 'music-distribution',
          values: {
            free: singlePrice,
            'trial-tier-2': singlePrice,
            'tier-1': (
              <>
                {t('2-releases-included')}
                <br />
                <SubValue>
                  {singlePrice} {t('per-release-after')}
                </SubValue>
              </>
            ),
            'tier-2-2': t('unlimited-releases-included'),
            'tier-2-5': t('unlimited-releases-included'),
            'tier-2-15': t('unlimited-releases-included'),
            'tier-2-25': t('unlimited-releases-included'),
            'tier-3': t('unlimited-releases-included'),
          },
        },
        {
          label: 'commission',
          tip: hasDelayedCommission ? 'commission-rate-delay-message' : null,
          section: 'music-distribution',
          values: {
            free: '10%',
            'tier-1': '0%',
            'trial-tier-2': '0%',
            'tier-2-2': '0%',
            'tier-2-5': '0%',
            'tier-2-15': '0%',
            'tier-2-25': '0%',
            'tier-3': '0%',
          },
        },
        {
          label: 'artists',
          section: 'music-distribution',
          values: {
            'trial-tier-2': <>{t('unlimited')}</>,
            free: <>{t('included-with-release')}</>,
            'tier-1': t('unlimited'),
            'tier-2-2': t('artists-included', {
              defaultValue: '{{count}} artists',
              count: 2,
            }),
            'tier-2-5': t('artists-included', {
              defaultValue: '{{count}} artists',
              count: 5,
            }),
            'tier-2-15': t('artists-included', {
              defaultValue: '{{count}} artists',
              count: 15,
            }),
            'tier-2-25': t('artists-included', {
              defaultValue: '{{count}} artists',
              count: 25,
            }),
            'tier-3': t('unlimited'),
          },
        },
        {
          label: 'content-id',
          section: 'music-distribution',
          values: {
            free: cidPrice,
            'trial-tier-2': cidPrice,
            'tier-1': (
              <>
                {t('10-tracks-per-year')}
                <br />
                <SubValue>
                  {cidPrice} {t('per-track-after')}
                </SubValue>
              </>
            ),
            'tier-2-2': t('unlimited-tracks'),
            'tier-2-5': t('unlimited-tracks'),
            'tier-2-15': t('unlimited-tracks'),
            'tier-2-25': t('unlimited-tracks'),
            'tier-3': t('unlimited-tracks'),
          },
        },
        {
          label: 'release-pages',
          section: 'music-distribution',
          values: {
            free: <Check />,
            'tier-1': releasePageWithPresave,
            'trial-tier-2': releasePageWithPresave,
            'tier-2-2': releasePageWithPresave,
            'tier-2-5': releasePageWithPresave,
            'tier-2-15': releasePageWithPresave,
            'tier-2-25': releasePageWithPresave,
            'tier-3': releasePageWithPresave,
          },
        },
        {
          label: 'custom-label-name',
          section: 'music-distribution',
          values: {
            free: labelPrice,
            'tier-1': labelPrice,
            'tier-2-2': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'release-customization',
          section: 'music-distribution',
          values: {
            free: releaseModificationsPrice,
            'tier-1': releaseModificationsPrice,
            'tier-2-2': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'classical-music-distribution',
          section: 'music-distribution',
          values: {
            free: <Cross />,
            'tier-1': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'revenue-splits',
          section: 'music-distribution',
          values: {
            free: <Cross />,
            'tier-1': <Cross />,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'email-support',
          section: 'support',
          values: {
            free: emailSupportPrice,
            'tier-1': <Check />,
            'tier-2-2': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'personal-consultation',
          section: 'support',
          values: {
            free: <Cross />,
            'trial-tier-2': phoneConsulationPrice,
            'tier-1': <Cross />,
            'tier-2-2': phoneConsulationPrice,
            'tier-2-5': phoneConsulationPrice,
            'tier-2-15': phoneConsulationPrice,
            'tier-2-25': phoneConsulationPrice,
            'tier-3': <Check />,
          },
        },
        {
          label: 'playlist-pitching',
          section: 'extra',
          values: {
            free: playlistPitchingPrice,
            'tier-1': playlistPitchingPrice,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'artist-pages',
          section: 'extra',
          values: {
            free: t('basic-features'),
            'tier-1': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'instant-mastering',
          section: 'extra',
          values: {
            free: instantMasteringPrice,
            'trial-tier-2': instantMasteringPrice,
            'tier-1': instantMasteringPrice,
            'tier-2-2': instantMasteringPrice,
            'tier-2-5': instantMasteringPrice,
            'tier-2-15': instantMasteringPrice,
            'tier-2-25': instantMasteringPrice,
            'tier-3': instantMasteringPrice,
          },
        },
        {
          label: 'music-analytics',
          section: 'extra',
          values: {
            free: <Cross />,
            'tier-1': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'official-artist-channel-youtube',
          section: 'extra',
          values: {
            free: <Cross />,
            'tier-1': <Check />,
            'trial-tier-2': <Check />,
            'tier-2-2': <Check />,
            'tier-2-5': <Check />,
            'tier-2-15': <Check />,
            'tier-2-25': <Check />,
            'tier-3': <Check />,
          },
        },
        {
          label: 'amp-rewards',
          section: 'extra',
          values: {
            free: <Cross />,
            'tier-1': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 50,
            }),
            'trial-tier-2': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
            'tier-2-2': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
            'tier-2-5': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
            'tier-2-15': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
            'tier-2-25': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
            'tier-3': t('amps-per-month', {
              defaultValue: '{{count}} Amps / month',
              count: 100,
            }),
          },
        },
      ],
      // LEGACY
      //
      //
      //
    };
  }, [
    t,
    hasDelayedCommission,
    instantMasteringPrice,
    playlistPitchingPrice,
    releaseModificationsPrice,
    labelPrice,
    singlePrice,
    cidPrice,
  ]);
};
