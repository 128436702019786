import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  TextFormatted,
  Caption,
  Button,
  ComponentIcons,
  PageLayer,
  H2,
} from 'imdui';
import { useCustomerFeatureRollout } from 'imddata';
import { LinkButton, SubscriptionUpsellContext } from 'imdshared';
import copy from 'copy-to-clipboard';
import {
  ControlsWrapper,
  ControlsGrid,
  ControlsText,
  UpsellMessage,
} from '../styles';
import { useHistory } from 'react-router-dom';
import { ReleasePageShowcase } from 'screens/Order/screens/ReleasePageWizard/screens/ReleasePageOverview';
import { ReleasePageOptions } from 'components/organisms';
import { ArtistHubIcon } from '@imus/base-ui';

type ReleasePageState = {
  isActive: boolean;
  isPremium: boolean;
  releasePageId: string;
  status: string;
  url: string | null;
};

export const StaticReleasePageAction = ({
  releaseId,
  releasePageState: rps,
}: {
  releaseId: number;
  releasePageState: ReleasePageState | undefined;
}) => {
  const releasePageRedirectState = useMemo(() => ({ releaseId }), [releaseId]);
  const { t } = useTranslation();

  if (rps?.isActive) return null;

  return (
    <LinkButton
      size="small"
      primary={true}
      state={releasePageRedirectState}
      to={'/order/release-page/new'}
      text={t('create')}
    />
  );
};

export const StaticReleasePageInfo = ({
  releasePageState: rps,
}: {
  releasePageState: ReleasePageState | undefined;
}) => {
  const { t } = useTranslation();

  const { open: openUpsell } = useContext(SubscriptionUpsellContext);
  const artistHubPro = useCustomerFeatureRollout({
    feature: 'artist-hub-pro',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });
  const artistHubProUnbranded = useCustomerFeatureRollout({
    feature: 'artist-hub-pro-unbranded',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });

  const history = useHistory();

  const [copied, setCopied] = useState(false);

  const { isPremium, releasePageId, url } = rps || {
    isPremium: false,
    isActive: false,
    releasePageId: null,
    url: undefined,
  };

  const canCustomize = isPremium || artistHubProUnbranded || artistHubPro;
  const canUpgrade = !artistHubProUnbranded && !isPremium;

  const version =
    artistHubProUnbranded || isPremium
      ? 'release-page-pro-plus'
      : artistHubPro
        ? 'release-page-pro'
        : 'release-page-free';

  const [isCompareWindowOpen, setCompareWindowOpen] = useState(false);

  if (!rps || !rps.isActive) {
    return (
      <UpsellMessage>
        <ArtistHubIcon />

        <div>
          <Content>{t('release-page')}</Content>
          <Caption>
            <TextFormatted text={t('release-page-description')} />
          </Caption>
        </div>
      </UpsellMessage>
    );
  }

  return (
    <>
      <ControlsWrapper>
        <ControlsGrid>
          <ControlsText>
            <Content>{t('page-link')}</Content>
            <Caption>
              {!url ? (
                t('please-wait-for-url')
              ) : (
                <a href={url} target="_blank" rel="norefferer noreferrer">
                  {url}
                </a>
              )}
            </Caption>
          </ControlsText>
          <Button
            size="small"
            text={!copied ? t('copy-to-clipboard') : t('copied')}
            disabled={!url}
            onClick={() => {
              if (url) {
                if (window.analytics) {
                  window.analytics.track('FT Artist Hub link copied', {
                    is_premium: isPremium,
                  });
                }
                setCopied(true);
                copy(url);
              }
            }}
          />
          <ControlsText>
            <Content>{t(version)}</Content>
            <Caption>
              {canUpgrade
                ? t('upgrade-to-get-more-features')
                : t('all-features-available')}
            </Caption>
          </ControlsText>
          <Button
            size="small"
            text={t('upgrade')}
            disabled={!canUpgrade}
            onClick={() => {
              setCompareWindowOpen(true);
              // openUpsell({ section: 'promo-tools' });
            }}
          />
          <ControlsText>
            <Content>{t('features')}</Content>
            <Caption>{t('edit-features-of-your-page')}</Caption>
          </ControlsText>
          <Button
            size="small"
            icon={canCustomize ? undefined : ComponentIcons.Lock}
            text={canCustomize ? t('customize') : ''}
            disabled={!canCustomize}
            onClick={() => {
              history.push(`/order/release-page/${releasePageId}/editor`);
            }}
          />
        </ControlsGrid>
      </ControlsWrapper>

      <PageLayer
        lockClickOutside={true}
        isOpen={isCompareWindowOpen}
        style={{ maxWidth: '1280px' }}
        close={() => {
          setCompareWindowOpen(false);
        }}
      >
        <div style={{ padding: '24px' }}>
          <H2 style={{ textAlign: 'center', margin: '0 0 32px 0' }}>
            {t('choose-release-page')}
          </H2>
          <ReleasePageOptions
            legacyPremium={rps.isPremium}
            onSelect={() => {
              setCompareWindowOpen(false);
              openUpsell({
                section: 'promo-tools',
                analytics: { detail: 'release-page' },
              });
            }}
            onCustomize={undefined}
            template={<ReleasePageShowcase releasePageId={rps.releasePageId} />}
          />
        </div>
      </PageLayer>
    </>
  );
};
