import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 640px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 1440px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`;

const CenteredContainer = styled.div<{
  maxContentWidth: number;
}>`
  z-index: inherit;
  position: relative;
  max-width: ${(props) => props.maxContentWidth}px;
  margin: 0 auto;
`;

type Props = {
  testId?: string;
  children: React.ReactNode;
  maxContentWidth?: number;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * ### CenterWrapper
 *
 * This component allows its children to be centered inside if the container width is exceeded.
 * The calculations are based on the default width (`1280px`) or the given one.
 */
const CenterWrapper: React.FC<Props> = ({
  testId,
  children,
  maxContentWidth = 1440,
  style,
  className,
}) => (
  <Wrapper style={style} className={className} data-test-id={testId}>
    <CenteredContainer maxContentWidth={maxContentWidth}>
      {children}
    </CenteredContainer>
  </Wrapper>
);

export default CenterWrapper;
