import { css } from '@emotion/react';

export const fileCardStyle = css`
  background-color: var(--bg-1);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export const pressSectionStyle = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const pressSectionHeaderStyle = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const descriptionStyle = css`
  color: var(--fg-2);
`;
