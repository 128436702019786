import { useTranslation } from 'next-i18next';
import { Window, Text, Card } from '@imus/services-ui';
import type { TemplateData } from '../types';
import styled from '@emotion/styled';
import { AutosizeGrid } from '@imus/base-ui';
import { CustomLinkCard, SocialCard } from './SocialCard';
import { GalleryCard } from './GalleryCard';
import { VideoCard } from './VideoCard';
import { TrackCard } from './TrackCard';
import { useEffect, useRef, useState } from 'react';

export { getSocialHandlerPrefix } from './SocialCard';

const BioText = styled(Text.BodyM)`
  color: var(--fg-2);
  position: relative;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const FullBioText = styled(Text.BodyM)`
  white-space: pre-line;
`;

const Grid = styled(AutosizeGrid)`
  & > * {
    min-height: 200px;
  }
  @media screen and (min-width: 512px) {
    & > * {
      aspect-ratio: 1;
    }
  }
`;

const ShowMoreButton = styled.button`
  margin-top: 8px;
  display: inline-flex;
  border: none;
  cursor: pointer;
  background: transparent;
`;

export const BioGrid = ({
  data,
  onRequestPlay,
  audioUrl,
}: {
  data: TemplateData;
  onRequestPlay?: () => void;
  audioUrl?: string;
}) => {
  const { t } = useTranslation();
  const bioTextRef = useRef<HTMLSpanElement | null>(null);
  const [displayShowMore, setDisplayShowMore] = useState(false);
  const [displayBioModal, setDisplayBioModal] = useState(false);
  useEffect(() => {
    if (!bioTextRef.current) return;
    if (bioTextRef.current?.scrollHeight > bioTextRef.current?.clientHeight) {
      setDisplayShowMore(true);
    }
  }, [data.description]);
  return (
    <>
      <Text.H2>{t('about')}</Text.H2>
      {data.description && (
        <div style={{ position: 'relative' }}>
          <BioText data-test-id="BioText" ref={bioTextRef}>
            {data.description}
          </BioText>
          {displayShowMore && (
            <ShowMoreButton
              data-test-id="ShowMoreButton"
              onClick={() => {
                setDisplayBioModal(true);
              }}
            >
              <Text.ContentM>{t('show-more')}</Text.ContentM>
            </ShowMoreButton>
          )}
        </div>
      )}
      <Window
        isOpen={displayBioModal}
        close={() => {
          setDisplayBioModal(false);
        }}
        title={t('about')}
      >
        <Card style={{ padding: '32px' }}>
          <Text.H2 style={{ marginBottom: '32px' }}>{t('about')}</Text.H2>
          <FullBioText data-test-id="FullBioText">
            {data.description}
          </FullBioText>
        </Card>
      </Window>
      <Grid gap={24} minWidth={216} maxColumnCount={3}>
        {data.media.map((m, index) => {
          switch (m.type) {
            case 'track': {
              return (
                <TrackCard
                  key={index}
                  {...m.data}
                  title={m.data.title}
                  release={m.data.release}
                  url={audioUrl || m.data.file?.url}
                  onRequestPlay={onRequestPlay}
                />
              );
            }
            case 'social': {
              return <SocialCard key={index} {...m.data} />;
            }
            case 'image-gallery': {
              if (!m.data) return null;
              return <GalleryCard key={index} images={m.data} />;
            }
            case 'video-embed': {
              return <VideoCard key={index} {...m.data} />;
            }
            case 'custom-link': {
              return <CustomLinkCard key={index} {...m.data} />;
            }
            default:
              return null;
          }
        })}
      </Grid>
    </>
  );
};
