import { difference, uniq } from 'ramda';
import { useMemo, useCallback, useEffect } from 'react';
import type { Query, EntityIdentifier } from '../../../types';
import { useEntityActions } from './useEntityActions';
import { useFetchRequestProvider } from './useFetchRequestProvider';

const isTemp = (id: string | number | undefined) =>
  typeof id === 'string' && id.slice(0, 6) === '[TEMP]';
type QueryComparator = (q1: Query, q2: Query) => boolean;

const compareQuery: QueryComparator = (
  { with: queryiedWith },
  { with: loadedWith }
) => {
  const newWiths =
    (queryiedWith && !loadedWith) ||
    (typeof loadedWith === 'string' &&
      typeof queryiedWith === 'string' &&
      difference(queryiedWith.split(','), loadedWith.split(',')).length > 0);
  return !newWiths;
};

type FetchEntityOptions = {
  entity: EntityIdentifier;
  passive?: boolean;
  queryHash?: string;
  id?: string | number;
  ids?: Array<string | number>;
  isSingle?: boolean;
  query?: Query;
};

export function useFetchEntity({
  entity,
  passive,
  queryHash: passedQueryHash,
  id,
  ids,
  isSingle,
  query: passedQuery,
}: FetchEntityOptions) {
  const { queryHash, query } = useMemo((): {
    queryHash?: string;
    query?: Query;
  } => {
    if (ids) {
      const finalIds = ids.filter((val) => !isTemp(val));
      if (finalIds.length) {
        return {
          queryHash: `${passedQueryHash}:${finalIds.join(',')}`,
          query: {
            ...(passedQuery || {}),
            'filter.ids': finalIds.join(','),
          },
        };
      }
      return { query: passedQuery, queryHash: passedQueryHash };
    }
    return { query: passedQuery, queryHash: passedQueryHash };
  }, [passedQuery, passedQueryHash, ids]);

  const request = useFetchRequestProvider({ entity, queryHash, id });

  const { loadedPages, loading, failed, loaded, loadedQuery, errorMessage } =
    request;

  const entityActions = useEntityActions(entity);

  const refresh = useCallback(() => {
    entityActions.refresh({
      id,
      query,
      queryHash,
    });
  }, [id, query, queryHash]);

  const loadMore = useCallback(() => {
    if (id) {
      return;
    }
    entityActions.load({
      queryHash,
      query: {
        ...(query || {}),
        ...(request.offset ? { offset: request.offset } : {}),
        ...(request.loadedPages ? { page: request.loadedPages + 1 } : {}),
      },
    });
  }, [loadedPages]);

  const mergedQuery = useMemo(
    () =>
      query &&
      loadedQuery &&
      typeof query.with === 'string' &&
      typeof loadedQuery.with === 'string'
        ? {
            ...query,
            with: uniq([
              ...loadedQuery.with.split(','),
              ...query.with.split(','),
            ]).join(','),
          }
        : query,
    [query, loadedQuery]
  );
  const reload = useCallback(() => {
    entityActions.load({
      queryHash,
      isSingle,
      id,
      query: mergedQuery,
    });
  }, [id, mergedQuery, queryHash]);

  useEffect(() => {
    const abortLoading = passive || failed;
    const inProgress = loaded || loading || errorMessage;
    const isSameQuery =
      mergedQuery && loadedQuery
        ? compareQuery(mergedQuery, loadedQuery)
        : true;

    if (
      abortLoading ||
      isTemp(id) ||
      (queryHash && inProgress) ||
      (isSameQuery && inProgress)
    ) {
      return;
    }

    entityActions.load(
      {
        queryHash,
        isSingle,
        id,
        query: mergedQuery,
      },
      { hook: true }
    );
  }, [
    passive,
    id,
    query,
    queryHash,
    loaded,
    failed,
    errorMessage,
    loadedQuery,
    loading,
  ]);

  return {
    reload,
    load: entityActions.load,
    request,
    loadMore,
    refresh,
  };
}
