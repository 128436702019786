import styled from '@emotion/styled';
import { OptionTabsWrapper, ToggleOptionLink } from 'imdui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router';
import { useLocation, matchPath, useRouteMatch } from 'react-router';

const TabsRow = styled(OptionTabsWrapper)`
  margin-bottom: 32px;
`;

export const PageNavigation = ({
  style,
  match,
  routes,
}: {
  style?: React.CSSProperties;
  match: RouteComponentProps['match'];
  routes: {
    exact?: boolean;
    to: string;
    paths?: string[];
    text: string;
    testId?: string;
  }[];
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const root = useRouteMatch({ exact: true, path: match.url });
  const matchUrl = match.url === '/' ? '' : match.url;
  const rts = routes.reduce<string[]>(
    (acc, r) =>
      r.paths
        ? [...acc, ...r.paths.map((p) => `${matchUrl}/${p}`)]
        : [...acc, `${matchUrl}/${r.to}`],
    []
  );
  console.log(location.pathname, rts);
  const showTabs = useMemo(
    () =>
      rts.find((r) => matchPath(location.pathname, { path: r, exact: true })),
    [location.pathname]
  );

  if (showTabs || root) {
    return (
      <TabsRow style={style} variant="standart">
        {routes.map(({ to, exact, testId, text }) => (
          <ToggleOptionLink
            key={`account-nav-${to}`}
            testId={testId}
            exact={exact}
            to={to.length ? `${matchUrl}/${to}` : match.url}
            text={t(text)}
          />
        ))}
      </TabsRow>
    );
  }
  return null;
};
