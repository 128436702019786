import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Content, Body, Caption } from 'imdui';

const ItemRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  ${Body} {
    flex: 1;
  }

  ${Content} {
    flex: 1;
    text-align: right;
  }

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const HasConversionRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  color: ${(props) => props.theme.foreground.secondary};

  ${Caption}:first-of-type {
    width: 6px;
    margin-right: 4px;
  }
`;

const cssBodyUserNotCustomer = css`
  margin: 20px auto;
`;

type Props = {
  isUserCustomer: boolean;
  cashCredit: string;
  promoCredit: string;
  hasConversion: boolean;
  className?: string;
};

const BalanceDisplay = ({
  hasConversion,
  isUserCustomer,
  cashCredit,
  promoCredit,
  className,
}: Props) => {
  const { t } = useTranslation();

  if (!isUserCustomer) {
    return <Body css={cssBodyUserNotCustomer}>{t('user-not-customer')}</Body>;
  }

  return (
    <div className={className}>
      <div>
        <ItemRow>
          <Body>
            {`${t('cash-credit')}`} {hasConversion && '*'}
          </Body>

          <Content data-test-id="BalanceDisplay-CashCredit">
            {cashCredit}
          </Content>
        </ItemRow>

        <ItemRow>
          <Body>{t('current-promo-credit')}</Body>

          <Content data-test-id="BalanceDisplay-PromoCredit">
            {promoCredit}
          </Content>
        </ItemRow>
      </div>

      {hasConversion && (
        <HasConversionRow>
          <Caption>*</Caption>

          <Caption>{t('conversion-rate-applies')}</Caption>
        </HasConversionRow>
      )}
    </div>
  );
};

export default BalanceDisplay;
