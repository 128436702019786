import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type React from 'react';

type TextProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  empty?: boolean;
};

type ThemeCSS = {
  theme: Theme;
};

const bodyTextColor = ({ theme }: ThemeCSS): SerializedStyles => css`
  color: ${theme.foreground.primary};
`;

const textColor = ({ theme }: ThemeCSS): SerializedStyles => css`
  color: ${theme.foreground.primary};
`;

const placeholderStyle = ({
  loading,
  empty,
}: TextProps): SerializedStyles | null => {
  if (loading) {
    return css`
      :after {
        content: '';
        top: 2.5px;
        left: 0;
        width: 100%;
        height: calc(100% - 5px);
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.05);
        position: absolute;
      }
    `;
  }

  if (empty) {
    return css`
      :after {
        content: '–';
        position: relative;
        color: rgba(0, 0, 0, 0.25);
      }
    `;
  }
  return null;
};

export const Caption = styled.span<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.15px;
  font-feature-settings: 'cv11' on;
  ${bodyTextColor};
  ${placeholderStyle};
`;

export const Body = styled.span<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;

  font-feature-settings: 'cv11' on;

  color: rgba(0, 0, 0, 0.8);
  ${textColor};
  ${placeholderStyle};
`;

export const Content = styled.span<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;

  font-feature-settings: 'cv11' on;

  color: rgba(0, 0, 0, 0.8);
  ${textColor};
  ${placeholderStyle};
`;

export const BodyXS = styled.span`
  font-family: 'Inter', sans-serif;
  color: var(--fg-2);
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
`;
export const BodySmall = styled.span<TextProps>`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  ${bodyTextColor};
  ${placeholderStyle};
`;

export const Action = styled.span<TextProps>`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-weight: 500;
  ${textColor};
  ${placeholderStyle};
`;

export const ContentSmall = styled.span<TextProps>`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-weight: 500;
  ${textColor};
  ${placeholderStyle};
`;

export const H3 = styled.h3<TextProps>`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 133% */

  letter-spacing: -0.15px;
  font-feature-settings: 'cv11' on;
  ${textColor};
  ${placeholderStyle};
`;

export const H4 = styled.h4`
  font-family: 'Inter', Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;

  font-feature-settings: 'cv11' on;

  color: rgba(0, 0, 0, 0.8);
  ${textColor};
  ${placeholderStyle};
`;

export const H2 = styled.h2<TextProps>`
  position: relative;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  line-height: 24px;

  letter-spacing: -0.01em;
  font-feature-settings: 'cv11' on;
  ${textColor};
  ${placeholderStyle};
`;

export const H1 = styled.h1<TextProps>`
  font-family: 'Inter', 'Roboto', 'sans-serif';
  position: relative;
  font-size: 28px;
  line-height: 24px;
  font-weight: 700;
  ${textColor};
  ${placeholderStyle};
`;
