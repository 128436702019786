//
import React, { useContext, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { EntityModels, ReduxState, DBNested } from 'imddata';
import {
  useEntryProvider,
  useBundle,
  useCustomerPrices,
  useCustomerSubscriptionState,
} from 'imddata';
import { touch } from 'redux-form';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { updateEntity } from 'imddata/actionTypes/request';
import { Portal } from 'react-portal';
import { ArtistIdContext, useSubscriptionUpsell } from 'imdshared';
import { ProductPageContext, ProductRating } from 'components';
import {
  useTrackValidator,
  RELEASE_DETAILS,
  TRACKS_DETAILS,
  DELIVERY,
  useReleaseStepManager,
  ADDITIONAL_FEATRUES,
} from './hooks';
import { MusicDistributionPriceBreakdown } from './PriceBreakdown';

import type {
  ProductState,
  ProductPageContextType,
} from '../../shared/ProductWizard';
import {
  ProductWizard,
  ProductPriceProvider,
  useProductPage,
  useProductPageContext,
} from '../../shared/ProductWizard';
import {
  PriceIconPortal,
  ProductPriceContext,
  ProductScreen,
  Stepper,
} from '../../shared';

import {
  // Summary,
  AdditionalFeatures,
  ReleaseDetails,
  Delivery,
  TracksDetails,
} from './screens';
import { Button, ButtonText, Card, H2, TextFormatted } from 'imdui';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { BodyS } from '@imus/services-ui/src/Text';
import { NextStepsBlock } from './NextStepsBlock';

const releaseRequiredFields = [
  'copyrightText',
  'pendingCover',
  'genreId',
  'artists',
  'title',
  'labelId',
];

const releaseFormKey = (releaseId: string | number) =>
  `Order-MD-Release-${releaseId}`;
const deliveryFormKey = (releaseId: string | number) =>
  `Order-MD-Delivery-${releaseId}`;

function useTouchReleaseFormFields(
  releaseId: string | number,
  productState: ProductState,
  currentStepId: string
) {
  // somehow trigger when form is initialized?
  // TODO
  const releaseStepIdx = productState.steps.findIndex(
    (step) => step.id === RELEASE_DETAILS
  );
  const nextStepVisited = productState.steps[1 + releaseStepIdx]?.visited;
  const form = releaseFormKey(releaseId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentStepId === RELEASE_DETAILS && nextStepVisited) {
      releaseRequiredFields.forEach((field) => dispatch(touch(form, field)));
    }
  }, [currentStepId, nextStepVisited]);
}

const deliveryRequiredFields = ['deliverAt', 'genreId', 'shops', 'countries'];

function useTouchDeliveryFields(
  releaseId: string | number,
  productState: ProductState,
  currentStepId: string
) {
  // somehow trigger when form is initialized?
  // TODO
  const releaseStepIdx = productState.steps.findIndex(
    (step) => step.id === DELIVERY
  );
  const nextStepVisited = productState.steps[1 + releaseStepIdx]?.visited;
  const form = deliveryFormKey(releaseId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentStepId === DELIVERY && nextStepVisited) {
      deliveryRequiredFields.forEach((field) => dispatch(touch(form, field)));
    }
  }, [currentStepId, nextStepVisited]);
}

const useResetDeliveryDates = ({
  bundleId,
  releaseId,
}: {
  bundleId: number | string;
  releaseId: number | string;
}) => {
  const dispatch = useDispatch();
  const resetDates = useSelector((state: ReduxState) => {
    const release = state.entities.releases.entities[releaseId];
    const bundle = state.entities.deliveryBundles.entities[bundleId];
    return (
      release?.isClassical && bundle?.deliveryTypeId === 'ultraexpressdelivery'
    );
  });

  useEffect(() => {
    if (resetDates) {
      dispatch(
        updateEntity('deliveryBundles', {
          id: bundleId,
          data: {
            deliverAt: null,
          },
        })
      );
    }
  }, [resetDates]);
};

// TODO:
// 1. Restore touchRequired logic on release
// 2. Restore RefetchRelease logic
const MusicDistributionManager: ProductPageContextType['StepperStateManager'] =
  ({ currentStepId }) => {
    const { id: bundleId, releaseId, state } = useProductPage();
    if (!releaseId) throw new Error('releaseId undef');

    useResetDeliveryDates({ bundleId, releaseId });
    useReleaseStepManager();
    useTouchReleaseFormFields(releaseId, state, currentStepId);
    useTouchDeliveryFields(releaseId, state, currentStepId);
    return null;
  };

const MD_STEPS = [
  { id: DELIVERY, label: 'delivery' },
  { id: RELEASE_DETAILS, label: 'release' },
  { id: TRACKS_DETAILS, label: 'tracks' },
  { id: ADDITIONAL_FEATRUES },
];

function RedirectManager({
  match,
  id,
}: {
  match: RouteComponentProps['match'];
  id: number | string;
}) {
  const entry = useEntryProvider<EntityModels.DeliveryBundle>({
    id,
    entity: 'deliveryBundles',
  });
  if (!entry) return null;

  if (entry.targets?.order?.isMet) {
    return <Redirect to={`${match.url}/${ADDITIONAL_FEATRUES}`} />;
  }

  if (entry.tracks.length) {
    return <Redirect to={`${match.url}/${TRACKS_DETAILS}`} />;
  }

  return <Redirect to={`${match.url}/${DELIVERY}`} />;
}

export const MD_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'md',
  title: 'music-distribution',
  steps: MD_STEPS,
  initialStepsState: MD_STEPS,
  entity: 'deliveryBundles',
  overviewEntity: 'deliveryBundleOverview',
  StepperStateManager: MusicDistributionManager,
  RedirectManager,
  StepRenderer: memo(function StepsRenderer({ stepId, ...props }) {
    const { id: bundleId, releaseId } = useProductPageContext();
    if (!releaseId) throw new Error('releaseId undef');
    const { validator: validateTrack, requiredFields } = useTrackValidator({
      bundleId,
    });
    switch (stepId) {
      case RELEASE_DETAILS: {
        const form = releaseFormKey(releaseId);
        return (
          <ReleaseDetails
            form={form}
            releaseId={releaseId}
            bundleId={bundleId}
            {...props}
          />
        );
      }
      case TRACKS_DETAILS:
        return (
          <TracksDetails
            requiredFields={requiredFields}
            validateTrack={validateTrack}
            bundleId={bundleId}
            releaseId={releaseId}
            {...props}
          />
        );
      case DELIVERY: {
        const form = deliveryFormKey(releaseId);
        return (
          <Delivery
            bundleId={bundleId}
            form={form}
            releaseId={releaseId}
            {...props}
          />
        );
      }
      case ADDITIONAL_FEATRUES:
        return (
          <AdditionalFeatures
            bundleId={bundleId}
            releaseId={releaseId}
            {...props}
          />
        );
      // case SUMMARY:
      //   return <Summary bundleId={bundleId} releaseId={releaseId} {...props} />;
      default:
        return null;
    }
  }),
};

const PricePortal = ({ bundle }: { bundle?: DBNested }) => {
  const { recalculate } = useContext(ProductPriceContext);
  useEffect(() => {
    recalculate();
  }, [
    bundle?.releases[0]?.release?.labelId,
    bundle?.releases[0]?.release?.releaseTypeId,
    bundle?.deliveryTypeId,
  ]);

  if (!bundle) return null;

  const breakdownNode =
    document && document.getElementById('MusicDistribution-PriceBreakdown');

  return (
    <>
      <PriceIconPortal>
        <MusicDistributionPriceBreakdown
          volumeCount={bundle.releases[0].release?.volumeCount}
          releaseTypeId={bundle.releases[0].release?.releaseTypeId}
          deliveryTypeId={bundle.deliveryTypeId}
        />
      </PriceIconPortal>
      {breakdownNode && (
        <Portal node={breakdownNode}>
          <MusicDistributionPriceBreakdown
            volumeCount={bundle.releases[0].release?.volumeCount}
            releaseTypeId={bundle.releases[0].release?.releaseTypeId}
            deliveryTypeId={bundle.deliveryTypeId}
          />
        </Portal>
      )}
    </>
  );
};

const TrialButton = styled(Button)`
  flex: auto;
  flex-grow: 0;
  margin-top: auto;
  border: none;
  box-shadow: none;
  @media screen and (min-width: 512px) {
    margin-left: auto;
  }
  ${ButtonText} {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    font-family: Inter;
  }
`;

const FreeTrialCard = styled(Card)`
  box-shadow: none;
  backdrop-filter: blur(0);
`;

const FreeTrialBack = styled.div`
  background: url('/images/free-trial-back.svg') no-repeat;
  background-size: cover;
`;

const FreeTrialContents = styled.div`
  background: rgba(0, 0, 0, 0.2);

  backdrop-filter: blur(6.5px);
  padding: 32px;
  display: flex;
  align-items: center;
  gap: 32px;
  background-size: cover;
  border: none;
  box-shadow: none;

  ${BodyS} {
    word-wrap: break-word;
  }
  div {
    flex-direction: column;
    display: flex;
  }
`;

const TrialText = styled.div`
  display: grid;
  gap: 8px;
  flex: 1;
  ${H2} {
    font-weight: 700 !important;
    color: #fff;
  }
  & * {
    font-weight: 500;
    color: #fff;
  }
`;

const FreeTrial = () => {
  const { t } = useTranslation();
  const { open: openUpsell } = useSubscriptionUpsell();

  const { data: subscriptionsData } = useCustomerSubscriptionState();
  if (
    !subscriptionsData?.options.find(
      (s) =>
        s.subscriptionId === 'trial-tier-2' ||
        s.subscriptionId === 'trial-monthly' ||
        s.subscriptionId === 'trial-yearly'
    )
  ) {
    return null;
  }
  return (
    <FreeTrialCard>
      <FreeTrialBack>
        <FreeTrialContents>
          <TrialText>
            <H2>{t('free-amplify-trial')}</H2>
            <BodyS>
              <TextFormatted text={t('free-amplify-trial-text')} />
            </BodyS>
          </TrialText>
          <TrialButton
            text={t('try-it-now')}
            onClick={() => {
              if (window.analytics) {
                window.analytics.track('FT Free Trial initiated');
              }
              openUpsell({ trial: true });
            }}
          />
        </FreeTrialContents>
      </FreeTrialBack>
    </FreeTrialCard>
  );
};

const ThankYouGrid = styled.div`
  display: grid;
  gap: 24px;

  grid-template-columns: 1fr;

  @media (min-width: 1280px) {
    grid-template-columns: 4fr 2fr;
  }
`;

export default function MusicDistribution(props: RouteComponentProps) {
  const thankYouMatch = useRouteMatch(props.match.path + '/thank-you');
  const { bundleId: id } = useParams<{ bundleId: string }>();
  const {
    entry,
    reload,
    request: { loaded },
  } = useBundle({ id });
  const {
    request: { loaded: loadedPrices },
  } = useCustomerPrices();

  const bundleRelease = entry?.releases[0];
  const releaseId = bundleRelease?.releaseId;
  const loading = !loaded || !entry?.releases[0].release || !loadedPrices;
  const artistIdValue = useMemo(
    () => ({
      spotify: !!entry?.shops?.find((s) => s.shopId === 'spotify'),
      appleMusic: !!entry?.shops?.find((s) => s.shopId === 'apple_music'),
    }),
    [entry?.shops]
  );

  const value = useMemo(() => {
    return {
      id,
      releaseId,
      ...MD_CONTEXT,
    };
  }, [id, releaseId]);

  useEffect(() => {
    if (thankYouMatch) {
      reload();
    }
  }, [thankYouMatch]);

  const { t } = useTranslation();

  if (entry && entry.status === 'ready') {
    return (
      <ProductScreen
        title={t(MD_CONTEXT.title)}
        stepper={
          <Stepper
            match={props.match}
            disabled={true}
            steps={MD_CONTEXT.steps?.map((step) => ({
              id: 'none',
              valid: true,
              visited: true,
              active: false,
              label: step.label || step.id,
            }))}
          />
        }
      >
        <ThankYouGrid>
          <div
            style={{ display: 'flex', gap: '32px', flexDirection: 'column' }}
          >
            <FreeTrial />
            <NextStepsBlock />
          </div>

          <ProductRating product="dmd" />
        </ThankYouGrid>
      </ProductScreen>
    );
  }

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceProvider>
        <ArtistIdContext.Provider value={artistIdValue}>
          <ProductWizard loading={loading} {...props} />
          <PricePortal bundle={entry} />
        </ArtistIdContext.Provider>
      </ProductPriceProvider>
    </ProductPageContext.Provider>
  );
}
