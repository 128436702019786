import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CenterWrapper, Headline } from 'imdui';

export const TopWrapper = styled(CenterWrapper)<{
  highlightedHeader: boolean;
}>`
  z-index: 1;
  margin-bottom: ${({ highlightedHeader }) => (highlightedHeader ? 32 : 0)}px;

  > div > ${Headline} {
    padding-top: 48px;
    margin-bottom: 24px;
  }
`;

export const descriptionCloudStyle = css`
  margin-bottom: 24px;
`;

export const childrenWrapperStyle = css`
  padding-bottom: 24px;
  z-index: 0;
`;
