import type { FormSubmitHandler, WrappedFieldProps } from 'redux-form';
import {
  formValueSelector,
  FormSection,
  reduxForm,
  FieldArray,
  Field,
  change,
} from 'redux-form';
import type { FormValues } from './types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  Button,
  HelpWindowContext,
  SubscriptionFeatureGateButton,
} from 'imdui';
import { InputField, SelectField, useSubscriptionUpsell } from 'imdshared';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useEntries, useCreateEntity } from 'imddata';
import type { ReduxState } from 'imddata';
import type { SocialProviders } from '@imus/artist-page-template';
import { getSocialHandlerPrefix } from '@imus/artist-page-template';
import { canPlayUrl, validateRequired, validateURL } from './utils';
import type { ArtistCollectionFieldProps } from './components';
import {
  ArtistIdContext,
  ArtistImagesField,
  TrackSelectField,
  AvatarField,
  useCustomerArtistPageLimits,
  ArtistCollectionField,
} from './components';
import { createMaxLengthValidation } from 'helpers/validation';
import isURL from 'validator/lib/isURL';
import {
  nestGalleryFiles,
  type ArtistGalleryNested,
  mapGalleryImage,
} from './useAritstPageForm';

type Media = FormValues['media'][number];
type MediaType = Media['type'];

type MediaTypeOption = { value: MediaType; label: string; disabled?: boolean };

type SocialTypeOption = { value: SocialProviders; label: string };

const validateVideoURL = (value?: string) => {
  const err = validateRequired(value) || validateURL(value);
  if (err) return err;
  return value && canPlayUrl(value) ? '' : 'not-supported-video-url';
};
const galleryAccept = {
  'image/jpeg': [],
  'image/png': [],
};
const TrackCardLimitReached = createContext(false);

const UsedGalleryIds = createContext<string[] | undefined>([]);

const GalleryIdField = ({ input, meta }: WrappedFieldProps) => {
  const artistId = useContext(ArtistIdContext);
  const dispatch = useDispatch();
  const galleryIds = useContext(UsedGalleryIds);
  const { query, queryHash } = useQuery({
    query: { 'filter.artists': artistId, with: 'images' },
  });
  const {
    entries: galleries,
    request: { loaded, failed },
    nestLoadFinshed,
  } = useEntries<ArtistGalleryNested>({
    nest: nestGalleryFiles,
    entity: 'artistGalleries',
    query,
    queryHash,
  });
  const {
    createEntry: createGallery,
    request: { id, failed: failedToCreate },
  } = useCreateEntity({
    entity: 'artistGalleries',
  });
  console.log('1', galleries);
  useEffect(() => {
    if (!input.value && ((loaded && nestLoadFinshed) || failed)) {
      const emptyGallery = galleries.find((g) => g.images?.length === 0);

      const unusedGallery = galleryIds
        ? galleries.find((g) => galleryIds.indexOf(g.id) < 0)
        : null;
      if (unusedGallery) {
        input.onChange(unusedGallery.id);
        console.log(unusedGallery);
        dispatch(
          change(
            meta.form,
            'data.gallery',
            unusedGallery.images.map(mapGalleryImage)
          )
        );
      } else if (emptyGallery) {
        input.onChange(emptyGallery.id);
      } else {
        console.log('creating gallery');
        createGallery({ data: { artistId } });
      }
    }
  }, [loaded, artistId, galleryIds?.length, nestLoadFinshed]);

  useEffect(() => {
    if (id) {
      input.onChange(id);
    }
    if (failedToCreate) {
      input.onChange(null);
    }
  }, [id, failedToCreate]);
  return null;
};

const useMediaTypes = () => {
  const artistPageLimits = useCustomerArtistPageLimits();
  const { t } = useTranslation();
  return useMemo<MediaTypeOption[]>(
    () =>
      (
        [
          { value: 'social', label: t('social-media') },
          { value: 'image-gallery', label: t('image-gallery') },
          { value: 'track', label: t('audio-player') },
          { value: 'video-embed', label: t('video-player') },
          { value: 'custom-link', label: t('custom-link') },
        ] as const
      ).map((v) => ({
        ...v,
        disabled:
          (artistPageLimits?.about?.allowedMediaTypes?.indexOf(v.value) || 1) <
          0,
      })),
    [t]
  );
};

const MediaForm = reduxForm<
  Media,
  { onSubmit: FormSubmitHandler<Media>; submitText: string }
>({ form: 'AddMediaForm' })(({
  handleSubmit,
  change,
  initialValues,
  submitText,
  form,
  onSubmit,
}) => {
  const trackCardLimitReached = useContext(TrackCardLimitReached);
  const artistPageLimits = useCustomerArtistPageLimits();
  const selectFormValues = useMemo(() => formValueSelector(form), [form]);
  const type: MediaTypeOption['value'] = useSelector((state: ReduxState) =>
    selectFormValues(state, 'type')
  );

  const socialProvider: SocialProviders = useSelector((state: ReduxState) =>
    selectFormValues(state, 'data.name')
  );
  const { t } = useTranslation();
  const mediaTypeData = useMediaTypes();

  const validateRequiredUrl = useCallback((v: string) => {
    return validateRequired(v) || validateURL(v);
  }, []);
  const { open: openUpsell } = useSubscriptionUpsell();
  const showHelp = useContext(HelpWindowContext);
  const socialTypesData: SocialTypeOption[] = useMemo(
    () =>
      (
        [
          {
            value: 'twitter',
          },
          {
            value: 'youtube',
          },
          {
            value: 'bandcamp',
          },
          {
            value: 'facebook',
          },
          {
            value: 'instagram',
          },
          {
            value: 'soundcloud',
          },
        ] as const
      ).map(({ value }) => ({ value, label: t(value) })),
    []
  );
  const submitHandler = useCallback<FormSubmitHandler<Media>>(
    (v: Media, ...rest) => {
      if (window.analytics) {
        window.analytics.track('FT Artist Page Media Card Added', {
          type: v.type,
          ...(v.type === 'custom-link'
            ? { urlLabel: v.data.name, url: v.data.url }
            : v.type === 'social'
              ? { socialProvider: v.data.name, handler: v.data.id }
              : v.type === 'video-embed' || v.type === 'track'
                ? v.data
                : v.type === 'image-gallery'
                  ? {
                      imageCount: v.data.gallery?.length,
                      galleryId: v.data.artistGalleryId,
                    }
                  : {}),
        });
      }
      onSubmit(v, ...rest);
    },
    [onSubmit]
  );
  return (
    <>
      {!!artistPageLimits.about.allowedMediaTypes?.length && (
        <SubscriptionFeatureGateButton
          style={{ width: '100%', marginBottom: '24px' }}
          action={t('upgrade')}
          label={t('unlock-more-media-types')}
          onClick={() => {
            openUpsell({
              analytics: { detail: 'artist-page' },
              section: 'promo-tools',
              feature: 'artist-hub-pre-save',
            });
          }}
        />
      )}
      <Field
        name="type"
        disabled={!!initialValues?.type}
        label={t('media-type')}
        placeholder={t('media-type')}
        data={mediaTypeData}
        onChange={() => {
          change('data', {});
        }}
        component={SelectField}
      ></Field>
      <FormSection name="data">
        {type === 'custom-link' && (
          <>
            <Field
              name="name"
              label={t('custom-link-name')}
              component={InputField}
              validate={validateRequired}
            />
            <Field
              name="url"
              label={t('custom-link-url')}
              component={InputField}
              validate={validateRequiredUrl}
            />
          </>
        )}
        {type === 'social' && (
          <>
            <Field
              name="name"
              label={t('social-type')}
              placeholder={t('social-type')}
              component={SelectField}
              validate={validateRequired}
              data={socialTypesData}
            />
            <Field
              name="id"
              label={t('social-id')}
              placeholder={t('social-id')}
              // TODO: remove @ from soundcloud, facebook, bandcamp
              format={(v: string) => {
                const prefix = getSocialHandlerPrefix(
                  socialProvider as SocialProviders,
                  v
                );
                if (!v) return '';
                return prefix + v;
              }}
              parse={(v: string) => {
                if (v && isURL(v)) {
                  try {
                    const url = new URL(v);
                    if (url.hostname.includes('bandcamp')) {
                      return url.hostname.split('.')[0];
                    }
                    let path = url.pathname.substring(1);
                    if (path[path.length - 1] === '/') {
                      path = path.substring(0, path.length - 1);
                    }
                    return path.replace(/[@]/g, '');
                  } catch (e) {
                    console.error(e);
                  }
                  return v;
                }
                if (!v) return '';
                return v.replace('@', '');
              }}
              component={InputField}
              validate={validateRequired}
            />
          </>
        )}
        {type === 'video-embed' && (
          <Field
            name="url"
            label={t('video-player-url')}
            component={InputField}
            validate={validateVideoURL}
            onClickHelp={() => {
              showHelp(t('video-player-url'), t('video-url-helptext'));
            }}
          />
        )}
        {type === 'image-gallery' && (
          <>
            <Field name="artistGalleryId" component={GalleryIdField} />
            <FieldArray
              name="gallery"
              accept={galleryAccept}
              rerenderOnEveryChange={true}
              component={ArtistImagesField}
            />
          </>
        )}
        {type === 'track' && (
          <Field
            name="trackId"
            label={t('track')}
            disabled={trackCardLimitReached && !initialValues?.type}
            validate={validateRequired}
            component={TrackSelectField}
          />
        )}
      </FormSection>
      <div style={{ marginTop: '24px' }}>
        <Button
          position="center"
          text={submitText}
          disabled={!type}
          onClick={handleSubmit(submitHandler)}
        />
      </div>
    </>
  );
});

const MediaCollectionField: typeof ArtistCollectionField<Media> = (props) => {
  const selector = useMemo(
    () => formValueSelector(props.meta.form),
    [props.meta.form]
  );

  const galleryIdsUsed = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return media
      ?.map((m) => (m.type === 'image-gallery' ? m.data.artistGalleryId : ''))
      .filter((v) => v !== '');
  });
  const trackAlreadyUsed = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return !!media?.find((m) => m.type === 'track');
  });

  const disabled = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return media?.length === 20;
  });
  return (
    <>
      <UsedGalleryIds.Provider value={galleryIdsUsed}>
        <TrackCardLimitReached.Provider value={trackAlreadyUsed}>
          <ArtistCollectionField disabled={disabled} {...props} />
        </TrackCardLimitReached.Provider>
      </UsedGalleryIds.Provider>
    </>
  );
};

export function ArtistPageAboutForm({ id }: { id: number }) {
  const { t } = useTranslation();
  const artistPageLimits = useCustomerArtistPageLimits();
  const isRegistered = useSelector(
    (state: ReduxState) => !!state.entities.artists.entities[id]?.registeredAt
  );

  const validateDescription = useMemo(
    () => createMaxLengthValidation(1120, t, false),
    []
  );
  const mediaTypes = useMediaTypes();
  const formatValueToItem = useCallback(
    (v: Media) => ({
      label: mediaTypes.find((mt) => mt.value === v.type)?.label || v.type,
    }),
    [t]
  );
  return (
    <ArtistIdContext.Provider value={id}>
      <Field
        name="profileImage"
        component={AvatarField}
        handler="artistProfileImage"
        handlerId={id}
      />
      <Field
        name="name"
        component={InputField}
        disabled={isRegistered}
        label={t('artist-name')}
        style={{ margin: 0 }}
      />
      <Field
        name="description"
        placeholder={t('artist-about-text')}
        component={InputField}
        multiline={true}
        validate={validateDescription}
        style={{ margin: 0 }}
      />
      <FieldArray<ArtistCollectionFieldProps<Media>, Media>
        name="media"
        component={MediaCollectionField}
        dragType="MEDIA-CARD"
        isDisabled={(v) =>
          (artistPageLimits.about.allowedMediaTypes?.indexOf(v.type) || 1) < 0
        }
        addText={t('add-media-card')}
        formatValueToItem={formatValueToItem}
        formComponent={MediaForm}
      />
    </ArtistIdContext.Provider>
  );
}
