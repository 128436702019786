import styled from '@emotion/styled';
import { BodyS, ContentS } from '@imus/services-ui/src/Text';
import type { SubscriptionId } from 'imddata/types/entities';
import { BodyXS, Button, Card } from 'imdui';
import { useTranslation } from 'react-i18next';

export const mapSubscriptionIdToColor = (sid: SubscriptionId | 'free') => {
  switch (sid) {
    case 'tier-1':
      return '--sub-amplify';

    case 'trial-tier-2':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
      return '--sub-amplify-plus';

    case 'tier-3':
      return '--sub-amplify-pro';

    case 'trial-monthly':
    case 'tier-2-monthly':
    case 'trial-yearly':
    case 'tier-2-yearly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
    case 'tier-1-monthly':
    case 'tier-1-yearly':
    case 'free':
    case 'music-analytics':
      return '';
  }
};

const perMonthOrPerYear = (
  sid: SubscriptionId | 'free'
): 'per-month' | 'per-year' | 'per-1-month-trial' | '' => {
  switch (sid) {
    case 'tier-1':
    case 'tier-3':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'trial-yearly':
    case 'tier-1-yearly':
    case 'tier-2-yearly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return 'per-year';

    case 'trial-tier-2':
      return 'per-1-month-trial';

    case 'music-analytics':
    case 'tier-1-monthly':
    case 'trial-monthly':
    case 'tier-2-monthly':
      return 'per-month';

    case 'free':
      return '';
  }
};

type Feature = { value: string };

type Props = {
  nextPrice?: string;
  recommendedText?: string;
  subtitle?: string;
  featureChildren?: React.ReactNode;
  subscriptionId: SubscriptionId | 'free';
  price?: React.ReactNode;
  features: Array<Feature>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  actionText?: string;
  className?: string;
  testId?: string;
  loading?: boolean;
};

const SubscriptionHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;
export const SubscriptionName = styled.span<{
  subscriptionId: SubscriptionId | 'free';
}>`
  --sub-color: var(
    ${({ subscriptionId }) => mapSubscriptionIdToColor(subscriptionId)}
  );
  font-family: Barlow, Inter;
  color: var(--sub-color, var(--fg-1));
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 106.667% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

const Section = styled.div``;
const FeatureSection = styled.div`
  display: grid;
  gap: 2px;
  margin-bottom: auto;
  align-self: flex-start;
`;

const Actions = styled.div`
  display: grid;
  gap: 16px;
  margin-top: auto;
`;

const FeatureBase = ({
  value,
  style,
  className,
  children,
}: Feature & {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => (
  <div style={style} className={className}>
    <ContentS>{value}</ContentS>
    {children}
  </div>
);

export const SubscriptionCardFeature = styled(FeatureBase)`
  display: flex;
  height: 24px;
  align-items: center;

  justify-content: space-between;
  & > * {
    display: flex;
  }
`;

export const SubscriptionAction = styled(Button)<{
  subscriptionId: SubscriptionId | 'free';
}>`
  --sub-color: var(
    ${({ subscriptionId }) => mapSubscriptionIdToColor(subscriptionId)}
  );
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--foreground-fg-4, rgba(0, 0, 0, 0.1));
  ${({ subscriptionId }) =>
    subscriptionId != 'free'
      ? `
  span {
    color: white !important;
  }
  `
      : ''}

  background: var(
                --sub-color, var(--fg-4)
               );
  /* Shadow/Tab Item */
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);
`;

const Price = styled.span`
  color: var(--foreground-fg-1, rgba(0, 0, 0, 0.95));
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
`;
const RecommendedChip = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  right: -4px;
  top: -7px;
  color: #fff;

  font-family: Barlow;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 120% */
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 30px;
  background: var(--sub-color);
`;

const SubscriptionCardBase = ({
  subscriptionId,
  price,
  features,
  testId,
  children,
  style,
  className,
  actionText,
  onClick,
  featureChildren,
  subtitle,
  recommendedText = '',
  loading,
  nextPrice,
}: Props) => {
  const { t } = useTranslation();
  const cadence = perMonthOrPerYear(subscriptionId);
  const nextCadence = perMonthOrPerYear(
    subscriptionId === 'trial-tier-2' ? 'tier-2-2' : 'free'
  );
  return (
    <Card data-test-id={testId} style={style} className={className}>
      {recommendedText && (
        <RecommendedChip>{t(recommendedText)}</RecommendedChip>
      )}
      <Section>
        <SubscriptionHeader>
          <SubscriptionName subscriptionId={subscriptionId}>
            {t(subscriptionId, { context: 'plan' })}
          </SubscriptionName>
          {subtitle && <BodyXS>{subtitle}</BodyXS>}
        </SubscriptionHeader>
        <div>
          <Price>{price}</Price>
          {cadence && cadence.length > 0 && (
            <BodyS>
              &nbsp;
              {t(cadence)}
            </BodyS>
          )}
          {nextPrice && (
            <>
              <br />

              <BodyS>
                {t('next-subscription-price', {
                  context: nextCadence,
                  cadence: t(nextCadence),
                  price: nextPrice,
                  defaultValue: '{{price}} {{cadence}} after',
                })}
              </BodyS>
            </>
          )}
        </div>
      </Section>
      <FeatureSection>
        {features.map((f, idx) => (
          <SubscriptionCardFeature key={idx} {...f} />
        ))}
        {featureChildren}
      </FeatureSection>
      <Actions>
        {children}
        {onClick && (
          <SubscriptionAction
            testId={testId + '-Action-enabled'}
            showLoading={loading}
            subscriptionId={subscriptionId}
            text={actionText}
            onClick={onClick}
          />
        )}
      </Actions>
    </Card>
  );
};

export const SubscriptionCard = styled(SubscriptionCardBase)<Props>`
  overflow: initial;
  --sub-color: var(
    ${({ subscriptionId }) => mapSubscriptionIdToColor(subscriptionId)}
  );
  border-radius: 24px;
  position: relative;
  box-shadow: ${({ subscriptionId }) =>
    subscriptionId ? 'inset 0 0 0 3px var(--sub-color)' : 'none'};
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
`;
