import React from 'react';
import type { TemplateData, TourPageEvent } from '../types';
import { TourItem, Text } from '@imus/services-ui';
import { tourInfoStyle } from './styles';

type Props = {
  data: TemplateData;
};

export const TourInfo: React.FC<Props> = ({ data }) => {
  const eventsByYear: Record<string, TourPageEvent[]> | undefined =
    data.tour?.events?.reduce(
      (acc, event) => {
        const year = new Date(event.date).getFullYear().toString();
        return {
          ...acc,
          [year]: [...(acc[year] || []), event],
        };
      },
      {} as Record<string, TourPageEvent[]>
    );

  if (!eventsByYear) {
    return null;
  }

  return (
    <>
      {Object.keys(eventsByYear).map((year: string) => (
        <div key={year} css={tourInfoStyle}>
          <Text.H2>{year}</Text.H2>

          {eventsByYear[year]?.map((event, idx) => (
            <TourItem key={idx} event={event} locale={data.general.locale} />
          ))}
        </div>
      ))}
    </>
  );
};
