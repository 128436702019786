import React from 'react';
import type { TemplateData } from '../types';
import { PressFileItem, Text } from '@imus/services-ui';

import {
  fileCardStyle,
  pressSectionStyle,
  pressSectionHeaderStyle,
  descriptionStyle,
} from './styles';
import { useTranslation } from 'next-i18next';
type Props = {
  data: TemplateData;
};

type FileProps = {
  idx: number;
  title: string;
  name?: string;
  description: string;
  file?: { url: string; extension: string; size?: number };
};

const PressItem = ({ file, description, title, idx }: FileProps) => {
  const { t } = useTranslation();

  return (
    <div css={pressSectionStyle} data-test-id={'PressSection-' + idx}>
      <div css={pressSectionHeaderStyle}>
        <Text.H4>{title}</Text.H4>

        {description && (
          <Text.BodyM css={descriptionStyle}>{description}</Text.BodyM>
        )}
      </div>
      <div>
        {file && (
          <PressFileItem
            css={fileCardStyle}
            name={t('download-press-file')}
            {...file}
          />
        )}
      </div>
    </div>
  );
};

export const PressKit: React.FC<Props> = ({ data }) => (
  <>
    {data.press?.pressFiles?.map(({ title, file, description }, idx) => {
      if (!file) return null;

      return (
        <PressItem
          key={idx}
          idx={idx}
          title={title}
          description={description}
          file={file}
        />
      );
    })}
  </>
);
