import React from 'react';
import { useTranslation } from 'next-i18next';
import { LinkButton, Text } from '@imus/services-ui';
import { artistSectionStyle, artistDescriptionStyle } from './styles';

type Props = {
  title: string;
  teaser: string;
  text: string;
  url?: string;
  urlLabel?: string;
};

const ArtistSection: React.FC<Props> = ({
  title,
  teaser,
  text,
  url,
  urlLabel,
}) => {
  const { t } = useTranslation();

  return (
    <div css={artistSectionStyle}>
      <Text.H2>{title}</Text.H2>

      {teaser && <Text.BodyL css={artistDescriptionStyle}>{teaser}</Text.BodyL>}

      {text && <Text.BodyL css={artistDescriptionStyle}>{text}</Text.BodyL>}

      {url && (
        <LinkButton
          data-component="artist-link"
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {urlLabel || t('visit-artist-page')}
        </LinkButton>
      )}
    </div>
  );
};

export default ArtistSection;
