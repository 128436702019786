import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, HelpWindowContext, OverlineText } from 'imdui';
import config from 'imdconfig';
import { ErrorBoundary, CopyToClipboardField } from 'components';
import { useCurrentCustomer } from 'imddata';
import styled from '@emotion/styled';

const CopyToClipboardFieldStyled = styled(CopyToClipboardField)`
  background: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline;
    background: transparent;
  }
`;

export default function ReferralCard() {
  const { t } = useTranslation();
  const { entry: customer } = useCurrentCustomer();
  // TODO: create useReferallLink to avoid duplication
  if (!customer) return null;
  const { referralToken, referralReward } = customer;
  if (!referralReward) return null;

  const linkReady =
    typeof referralToken === 'string' && referralToken.length > 0;

  const link = linkReady
    ? `${config.imdfront.appUrl}/signup?referral_token=${referralToken}`
    : t('creating-referral-link');

  const showHelp = useContext(HelpWindowContext);

  return (
    <Card style={{ padding: '24px' }}>
      <OverlineText
        size="large"
        style={{ marginBottom: '8px' }}
        label={t('referral-link')}
        onClickHelp={() => {
          showHelp(t('referral-link'), t('referral-rewards-description'));
        }}
      />
      <ErrorBoundary>
        <div style={{ marginTop: 'auto' }}>
          <CopyToClipboardFieldStyled
            hideLink={linkReady}
            disabled={!linkReady}
            value={link}
          />
        </div>
      </ErrorBoundary>
    </Card>
  );
}
