//
import React, { useRef, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { ReduxState } from 'imddata';
import {
  useCurrentCustomerSubscription,
  useDeleteEntity,
  useUserNotifications,
} from 'imddata';
import { Button } from 'imdui';
import { AppBanner, AppBannerOutdated, AppOutdatedContext } from 'imdshared';

import { getSubCardVariant } from 'components/subscriptions';
import { BlackBanner, PromoBanner } from './PromoBanner';
import type { SubscriptionId } from 'imddata/types/entities';
import { useWinbackYearly } from './useWinbackYearly';
import { WinbackYearlyWindowContent } from './WinbackYearlyContents';

const cardErrorSvg = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.4238 11.8281L43.9424 28.7451H40.2402L39.7588 11.8281H44.4238ZM39.626 33.958C39.626 33.305 39.8473 32.7572 40.29 32.3145C40.7438 31.8607 41.3525 31.6338 42.1162 31.6338C42.8799 31.6338 43.4831 31.8607 43.9258 32.3145C44.3796 32.7572 44.6064 33.305 44.6064 33.958C44.6064 34.5999 44.3796 35.1423 43.9258 35.585C43.4831 36.0277 42.8799 36.249 42.1162 36.249C41.3525 36.249 40.7438 36.0277 40.29 35.585C39.8473 35.1423 39.626 34.5999 39.626 33.958Z"
      fill="black"
      fillOpacity="0.8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 14H22.4C24.1132 14 25.2777 14.0016 26.1779 14.0751C27.0548 14.1467 27.5032 14.2766 27.816 14.436C28.5686 14.8195 29.1805 15.4314 29.564 16.184C29.7234 16.4968 29.8533 16.9452 29.9249 17.8221C29.9296 17.8803 29.9341 17.9395 29.9383 18H2.06173C2.0659 17.9395 2.07035 17.8803 2.0751 17.8221C2.14674 16.9452 2.27659 16.4968 2.43597 16.184C2.81947 15.4314 3.43139 14.8195 4.18404 14.436C4.49684 14.2766 4.94524 14.1467 5.82208 14.0751C6.72225 14.0016 7.88684 14 9.6 14ZM2 23V26.4C2 28.1132 2.00156 29.2777 2.0751 30.1779C2.14674 31.0548 2.27659 31.5032 2.43597 31.816C2.81947 32.5686 3.43139 33.1805 4.18404 33.564C4.49684 33.7234 4.94524 33.8533 5.82208 33.9249C6.72225 33.9984 7.88684 34 9.6 34H22.4C24.1132 34 25.2777 33.9984 26.1779 33.9249C27.0548 33.8533 27.5032 33.7234 27.816 33.564C28.5686 33.1805 29.1805 32.5686 29.564 31.816C29.7234 31.5032 29.8533 31.0548 29.9249 30.1779C29.9984 29.2777 30 28.1132 30 26.4V23H2ZM0 21.6C0 18.2397 0 16.5595 0.653961 15.2761C1.2292 14.1471 2.14708 13.2292 3.27606 12.654C4.55953 12 6.23969 12 9.6 12H22.4C25.7603 12 27.4405 12 28.7239 12.654C29.8529 13.2292 30.7708 14.1471 31.346 15.2761C32 16.5595 32 18.2397 32 21.6V26.4C32 29.7603 32 31.4405 31.346 32.7239C30.7708 33.8529 29.8529 34.7708 28.7239 35.346C27.4405 36 25.7603 36 22.4 36H9.6C6.23969 36 4.55953 36 3.27606 35.346C2.14708 34.7708 1.2292 33.8529 0.653961 32.7239C0 31.4405 0 29.7603 0 26.4V21.6Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

const useIsAppOutdated = () => {
  const optimizelyUrl = useSelector(
    ({ auth }: ReduxState) => auth.profile?.extra?.optimizely?.url
  );

  const { isAppOutdated, setIsAppOutdated } = useContext(AppOutdatedContext);
  const loadedUrl = useRef<undefined | string>(undefined);
  useEffect(() => {
    if (!loadedUrl.current) {
      loadedUrl.current = optimizelyUrl;
      return;
    }
    if (loadedUrl.current !== optimizelyUrl && optimizelyUrl) {
      loadedUrl.current = optimizelyUrl;
      setIsAppOutdated(true);
    }
  }, [optimizelyUrl]);

  return isAppOutdated;
};

const FailedRenewalBanner = ({
  id,
  subscriptionId,
}: {
  id: number;
  subscriptionId: SubscriptionId;
}) => {
  const { deleteEntry: markAsRead } = useDeleteEntity({
    entity: 'userNotifications',
  });

  const history = useHistory();
  const { t } = useTranslation();
  return (
    <AppBanner
      asset={cardErrorSvg}
      onRequestClose={() => {
        markAsRead({ id });
      }}
      title={t('subscription-renewal-failed-title')}
      text={t('subscription-renewal-failed-body', {
        subscription: t(`${subscriptionId as string}`),
      })}
    >
      <Button
        text={t('continue')}
        onClick={() => {
          markAsRead({ id });
          history.push('/account/subscriptions');
        }}
      />
    </AppBanner>
  );
};

const RescueBanner = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const sub = useCurrentCustomerSubscription();
  return (
    <AppBanner
      asset={cardErrorSvg}
      title={t('subscription-renewal-unsuccessful-title')}
      text={t('subscription-renewal-unsuccessful-body', {
        subscription: t(sub?.subscriptionId + ''),
      })}
    >
      <Button
        text={t('continue')}
        onClick={() => {
          history.push('/account/payment-methods');
        }}
      />
    </AppBanner>
  );
};

export const ConnectedAppBanner = () => {
  const isAppOutdated = useIsAppOutdated();
  const { t } = useTranslation();

  const [showFixWindow, setShowFixWindow] = useState(false);
  const loggedIn = useSelector(({ auth: loggedUser }: ReduxState) =>
    loggedUser.data ? !!loggedUser.data.customerId : false
  );
  const {
    active: activeWinback,
    toggleRenewal,
    subscriptionId,
  } = useWinbackYearly();

  const { entries: notifications } = useUserNotifications({
    passive: !loggedIn,
    all: true,
  });
  const failedRenewalNotification = notifications?.find(
    (n) => n.notification.type === 'subscription-renewal-failed' && !n.wasRead
  );

  const rescueNotification = notifications?.find(
    (n) => n.notification.type === 'payment-rescue-attempt' && !n.wasRead
  );

  if (activeWinback) {
    return (
      <>
        <BlackBanner
          text={t('win-back-resub-text', {
            subscription: t(getSubCardVariant(subscriptionId) || ''),
          })}
          buttonText={t('fix-it-now')}
          onClick={() => {
            setShowFixWindow(true);
          }}
        />
        <WinbackYearlyWindowContent
          isOpen={showFixWindow}
          close={() => setShowFixWindow(false)}
          onSubmit={() => {
            toggleRenewal();
          }}
        />
      </>
    );
  }

  if (rescueNotification) {
    return <RescueBanner />;
  }

  if (failedRenewalNotification) {
    const failedSubId =
      failedRenewalNotification.notification.data?.subscriptionId;
    return (
      <FailedRenewalBanner
        id={failedRenewalNotification.id}
        subscriptionId={failedSubId as SubscriptionId}
      />
    );
  }

  if (isAppOutdated) {
    return <AppBannerOutdated />;
  }

  return <PromoBanner />;
};
