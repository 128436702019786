/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled';
import React from 'react';

type SVGGroupPath = React.ComponentPropsWithoutRef<'path'>;

type SVGGroupSize = {
  width: string | number;
  height: string | number;
};

type SVGGroupProps = {
  group: Array<SVGGroupPath>;
  title?: string;
  size?: SVGGroupSize;
  viewBox?: string;
  className?: string;
  style?: any;
};

const SVGStyled = styled.svg`
  fill: #333;
`;

const SVGGroup: React.FC<SVGGroupProps> = ({
  group,
  title,
  viewBox = '0 0 20 20',

  className,
  style,
  size = { width: 20, height: 20 },
  ...other
}) => (
  <SVGStyled
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...{ xmlnsXlink: 'http://www.w3.org/1999/xlink' }}
    className={className}
    style={style}
    width={size.width}
    height={size.height}
    viewBox={viewBox}
    aria-labelledby={title}
    {...other}
  >
    {group.map((path: SVGGroupPath, index: number) => {
      return (
        <path key={`svg-${Element}-${index}`} fill={path.color} {...path} />
      );
    })}
  </SVGStyled>
);

export default SVGGroup;
