import React, { useContext, useMemo, useState } from 'react';
import type { FieldArrayFieldsProps } from 'redux-form';
import { FieldArray, FormSection, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import type { JoinTagsProps } from 'imdshared';
import {
  EnabledField,
  JoinTags,
  HelpWindowContext,
  NewInputField,
} from 'imdshared';
import { TabItem, FieldGroup } from 'imdui';
import isURL from 'validator/lib/isURL';
import { createStringValidator } from './utils';

const TabsWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

const joinData = {
  tags: [],
};

const validateURL = (value?: string) => {
  if (!value) return '';
  return isURL(value, {
    protocols: ['https', 'http'],
    require_protocol: true,
    require_valid_protocol: true,
  })
    ? ''
    : 'invalid-url';
};

const ArtistJoin = ({
  ...props
}: {
  value: JoinTagsProps['value'];
  allowedIds: JoinTagsProps['allowedIds'];
  onChange: JoinTagsProps['onChange'];
}) => {
  const { t } = useTranslation();
  return (
    <JoinTags
      {...props}
      idKey="artistId"
      style={{ margin: 0 }}
      allowDuplicates={false}
      renderForm={null}
      // @ts-ignore
      data={joinData}
      draggable={true}
      placeholder={t('add-artist-placeholder')}
      addLabel={t('add-artist-from-release')}
      label={t('add-artist-from-release')}
      floatingLabelText={t('add-artist')}
      entity="artists"
    />
  );
};
type ArtistValue = {
  id: number;
  name: string;
  title: string;
  teaser: string;
  text: string;
};

/* eslint-disable react/no-array-index-key */
const renderArtists = ({
  fields,
  artistIds,
}: {
  fields: FieldArrayFieldsProps<ArtistValue>;
  artistIds: Array<number>;
}) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const showHelpWindow = useContext(HelpWindowContext);
  const selectedIds =
    fields
      .getAll()
      ?.filter((entry) => !!entry?.id)
      .map((entry) => ({ artistId: entry.id })) || [];

  const validateTeaser = useMemo(() => createStringValidator(300, t), []);
  const validateUrlLabel = useMemo(() => createStringValidator(255, t), []);
  const validateTitle = useMemo(() => createStringValidator(1024, t, true), []);
  const validateText = useMemo(() => createStringValidator(2000, t, true), []);

  return (
    <>
      <FieldGroup>
        <ArtistJoin
          allowedIds={artistIds?.length ? artistIds : undefined}
          onChange={(value, actionObj) => {
            switch (actionObj.action) {
              case 'add':
                fields.push({
                  id: actionObj.option.value,
                  name: actionObj.option.label,
                  title: actionObj.option.label,
                  teaser: '',
                  text: '',
                });
                break;
              case 'remove':
                fields.remove(actionObj.index);
                break;
              case 'move':
                fields.move(actionObj.fromIndex, actionObj.toIndex);
                break;
              default:
                console.log(actionObj);
            }
          }}
          value={selectedIds}
        />
      </FieldGroup>
      <FieldGroup>
        <TabsWrapper>
          {fields.map((artist, index) => {
            const data = fields.get(index);
            return (
              <TabItem
                key={index}
                text={data.name || `${data.id}`}
                selected={tab === index}
                testId="sidewindow-edit-tab"
                onClick={() => setTab(index)}
              />
            );
          })}
        </TabsWrapper>
        {fields.map((artist, index) => (
          <div
            style={{ display: tab === index ? 'block' : 'none' }}
            key={`artist${index}`}
          >
            <Field
              name={`${artist}.title`}
              placeholder={t('artist-title')}
              label={t('artist-title')}
              required={true}
              validate={validateTitle}
              onClickHelp={() => {
                showHelpWindow(
                  t('release-page-artist-title'),
                  t('release-page-helptext-artist-title')
                );
              }}
              component={NewInputField}
            />
            <Field
              name={`${artist}.teaser`}
              placeholder={t('artist-description')}
              label={t('artist-description')}
              validate={validateTeaser}
              multiline={true}
              onClickHelp={() => {
                showHelpWindow(
                  t('artist-description'),
                  t('release-page-helptext-artist-description')
                );
              }}
              component={NewInputField}
            />
            <Field
              name={`${artist}.text`}
              placeholder={t('artist-copyrightText')}
              label={t('artist-copyrightText')}
              multiline={true}
              required={true}
              validate={validateText}
              onClickHelp={() => {
                showHelpWindow(
                  t('artist-copyrightText'),
                  t('release-page-helptext-artist-copyrightText')
                );
              }}
              component={NewInputField}
            />
            <Field
              name={`${artist}.url`}
              placeholder={t('artist-url')}
              label={t('artist-url')}
              validate={validateURL}
              onClickHelp={() => {
                showHelpWindow(
                  t('artist-url'),
                  t('release-page-helptext-artist-url')
                );
              }}
              component={NewInputField}
            />
            <Field
              name={`${artist}.urlLabel`}
              placeholder={t('artist-urlLabel')}
              label={t('artist-urlLabel')}
              validate={validateUrlLabel}
              component={NewInputField}
            />
          </div>
        ))}
      </FieldGroup>
    </>
  );
};

export type ArtistFormData = {
  artists: Array<ArtistValue>;
  enabled: boolean;
};

const ArtistForm: React.FC<{ artistIds: Array<number> }> = ({ artistIds }) => {
  const { t } = useTranslation();
  return (
    <FormSection name="artist">
      <FieldGroup>
        <Field
          name="enabled"
          label={t('enable-page')}
          component={EnabledField}
        />
      </FieldGroup>
      <FieldArray
        name="artists"
        artistIds={artistIds}
        component={renderArtists}
      />
    </FormSection>
  );
};

export default ArtistForm;
