import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextFormatted, Button, Window } from 'imdui';

const WINDOW_STYLE = { width: '100%', maxWidth: '512px' };

const Container = styled.div`
  width: 100%;
`;

const MessageContainer = styled.div`
  margin: 0px 32px 32px;
`;

const ActionWrapper = styled.div`
  display: flex;
  margin: 16px 24px;
  justify-content: center;
`;
const CancelButton = styled(Button)`
  justify-content: center;
  display: flex;
  margin: 0 8px 0 0;
  flex: 0.5;
  flex-grow: 1;
`;
const ConfirmButton = styled(Button)`
  justify-content: center;
  display: flex;
  flex: 0.5;
  flex-grow: 1;
`;

type ButtonProps = React.ComponentProps<typeof Button>;

type ContentProps = {
  onConfirm?: ButtonProps['onClick'];
  onCancel?: ButtonProps['onClick'];
  onRequestClose: (e?: React.MouseEvent<HTMLElement>) => void;
  cancelLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  confirmTestId?: string;
  confirmLabel?: string;
  buttonType?: ButtonProps['primary'];
  children?: React.ReactNode;
  actions?: React.ReactNode;
  message?: string;
};

export const ConfirmationContent = ({
  onConfirm,
  message,
  children,
  actions,
  onRequestClose,
  onCancel,
  cancelLabel,
  loading,
  disabled,
  confirmTestId,
  confirmLabel,
  buttonType = true,
}: ContentProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      {message && (
        <MessageContainer>
          <TextFormatted text={message} />
        </MessageContainer>
      )}
      {children}
      <ActionWrapper>
        {actions || (
          <>
            <CancelButton
              onClick={onCancel || onRequestClose}
              text={cancelLabel || t('cancel')}
            />
            {onConfirm && (
              <ConfirmButton
                testId={confirmTestId}
                primary={buttonType}
                showLoading={loading}
                disabled={disabled}
                onClick={onConfirm}
                text={confirmLabel || t('confirm')}
              />
            )}
          </>
        )}
      </ActionWrapper>
    </Container>
  );
};

export default function ConfirmationWindow({
  title,
  isOpen,
  onRequestClose,
  lockClickOutside,
  ...props
}: ContentProps & {
  title: string;
  lockClickOutside?: boolean;
  isOpen: boolean;
}) {
  return (
    <Window
      lockClickOutside={lockClickOutside}
      style={WINDOW_STYLE}
      isOpen={isOpen}
      title={title}
      close={onRequestClose}
    >
      <ConfirmationContent {...props} onRequestClose={onRequestClose} />
    </Window>
  );
}
