import { css } from '@emotion/react';

export const darkTheme = css`
  --fg-1: var(--white-95);
  --fg-2: var(--white-65);
  --fg-3: var(--white-35);
  --fg-4: var(--white-10);
  --fg-inverted: var(--black-95);

  --bg-1: var(--gray-33);
  --bg-2: var(--gray-20);
  --bg-3: var(--gray-15);
  --bg-4: var(--gray-10);
  --bg-inverted: var(--gray-100);
`;

export const baseTheme = css`
  --black-95: rgba(0, 0, 0, 0.95);
  --black-65: rgba(0, 0, 0, 0.65);
  --black-35: rgba(0, 0, 0, 0.35);
  --black-10: rgba(0, 0, 0, 0.1);

  --white-95: rgba(255, 255, 255, 0.95);
  --white-65: rgba(255, 255, 255, 0.65);
  --white-35: rgba(255, 255, 255, 0.35);
  --white-10: rgba(255, 255, 255, 0.1);
  --artist-hub: #b151c9;
  --music-distribution: #16ad40;
  --instant-mastering: #ffa800;
  --amps-pink: #ff00b5;

  --sub-amplify: #3cbeb1;
  --sub-amplify-plus: #926ef7;
  --sub-amplify-pro: #e4497d;

  --gray-100: #ffffff;
  --gray-95: #fafafa;
  --gray-90: #f4f4f4;
  --gray-85: #eeeeee;
  --gray-33: #333333;
  --gray-20: #272727;
  --gray-15: #212121;
  --gray-10: #1a1a1a;

  --accent-green: #82bd32;
  --accent-orange: #fb9013;

  --state-positive: #00c82c;
  --state-negative: #ff0000;

  --fg-1: var(--black-95);
  --fg-2: var(--black-65);
  --fg-3: var(--black-35);
  --fg-4: var(--black-10);
  --fg-inverted: var(--white-95);

  --bg-1: var(--gray-100);
  --bg-2: var(--gray-95);
  --bg-3: var(--gray-90);
  --bg-4: var(--gray-85);
  --bg-inverted: var(--gray-10);
`;
