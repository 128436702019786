import React, { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { ReleasePagePageId } from 'components';
import {
  useProductPage,
  useProductId,
  ReleasePageTemplateRenderer,
  useReleasePageFormData,
  useReleasePageData,
  ReleasePageOptions,
  ReleasePageOptionsTemplateContext,
} from 'components';
import styled from '@emotion/styled';
import { AlertBox, Card } from 'imdui';
import { useSubscriptionUpsell } from 'imdshared';

export const ReleasePageShowcase: React.FC<{
  type?: 'premium' | 'free';
  releasePageId: string;
}> = ({ type, releasePageId }) => {
  const [tab, setTab] = useState<ReleasePagePageId>('release');

  const { loaded, pressFiles, release, releasePageShops, releasePage } =
    useReleasePageData(releasePageId);

  const mode = useContext(ReleasePageOptionsTemplateContext);

  const data = useReleasePageFormData({
    release,
    releasePage,
    releasePageShops,
    pressFiles,
    loaded,
  });

  if (!data) return null;

  return (
    <ReleasePageTemplateRenderer
      data={data}
      isBranded={type !== undefined ? false : mode === 'pro'}
      type={type !== undefined ? type : mode === 'base' ? 'free' : 'premium'}
      tab={tab}
      // @ts-ignore
      onSelectTab={setTab}
    />
  );
};

const CardStyled = styled(Card)`
  margin: 24px 0;
  padding: 24px 32px;
`;

export const ReleasePageOverview = ({
  editorUrl,
  legacyPremium,
  readyToActivate,
}: {
  readyToActivate: boolean;
  legacyPremium: boolean;
  editorUrl: string;
}) => {
  const history = useHistory();
  const id = useProductId();

  const { t } = useTranslation();

  const { verifyingSteps, validSteps } = useProductPage();
  const errorText =
    !validSteps || !readyToActivate
      ? t('fix-release-step-to-generate-preview')
      : '';
  const loadingText = verifyingSteps;
  const { open: openUpsell } = useSubscriptionUpsell();

  const handleUpsellOnReleasePage = useCallback(() => {
    openUpsell({
      section: 'promo-tools',
      analytics: { detail: 'release-page' },
    });
  }, []);
  const disabledTemplate = !!errorText || !!loadingText;
  return (
    <CardStyled>
      <>
        {errorText && (
          <AlertBox
            type={AlertBox.Type.error}
            title={errorText}
            style={{ marginBottom: '32px' }}
          />
        )}
        <ReleasePageOptions
          label={t('choose-release-page')}
          disabledTemplate={disabledTemplate}
          legacyPremium={legacyPremium}
          onSelect={handleUpsellOnReleasePage}
          onCustomize={
            !disabledTemplate
              ? () => {
                  history.push(editorUrl);
                }
              : undefined
          }
          template={<ReleasePageShowcase releasePageId={id} />}
        />
      </>
    </CardStyled>
  );
};
