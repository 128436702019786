//
import React from 'react';
import styled from '@emotion/styled';
import { StreamsCard } from '../../../Reporting/screens/EnhancedTrends/index';
import { ChartsGridCard } from '../../../Reporting/screens/EnhancedTrends/components/ChartsGridCard';

const DashboardStreamCard = styled(StreamsCard)`
  min-width: 0%;
  position: relative;
  grid-column: 1 / -1;
  @media (min-width: 1600px) {
    grid-column: span 2;
  }
  ${ChartsGridCard} {
    height: 320px;
    grid-template-rows: 48px calc(100% - 48px);
  }
`;

export default function TrendCard() {
  // Todo check for premium
  return <DashboardStreamCard verticalTicksNum={4} isPremium={false} />;
}
