import styled from '@emotion/styled';
import { Card, Text } from '@imus/services-ui';

export const BioCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  ${Text.ContentM},
  ${Text.BodyS} {
    word-break: break-all;
  }
`;
