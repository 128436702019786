import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from '../../../text';
import { theme } from '../../../theme';
import { LoadingIndicator } from '../../base';
import { ComponentIcons } from '../../icon';
import { Wrapper, NumberWrapper, StepDivider } from './styles';

interface Props {
  to?: string;
  testId?: string;
  number?: number;
  text: string;
  divider?: boolean;
  active?: boolean;
  disabled?: boolean;
  visited?: boolean;
  invalid?: boolean;
  verifying?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Step = ({
  to,
  divider,
  disabled = false,
  text,
  active = false,
  visited = false,
  invalid = false,
  verifying,
  testId,
  onClick,
}: Props): JSX.Element => {
  let StepIndicator = null;
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (to) {
        history.push(to);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [to, history, onClick]
  );

  if (visited) {
    StepIndicator = null;
  } else {
    StepIndicator = invalid ? (
      <ComponentIcons.Error fill={theme.state.error} />
    ) : null;
  }

  if (verifying) {
    StepIndicator = <LoadingIndicator color="#666" />;
  }

  return (
    <>
      {divider && <StepDivider />}

      <Wrapper
        key="step"
        className={disabled ? 'disabled' : ''}
        number={!!StepIndicator}
        testId={testId}
        active={active}
        invalid={invalid}
        onClick={!disabled ? handleClick : undefined}
      >
        {StepIndicator && <NumberWrapper>{StepIndicator}</NumberWrapper>}

        <Content>{text}</Content>
      </Wrapper>
    </>
  );
};

export default Step;
