import isURL from 'validator/lib/isURL';

export const validateRequired = (value?: string) => (!value ? 'required' : '');

export const validateURL = (value?: string) => {
  if (!value) return false;
  return isURL(value, {
    protocols: ['https', 'http'],
    require_protocol: true,
    require_valid_protocol: true,
  })
    ? ''
    : 'invalid-url';
};

const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie|education)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
const MATCH_URL_SOUNDCLOUD = /(?:soundcloud\.com|snd\.sc)\/[^.]+$/;
const MATCH_URL_VIMEO = /vimeo\.com\/(?!progressive_redirect).+/;
const MATCH_URL_FACEBOOK =
  /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
const MATCH_URL_FACEBOOK_WATCH = /^https?:\/\/fb\.watch\/.+$/;
const MATCH_URL_STREAMABLE = /streamable\.com\/([a-z0-9]+)$/;
const MATCH_URL_WISTIA =
  /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/;
const MATCH_URL_TWITCH_VIDEO = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const MATCH_URL_TWITCH_CHANNEL =
  /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/;
const MATCH_URL_DAILYMOTION =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?(?:[\w.#_-]+)?/;
const MATCH_URL_MIXCLOUD = /mixcloud\.com\/([^/]+\/[^/]+)/;
const MATCH_URL_VIDYARD = /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-_]+)/;
// const MATCH_URL_KALTURA =
//   /^https?:\/\/[a-zA-Z]+\.kaltura.(com|org)\/p\/([0-9]+)\/sp\/([0-9]+)00\/embedIframeJs\/uiconf_id\/([0-9]+)\/partner_id\/([0-9]+)(.*)entry_id.([a-zA-Z0-9-_].*)$/;

export const canPlayUrl = (url: string) =>
  [
    MATCH_URL_YOUTUBE,
    MATCH_URL_VIMEO,
    MATCH_URL_SOUNDCLOUD,
    MATCH_URL_FACEBOOK,
    MATCH_URL_FACEBOOK_WATCH,
    MATCH_URL_STREAMABLE,
    MATCH_URL_WISTIA,
    MATCH_URL_TWITCH_CHANNEL,
    MATCH_URL_TWITCH_VIDEO,
    MATCH_URL_DAILYMOTION,
    MATCH_URL_MIXCLOUD,
    MATCH_URL_VIDYARD,
  ].find((mask) => mask.test(url));
