import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { camelize } from 'humps';
import { Body, Content, IconButton, IconsCollection } from 'imdui';
import RevertButton from '../RevertButton';
import type { Change } from '../../types';

type Props = {
  change: Change;
};

const ChangeRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
`;

const PlainField: React.FC<Props> = ({ change }) => {
  const { t } = useTranslation();

  return (
    <>
      {change.attributes.map(
        ({ field, old: oldValue, new: newValue, canRevert }) => {
          return (
            <RevertButton
              key={field}
              changeId={change.id}
              attribute={field}
              // TODO: fix this to hook useRevert
              // eslint-disable-next-line
              customRenderer={({
                onClickRevert,
                deleting,
                deleted,
              }): JSX.Element => (
                <ChangeRow
                  key={field}
                  className={deleted ? 'disabled' : undefined}
                >
                  <Body>
                    {t(camelize(field))} <Content>{oldValue}</Content>{' '}
                    {t('changed-to')} <Content>{newValue}</Content>
                  </Body>

                  {canRevert && !deleted && (
                    <StyledIconButton
                      newIcon={IconsCollection.remove}
                      showLoading={deleting}
                      onClick={onClickRevert}
                    />
                  )}
                </ChangeRow>
              )}
            />
          );
        }
      )}
    </>
  );
};

export default PlainField;
