// TODO: Deprecate this API only used for datepicker & button dropdown
// @ts-nocheck
import React, { Component } from 'react';
import { Dropdown, NewInput } from 'imdui';
import styled from '@emotion/styled';
import Menu from '../Menu';

const DropdownContainer = styled.div`
  position: relative;
  display: flex;

  & > * {
    flex: 1;
  }
`;

type Props = {
  value?: number | string;
  label?: string;
  data?: {
    keys: string[];
    entries: any[];
  };
  options?: Array<Node>;
  disabled?: boolean;
  floatingLabelText: string;
  placeholder?: string;
  errorText?: string;
  helperText?: string;
  warningText?: string;
  acceptValidation?: boolean;
  onClickHelperButton?: () => void;
  renderDropdown: React.FC<any>;
  onChange?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  render?: React.FC<any>;
  className?: string;
  style?: React.CSSProperties;
};

type State = {
  dropdownOpened?: boolean;
};

class DropdownInteractive extends Component<Props, State> {
  static defaultProps = {
    renderDropdown: Menu,
    showUnderline: true,
    floatingLabelText: null,
    placeholder: null,
    errorText: null,
    onBlur: (): void => undefined,
    acceptValidation: true,
  };

  inputAnchor = React.createRef<HTMLElement>();

  fallbackAnchor = React.createRef<HTMLElement>();

  state = {
    dropdownOpened: false,
  };

  // For next two return undefined because otherwise redux-form will
  // receive the display value if present
  handleFocus = (e: React.FocusEvent<HTMLElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    const { disabled, onFocus } = this.props;
    if (disabled) {
      return;
    }

    if (onFocus) {
      onFocus(e);
    }

    this.setState(() => ({
      dropdownOpened: true,
    }));
  };

  handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { disabled } = this.props;
    if (disabled) return;

    this.setState(({ dropdownOpened }) => ({
      dropdownOpened: !dropdownOpened,
    }));
  };

  collapse = (event) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
    this.setState({ dropdownOpened: false });
  };

  onChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value, {
        collapse: this.collapse,
        expand: this.handleFocus,
      });
    }
    this.collapse();
  };

  handleKeydDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.keyCode === 9) {
      this.collapse();
    }
    if (e.keyCode === 8) {
      this.onChange(null);
    }
  };

  defaultRender = ({ onClick, ...props }) => {
    const { displayValue, components, onClickHelperButton } = this.props;

    return (
      <NewInput
        {...props}
        components={components}
        inputRef={this.inputAnchor}
        value={displayValue}
        onClickHelp={onClickHelperButton}
        onClickHelperButton={undefined}
      />
    );
  };

  render() {
    const {
      required,
      value,
      label,
      data,
      renderDropdown,
      render = this.defaultRender,
      disabled,
      helperText,
      onClickHelperButton,
      floatingLabelText,
      placeholder,
      errorText,
      warningText,
      acceptValidation,
      className,
      style,
      testId,
      dropdownProps,
      displayValue,
    } = this.props;

    const { dropdownOpened } = this.state;

    return (
      <>
        <DropdownContainer
          ref={this.fallbackAnchor}
          className={className}
          style={style}
          data-test-id={`DropdownInteractive-${testId}`}
          onClick={this.handleFocus}
        >
          {render({
            disabled,
            dropdownOpened,
            testId,
            value,
            label,
            displayValue,
            required,
            floatingLabelText,
            acceptValidation,
            placeholder,
            onClick: this.handleClick,
            onFocus: this.handleFocus,
            onKeyDown: this.handleKeydDown,
            helperText,
            warningText,
            errorText,
            onClickHelperButton,
          })}
        </DropdownContainer>

        {dropdownOpened && (
          <Dropdown
            anchorEl={
              this.inputAnchor.current ? this.inputAnchor : this.fallbackAnchor
            }
            maxWidthFix={true}
            onClickOutside={this.collapse}
          >
            {({ width }) =>
              renderDropdown({
                value,
                data,
                onBlur: this.collapse,
                onChange: this.onChange,
                style: { width },
                ...dropdownProps,
              })
            }
          </Dropdown>
        )}
      </>
    );
  }
}

export default DropdownInteractive;
