import styled from '@emotion/styled';

export { AutosizeGrid } from './AutosizeGrid';
export { ArtistAvatar, ArtistAvatarPlaceholder } from './ArtistAvatar';
export * from './icons';
export * from './theme';

export const Separtor = styled.hr`
  background: var(--fg-4);
  border: none;
  height: 1px;
`;

export const BaseGrid = styled.div`
  display: grid;
  gap: 24px;
`;
