import styled from '@emotion/styled';

export const cardBoxShadow =
  '0px 2px 6px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)';

const Card = styled.div<{ secondary?: boolean }>`
  position: relative;
  box-shadow: ${cardBoxShadow};
  overflow: hidden;
  box-sizing: border-box;

  border-radius: 20px;
  background: ${({ secondary }) =>
    secondary ? 'var(--bg-3)' : 'var(--bg-2, #fafafa)'};

  /* Shadow/Card */
  box-shadow:
    0px 2px 6px -2px rgba(0, 0, 0, 0.05),
    inset 0 0 0 1px var(--fg-4);
`;

export default Card;
