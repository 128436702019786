import React, { useContext, useMemo } from 'react';
import { AppTitle, Body } from 'imdui';
import styled from '@emotion/styled';
import { useCurrentLocale, ButtonDropdown } from 'imdshared';
import { ApplicationSettingsContext } from 'components';

const HeaderStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: minmax(min-content, max-content);
  padding: 24px 24px 0 24px;
  ${AppTitle} {
    display: inline-flex;
    align-items: center;
    line-height: 24px;
    margin-right: 24px;
  }
`;

const ColumnFirst = styled.div`
  height: 24px;
  display: inline-flex;
`;

const ColumnSecond = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HeaderCaption = styled(Body)`
  color: ${({ theme }) => theme.darks[4]};
  line-height: 24px;
`;

const ShopSelector = styled(ButtonDropdown)`
  height: 40px;
  position: relative;
  top: -10px;
  right: -16px;
`;

const TabsWrapper = styled.div`
  display: inline-flex;
  position: relative;
  top: -8px;
`;

const LegendWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
`;

const TrendUp = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6 7.53333L6.6 14.2C6.35279 14.5296 6.58798 15 7 15H17C17.412 15 17.6472 14.5296 17.4 14.2L12.4 7.53333C12.2 7.26667 11.8 7.26667 11.6 7.53333Z"
      fill="#00C82C"
    />
  </svg>
);

const TrendDown = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6 16.4667L6.6 9.8C6.35279 9.47038 6.58798 9 7 9H17C17.412 9 17.6472 9.47038 17.4 9.8L12.4 16.4667C12.2 16.7333 11.8 16.7333 11.6 16.4667Z"
      fill="#FF0000"
    />
  </svg>
);

const ChangeRatio = styled(AppTitle)<{ value: number }>`
  display: inline-flex;
  line-height: 24px;
  margin-right: 8px;
  color: ${({ value }) =>
    value > 0 ? '#00C82C' : value < 0 ? '#FF0000' : '#333'};
`;

export type Props = {
  title: React.ReactNode;
  caption?: React.ReactNode;
  changeRatio?: number | null;
  tabs?: React.ReactNode;
  legend?: React.ReactNode;
  selectedShop?: string;
  shops?: Array<{ value: string | undefined; label: string }> | undefined;
  onChangeShop?: (v: string) => void;
};

export function ChartHeader({
  title,
  caption,
  tabs,
  shops,
  selectedShop,
  onChangeShop,
  changeRatio,
  legend,
}: Props) {
  const locale = useCurrentLocale();
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(numberFormatLocale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
    [locale]
  );
  return (
    <HeaderStyled>
      <ColumnFirst>
        <AppTitle>{title}</AppTitle>
        {changeRatio ? (
          <ChangeRatio value={changeRatio}>
            {changeRatio > 0 ? TrendUp : changeRatio < 0 ? TrendDown : null}
            {formatter.format(changeRatio)}
          </ChangeRatio>
        ) : null}
        {tabs ? <TabsWrapper>{tabs}</TabsWrapper> : null}
        {caption ? <HeaderCaption>{caption}</HeaderCaption> : null}
      </ColumnFirst>
      <ColumnSecond>
        {legend ? <LegendWrapper>{legend}</LegendWrapper> : null}
        {!!shops && (
          // @ts-ignore
          <ShopSelector
            onChange={onChangeShop}
            value={selectedShop}
            data={shops}
            backgroundColor="transparent"
          />
        )}
      </ColumnSecond>
    </HeaderStyled>
  );
}
