import { css } from '@emotion/react';

export const quoteStyle = css`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const quoteText = css`
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;

  hanging-punctuation: first last;
  font-feature-settings: 'cv11' on;

  color: var(--fg-1);
`;
