import { css } from '@emotion/react';

export const artistSectionStyle = css`
  display: grid;
  gap: 24px;
`;

export const artistDescriptionStyle = css`
  color: var(--fg-2);
`;
