// @flow
import React, { useState } from 'react';
import {
  ArtistHubIcon,
  ChevronRightIcon,
  InstantMasterinProductIcon,
  MusicDistributionProductIcon,
} from '@imus/base-ui';
import PromoSvg from './promo.svg';
import ReferralSvg from './referral.svg';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, CenterWrapper, useSubscriptionUpsell } from 'imdshared';
import config from 'imdconfig';
import { useCurrentLocale, DashboardCard, SubscriptionName } from 'components';
import { Feature } from 'imdfeature';
// eslint-disable-next-line
import bg from '!@svgr/webpack!url-loader!./bg.svg';
import {
  H1,
  LinkButton,
  TextFormatted,
  Card,
  H3,
  Clickable,
  BodySmall,
  H4,
  ButtonText,
} from 'imdui';
import { Window } from '@imus/services-ui';
import { useQuery, useEntries, useCurrentCustomerSubscription } from 'imddata';
import {
  ReferralCard,
  DistributionInProgressCard,
  BalanceCard,
  TrendCard,
} from './components';
import { useHistory } from 'react-router';
import { useSubsPromo } from 'screens/App/PromoBanner';
import { BodyL, BodyS } from '@imus/services-ui/src/Text';

const Wrapper = styled.div``;

const Spacer = styled.div`
  grid-column: 1 / -1;
  padding: 32px 12px 0 0;

  @media (min-width: 600px) {
    padding: 48px 24px 0px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  ${H1} {
    margin: 0;
  }
`;

const SubscriptionCardRow = styled(Grid)`
  grid-column: 1 / -1;
`;

const DashboardGrid = styled(Grid)`
  grid-auto-rows: fit-content(480px);
`;

const AnnouncementCard = styled(DashboardCard)``;

const AnnouncementLink = styled.a`
  margin-top: auto;
  display: flex;
  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;
  padding-top: 16px;

  color: #82bd32;
`;

const AnnouncementImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  max-width: 100%;
  margin: 0 0 16px 0;
`;

type NewsExceprt = {
  title: string;
  id: string;
  image?: { filenameDisk: string };
  type: string;
  url: string;
  buttonText: string;
  body: string;
};

const AnnouncementText = styled(TextFormatted)<{ truncate: boolean }>`
  text-overflow: ellipsis;

  ${({ truncate }) =>
    truncate
      ? `
  max-height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

`
      : ''}
`;

const ProductCard = styled(Card)`
  padding: 24px;
  display: grid;
  gap: 16px;
`;

const ProductButton = styled(LinkButton)`
  background: #ffffff;

  border: 1px solid;

  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 30px;
  margin-top: auto;
`;

const ProductCardContent = styled.div`
  display: flex;
  gap: 16px;
  div {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: 4px;
  }
  svg {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  }
`;

const ClickableSubscription = styled(Clickable)`
  border-radius: 12px;
`;

const SubscriptionCard = styled(Card)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: initial;
  text-align: left;
  padding: 24px;
  gap: 4px;
  & > div {
    align-items: center;
    flex: 1;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
  }
`;

const ReferralWindowContent = styled.div`
  ${H1} {
    margin-bottom: 16px;
  }
  background: var(--bg-1);
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

const ReferralViewButton = styled(Clickable)`
  display: flex;
  height: 48px;
  max-width: 256px;
  width: 100%;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 14px;
  border: 1px solid #ff6300;

  border: 1px solid color(display-p3 0.9961 0.4353 0.1294);

  background: var(--surface-bg-1, #fff);

  background: var(--surface-bg-1, color(display-p3 1 1 1));
  /* Shadow/Tab Item */
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);

  box-shadow: 0px 1px 3px -1px color(display-p3 0 0 0 / 0.1);
`;

const PromoImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  background: url(${bg as any});
  background-size: cover;
`;

const PromoSvgStyled = styled(PromoSvg)`
  display: block;
`;

const ActiveDashboard: React.FC = () => {
  const { t } = useTranslation();

  const locale = useCurrentLocale();
  const { query: newsQuery, queryHash: newsQueryHash } = useQuery({
    query: { locale },
  });
  const { entries: news } = useEntries<NewsExceprt>({
    entity: 'newsExceprts',
    query: newsQuery,
    queryHash: newsQueryHash,
  });

  const { query: tipsQuery, queryHash: tipsQueryHash } = useQuery({
    query: { locale },
  });
  const { entries: tips } = useEntries<NewsExceprt & { articleId: string }>({
    entity: 'platformTips',
    query: tipsQuery,
    queryHash: tipsQueryHash,
  });

  const subscription = useCurrentCustomerSubscription();

  const { open: openUpsell } = useSubscriptionUpsell();
  const [showPromo, onClick] = useSubsPromo();
  const [openReferralWindow, setOpenReferralWindow] = useState(false);

  const isAlertNotificationValid =
    t('alert-dashboard-notification') !== '' &&
    t('alert-dashboard-notification') !== 'alert-dashboard-notification';

  const history = useHistory();
  return (
    <CenterWrapper>
      <Wrapper>
        <Helmet>
          <title>{t('page-title-dashboard')}</title>
        </Helmet>

        <DashboardGrid>
          <>
            <Spacer>
              <H1>{t('dashboard')}</H1>
            </Spacer>
            <Window
              isOpen={openReferralWindow}
              close={() => {
                setOpenReferralWindow(false);

                if (window && window.analytics) {
                  window.analytics.track('FT referral card closed modal');
                }
              }}
              style={{ maxWidth: '512px' }}
            >
              <ReferralSvg
                style={{
                  backgroundColor: 'color(display-p3 1 0.4353 0.1333)',
                  flex: 1,
                  width: '100%',
                  height: 'auto',
                }}
              />
              <ReferralWindowContent>
                <div>
                  <H1>{t('refer-and-earn')}</H1>
                  <BodyL>{t('refer-and-earn-text')}</BodyL>
                </div>
                <ReferralViewButton
                  onClick={() => {
                    if (window && window.analytics) {
                      window.analytics.track(
                        'FT referral card modal clicked to open referral dashboard'
                      );
                    }
                    history.push('/account/referral');
                  }}
                >
                  <ButtonText size="large">{t('start-referring')}</ButtonText>
                </ReferralViewButton>
              </ReferralWindowContent>
            </Window>

            <SubscriptionCardRow columns={3}>
              {showPromo && (
                <Card
                  style={{
                    maxHeight: '312px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ padding: '24px' }}>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <H4 style={{ flex: '1' }}>{t('promo-card-title')}</H4>
                      <Clickable onClick={onClick} style={{ padding: '0 4px' }}>
                        <ButtonText
                          size="small"
                          style={{ margin: '0 0 0 auto', color: '#FA5376' }}
                        >
                          {t('promo-card-button-text', {
                            defaultValue: 'view',
                          })}
                        </ButtonText>
                      </Clickable>
                    </div>
                    <BodySmall>{t('promo-card-subtitle')}</BodySmall>
                  </div>

                  <PromoImageContainer>
                    <PromoSvgStyled />
                  </PromoImageContainer>
                </Card>
              )}

              <Card
                style={{
                  maxHeight: '312px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ padding: '24px' }}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <H4 style={{ flex: '1' }}>{t('referral-card-title')}</H4>
                    <Clickable
                      onClick={() => {
                        if (window && window.analytics) {
                          window.analytics.track(
                            'FT referral card opened modal'
                          );
                        }
                        setOpenReferralWindow(true);
                      }}
                      style={{ padding: '0 4px' }}
                    >
                      <ButtonText
                        size="small"
                        style={{ margin: '0 0 0 auto', color: '#FA5376' }}
                      >
                        {t('referral-card-button-text', {
                          defaultValue: 'view',
                        })}
                      </ButtonText>
                    </Clickable>
                  </div>
                  <BodySmall>{t('referral-card-subtitle')}</BodySmall>
                </div>

                <ReferralSvg
                  style={{
                    backgroundColor: 'color(display-p3 1 0.4353 0.1333)',
                    flex: 1,
                    width: 'auto',
                  }}
                />
              </Card>
            </SubscriptionCardRow>
            <SubscriptionCardRow columns={3}>
              <ProductCard>
                <ProductCardContent>
                  <MusicDistributionProductIcon />
                  <div>
                    <H4>{t('distribute-music')}</H4>
                    <BodySmall>
                      {t('music-distribution-onboarding-text')}
                    </BodySmall>
                  </div>
                </ProductCardContent>
                <ProductButton
                  onClick={() => {
                    if (window?.analytics) {
                      window.analytics.track('FT Product Dashboard Click', {
                        product: 'music-distribution',
                      });
                    }
                  }}
                  testId="start-distributing"
                  style={{ borderColor: '#1C9A3F' }}
                  to="/order/md/new"
                  size="small"
                  text={t('explore')}
                />
              </ProductCard>
              <ProductCard>
                <ProductCardContent>
                  <InstantMasterinProductIcon />
                  <div>
                    <H4>{t('instant-mastering')}</H4>
                    <BodySmall>
                      {t('instant-mastering-dashboard-text')}
                    </BodySmall>
                  </div>
                </ProductCardContent>
                <ProductButton
                  style={{ borderColor: '#FF9900' }}
                  to="/order/mastering/new"
                  onClick={() => {
                    if (window?.analytics) {
                      window.analytics.track('FT Product Dashboard Click', {
                        product: 'mastering',
                      });
                    }
                  }}
                  size="small"
                  text={t('master-my-track')}
                />
              </ProductCard>
              <ProductCard>
                <ProductCardContent>
                  <ArtistHubIcon />
                  <div>
                    <H4>{t('brand-and-release-promo')}</H4>
                    <BodySmall>{t('artist-hub-dashboard-text')}</BodySmall>
                  </div>
                </ProductCardContent>
                <ProductButton
                  style={{ borderColor: '#8E24F8' }}
                  to="/products/artist-hub"
                  onClick={() => {
                    if (window?.analytics) {
                      window.analytics.track('FT Product Dashboard Click', {
                        product: 'release-page',
                      });
                    }
                  }}
                  size="small"
                  text={t('explore-artist-hub')}
                />
              </ProductCard>
            </SubscriptionCardRow>
          </>

          <SubscriptionCardRow>
            <ClickableSubscription
              style={{ borderRadius: '20px' }}
              onClick={() => {
                if (subscription) {
                  history.push('/account');
                  return;
                }
                openUpsell();
              }}
            >
              <SubscriptionCard>
                <div>
                  <div>
                    <SubscriptionName
                      style={{ display: 'block', marginBottom: '4px' }}
                      subscriptionId={subscription?.subscriptionId || 'free'}
                    >
                      {t(subscription?.subscriptionId || 'free', {
                        context: 'plan',
                      })}
                    </SubscriptionName>
                    <BodyS>
                      {subscription ? t('current-plan') : t('upgrade')}
                    </BodyS>
                  </div>
                  <ChevronRightIcon />
                </div>
              </SubscriptionCard>
            </ClickableSubscription>

            <Feature feature="referral-system">
              {(isEnabled) => (isEnabled ? <ReferralCard /> : null)}
            </Feature>
            <BalanceCard />
          </SubscriptionCardRow>

          <DistributionInProgressCard />
          <TrendCard />
          <SubscriptionCardRow columns={4} id="news">
            {news.map((n) => (
              <AnnouncementCard key={n.id}>
                {n.image ? (
                  <AnnouncementImage
                    src={`${config.contentUploads}/${n.image.filenameDisk}`}
                    alt={n.title}
                  />
                ) : (
                  <div style={{ padding: '16px 0 0 0' }} />
                )}
                <H3 style={{ marginBottom: '8px' }}>{n.title}</H3>
                <AnnouncementText truncate={!!n.image} text={n.body} />
                {n.url ? (
                  <AnnouncementLink target="_blank" href={n.url}>
                    {n.buttonText}
                  </AnnouncementLink>
                ) : null}
              </AnnouncementCard>
            ))}
            {isAlertNotificationValid ? (
              <AnnouncementCard label={t('alert-dashboard-notification-title')}>
                <TextFormatted text={t('alert-dashboard-notification')} />
              </AnnouncementCard>
            ) : null}
          </SubscriptionCardRow>
        </DashboardGrid>
        <DashboardGrid columns={3}>
          <Spacer>
            <H1>{t('tips')}</H1>
          </Spacer>
          {tips.map((tip, idx) => {
            return (
              <AnnouncementCard key={`tip${idx}`}>
                <H3 style={{ marginBottom: '12px', padding: '12px 0 0' }}>
                  {tip.title}
                </H3>
                <AnnouncementText truncate={false} text={tip.body} />

                {tip.articleId ? (
                  <AnnouncementLink
                    data-beacon-article-modal={tip.articleId}
                    href="#"
                  >
                    {tip.buttonText}
                  </AnnouncementLink>
                ) : null}
              </AnnouncementCard>
            );
          })}
          <Spacer />
        </DashboardGrid>
      </Wrapper>
    </CenterWrapper>
  );
};

export default ActiveDashboard;
