import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { InfiniteList, BlockTemplate } from 'components';
import type { EntityModels } from 'imddata';
import { useEntityActions, useEntries } from 'imddata';
import { ButtonDropdown, NavListItem as AccountNavListItem } from 'imdshared';
import { IconButton, Icons } from 'imdui';
import moment from 'moment';

function PayoutAddressListItem({
  item: { id, wasConfirmed, label, createdAt },
  style,
  key,
}: {
  item: EntityModels.PayoutAddress;
  style: React.CSSProperties;
  key: string;
}) {
  const { remove } = useEntityActions('payoutAddresses');
  const { t } = useTranslation();

  const handleRemove = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      remove({ id });
    },
    [id]
  );

  return (
    <AccountNavListItem
      key={key}
      // @ts-ignore
      to={`/revenue/billing/${id}`}
      style={style}
      title={`${t('payout-account')} ${id} ${
        !wasConfirmed ? `(${t('this-address-is-not-confirmed')})` : ''
      }`}
      subtitle={label}
      createdAt={moment(createdAt).format('lll')}
    >
      <IconButton onClick={handleRemove} icon={Icons.actions.remove} />
    </AccountNavListItem>
  );
}

const getRowHeight = () => 80;

export default function PayoutAddressList({ match }: RouteComponentProps) {
  const { t } = useTranslation();
  const {
    request: { loading, hasMorePages },
    entries,
    loadMore,
  } = useEntries<EntityModels.PayoutAddress>({ entity: 'payoutAddresses' });
  const history = useHistory();

  return (
    <BlockTemplate
      padded={false}
      title={t('payout-addresses')}
      action={
        <ButtonDropdown
          testId="AddPayoutAddressButton"
          // @ts-ignore
          style={{ marginLeft: 'auto' }}
          onChange={(value: string) => {
            history.push(`${match.url}/${value}`);
          }}
          data={[
            { value: 'new-bank', label: t('bank') },
            { value: 'new-paypal', label: t('paypal') },
          ]}
          label={t('add-payout-address')}
          icon={Icons.actions.add}
        />
      }
    >
      <InfiniteList
        data={entries}
        hasNextPage={hasMorePages}
        loadNextPage={loadMore}
        loading={loading}
        rowRenderer={PayoutAddressListItem}
        emptyPlaceholder={t('no-payout-addresses')}
        getRowHeight={getRowHeight}
      />
    </BlockTemplate>
  );
}
