import styled from '@emotion/styled';
import React from 'react';
import { BodySmall, Caption, Content } from '../../text';
import { Button, Clickable } from '../button';
import { Lock } from '../icon/ComponentIcons';

type Props = {
  className?: string;
  testId?: string;
  style?: React.CSSProperties;
  features?: string[];
  action?: string;
  onActionClick: () => void;
  title: React.ReactNode;
};

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  ${Content} {
    margin: 0 8px;
  }
  & > div > div {
    align-items: center;
    display: flex;
  }
`;

const SubscriptionFeatureGateBase = ({
  className,
  title,
  features,
  testId,
  style,
  action,
  onActionClick,
}: Props) => {
  return (
    <div className={className} style={style}>
      <Header>
        <div>
          <div style={{ flex: '300px', margin: '0 auto' }}>
            <Lock />
            <Content>{title}</Content>
          </div>

          {features &&
            features
              .map<React.ReactNode>((f) => <Caption key={f}>{f}</Caption>)
              .reduce((acc, f) => [acc, ' • ', f])}
        </div>
        {action && onActionClick && (
          <Button
            style={{ flexShrink: '0' }}
            primary="amp"
            testId={testId}
            onClick={onActionClick}
            text={action}
          />
        )}
      </Header>
    </div>
  );
};

const ButtonTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  gap: 8px;
  flex-wrap: wrap;
`;

const Action = styled.span`
  font-family: 'Space Grotesk', 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  text-transform: uppercase;

  /* Accent/2 */

  color: #fb9013;
`;

const SubscriptionFeatureGateButtonBase = ({
  label,
  action,
  sublabel,
  onClick,
  className,
  style,
}: {
  label: string;
  className?: string;
  style?: React.CSSProperties;
  sublabel?: string;
  action: string;
  onClick: React.ComponentProps<typeof Clickable>['onClick'];
}) => {
  return (
    <Clickable className={className} style={style} onClick={onClick}>
      <ButtonTitle>
        <Content>{label}</Content>
        <Action>{action}</Action>
      </ButtonTitle>
      {sublabel && <BodySmall>{sublabel}</BodySmall>}
    </Clickable>
  );
};

export const SubscriptionFeatureGateButton = styled(
  SubscriptionFeatureGateButtonBase
)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 4px;
  background: #ffffff;
  box-shadow:
    0px 2px 6px -2px rgba(0, 0, 0, 0.05),
    inset 0px 0px 0px 1px var(--amps-pink, #ff00b5);
  border-radius: 8px;
  ${BodySmall} {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const SubscriptionFeatureGate = styled(SubscriptionFeatureGateBase)`
  border-radius: 12px;
  padding: 16px;
  border: 1px solid var(--fg-4, #ff00b5);
  background: var(--bg-1, #fff);
  ${Caption} {
    color: var(--foreground-fg-2, rgba(0, 0, 0, 0.65));
  }

  padding: 12px 16px;
`;
