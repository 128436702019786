import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  background: var(--background-component-stepper, rgba(0, 0, 0, 0.05));
  border-radius: 12px;
  gap: 16px;
  flex-wrap: wrap;
  padding: 8px;
  width: fit-content;

  @media screen and (min-width: 1200px) {
    gap: 24px;
  }
`;

export default Wrapper;
