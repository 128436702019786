import { css } from '@emotion/react';
import React from 'react';
import { BodySmall } from '../../text';
import { Button } from '../button';

type Props = {
  text: string;
  onClickAction: (e: React.MouseEvent) => void;
  actionButtonText: string;
  className?: string;
  style?: React.CSSProperties;
};

const wrapperStyle = css`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  max-width: 472px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.08);
`;

const textStyle = css`
  flex: 1;
  margin-right: 8px;
`;

const buttonStyle = css`
  height: 32px;
  min-height: 32px;
  span {
    font-size: 14px;
    margin: 0 12px;
  }
`;

const DescriptionCloud: React.FC<Props> = ({
  text,
  onClickAction,
  actionButtonText,
  className,
  style,
}) => (
  <div css={wrapperStyle} className={className} style={style}>
    <BodySmall css={textStyle}>{text}</BodySmall>

    {onClickAction && (
      <Button
        text={actionButtonText}
        onClick={onClickAction}
        css={buttonStyle}
      />
    )}
  </div>
);

export default DescriptionCloud;
