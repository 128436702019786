import { WysiwygTemplateContainer } from 'components/atoms';
import { getFormValues } from 'redux-form';
import type { TemplateData } from '@imus/artist-page-template';
import {
  BioGrid,
  ReleaseGrid,
  PressItems,
  TourInfo,
  Layout,
} from '@imus/artist-page-template';
import { useSelector } from 'react-redux';
import type { FormValues } from '../types';
import { useEffect, useMemo, useRef } from 'react';
import { Centered, LoadingIndicator } from 'imdui';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useCustomerArtistPageLimits } from './useCustomerAritstPageLimits';
import type { ReduxState } from 'imddata';
import { useReleaseCovers, useTrack } from 'imddata';

type LayoutProps = React.ComponentProps<typeof Layout>;

type PageId = LayoutProps['page'];

type Props = {
  form: string;
  page: PageId;
  onNavigation: LayoutProps['onNavigation'];
  onReleaseClick: React.ComponentProps<typeof ReleaseGrid>['onSelect'];
};

const getActivePage = (activePage: PageId, data?: TemplateData): PageId => {
  switch (activePage) {
    case 'press': {
      if (data?.press.enabled) {
        return 'press';
      }
      return 'about';
    }
    case 'tour': {
      if (data?.tour.enabled) {
        return 'tour';
      }
      return 'about';
    }
    case 'releases': {
      if (data?.releases?.length) {
        return 'releases';
      }
      return 'about';
    }
    default:
      return 'about';
  }
};

const ArtistLayoutConnected = ({
  form,
  onNavigation,
  onReleaseClick,
  page,
}: Props) => {
  const selector = useMemo<(state: ReduxState) => FormValues>(
    // @ts-ignore
    () => getFormValues(form),
    [form]
  );

  const artistPageLimits = useCustomerArtistPageLimits();
  const formData = useSelector<ReduxState, FormValues | undefined>(selector);
  const { i18n } = useTranslation();
  const trackMediaCard = formData?.media.find((m) => m.type === 'track');
  const trackId =
    trackMediaCard?.type === 'track' ? trackMediaCard.data.trackId : undefined;

  const { entry: track } = useTrack({ id: Number(trackId), passive: !trackId });
  const trackRelease = track?.releases.find(
    (tr) => tr.release?.states.releasePages?.isActive
  );
  const { entry: release } = useReleaseCovers({
    id: trackRelease?.releaseId,
    passive: !trackRelease,
  });
  const templateI18nRef = useRef(i18n.cloneInstance());
  const data = useMemo(
    () =>
      formData
        ? {
            ...formData,
            branded: artistPageLimits.branded,
            releases: formData.releases.filter((r) => r.enabled),
            media: formData.media
              .map((m) => {
                if (m.type === 'image-gallery') {
                  return { type: m.type, data: m.data.gallery };
                }
                if (m.type === 'track' && track) {
                  return {
                    type: m.type,
                    data: {
                      file: {
                        access: '',
                        filename: '',
                        extension: '',
                        url: track.audioPreview?.file.downloadUrl || '',
                        audio: {},
                      },
                      release: trackRelease
                        ? {
                            title:
                              track.releases[0]?.release?.defaultName?.title ||
                              '',
                            pageKey: '',
                            releasePageId: '',
                            releaseAt: '',
                            cover: {
                              access: '',
                              filename: '',
                              extension: '',
                              url: release?.availableCovers.small,
                            },
                            releaseTypeId:
                              release && release.tracksCount > 0
                                ? release.releaseTypeId
                                : null,
                          }
                        : undefined,
                      title: track.title || '',
                      audio: {
                        id: m.data.trackId,
                      },
                    },
                  };
                }
                return m;
              })
              .filter((m) => {
                if (!artistPageLimits.about.allowedMediaTypes) return true;
                return (
                  artistPageLimits.about.allowedMediaTypes.indexOf(m.type) >= 0
                );
              }),
            tour: {
              ...formData.tour,
              splash: formData.tour.splash?.enabled
                ? formData.tour.splash
                : undefined,
              enabled: artistPageLimits.tour.enabled && formData.tour.enabled,
            },
            press: {
              ...formData.press,
              enabled: artistPageLimits.press.enabled && formData.press.enabled,
            },
          }
        : undefined,
    [
      formData,
      artistPageLimits,
      !!track?.audioPreview?.file.downloadUrl,
      !!release?.availableCovers.small,
    ]
  );
  const activePage = getActivePage(page, data);
  // const [selectedReleasePageDataUrl, setSelectedReleasePageDataUrl] =
  //   useState('');
  // const [releasePageData, setReleasePageData] = useState<TemplateData | null>(
  //   null
  // );
  // useEffect(() => {
  //   if (selectedReleasePageDataUrl) {
  //
  //   }
  // },[selectedReleasePageDataUrl])
  useEffect(() => {
    if (data?.locale) {
      templateI18nRef.current.changeLanguage(data?.locale);
      templateI18nRef.current.loadNamespaces('artist-hub');
      templateI18nRef.current.setDefaultNamespace('artist-hub');
    }
  }, [data?.locale]);

  if (!data)
    return (
      <Centered>
        <LoadingIndicator size="large" />
      </Centered>
    );

  return (
    <I18nextProvider i18n={templateI18nRef.current}>
      <Layout data={data} page={activePage} onNavigation={onNavigation}>
        {activePage === 'about' && <BioGrid data={data} />}
        {activePage === 'releases' && (
          <ReleaseGrid
            releases={data.releases.filter((r) => r.enabled)}
            onSelect={onReleaseClick}
          />
        )}
        {activePage === 'press' && (
          <PressItems
            contacts={data.press.contacts}
            quotes={data.press.quotes}
            files={data.press.pressFiles}
          />
        )}
        {activePage === 'tour' && (
          <TourInfo
            locale={data.locale}
            tourHighlight={data.tour.splash}
            tourEvents={data.tour.events}
          />
        )}
      </Layout>
    </I18nextProvider>
  );
};

export const ArtistWysiwygOverlay = ({
  status,
  form,
  onNavigation,
  onReleaseClick,
  page,
}: {
  status: 'generating' | 'generated' | 'not-generated' | null;
  form: Props['form'];
  onNavigation: Props['onNavigation'];
  onReleaseClick: Props['onReleaseClick'];
  page: Props['page'] | null;
}) => {
  return (
    <WysiwygTemplateContainer
      status={status}
      containerName="artist-template-sizer"
    >
      <ArtistLayoutConnected
        onReleaseClick={onReleaseClick}
        onNavigation={onNavigation}
        page={page || 'about'}
        form={form}
      />
    </WysiwygTemplateContainer>
  );
};
