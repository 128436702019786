import styled from '@emotion/styled';
import FieldWrapper from '../../base/FieldWrapper';
import { Wrapper as CheckboxWrapper } from '../../toggle/Checkbox/styles';

export const FieldGroup = styled.div`
  padding: 24px 24px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);

  & > ${FieldWrapper}:not(:last-child) {
    margin-bottom: 24px;
  }
  & > ${CheckboxWrapper} {
    margin: 8px;
  }

  &:last-of-type,
  &:last-child {
    box-shadow: none;
  }
`;
