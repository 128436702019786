import styled from '@emotion/styled';
import { HelpIcon } from '@imus/base-ui';
import type { MouseEventHandler } from 'react';
import { BodySmall, Caption } from '../../text';
import { IconButton } from '../button';
import { clickableStyle } from '../button/Clickable';
import { InputActionSeparator } from '../input/NewInput';

const FieldWrapperBase = ({
  style,
  testId,
  children,
  className,
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>;
  testId?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div
      data-test-id={testId}
      style={style}
      onClick={onClick}
      className={className}
    >
      {children}
    </div>
  );
};

export const FieldCardSection = styled.div`
  gap: 24px;
  &:has(*) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 32px 32px;
    padding-bottom: 32px;
    display: grid;
  }
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

// TODO: Unify backgrounds with createInputWithStyles
export const FieldCard = styled(FieldWrapperBase)<{
  status?: 'error' | 'warning' | 'success';
}>`
  position: relative;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 16px;

  ${Caption},
  ${BodySmall} {
    color: var(--fg-2, rgba(0, 0, 0, 0.65));
    font-feature-settings:
      'ss01' on,
      'cv11' on;
  }
  ${({ status }) => {
    switch (status) {
      case 'error':
        return `
  border: 1px solid var(--state-negative, #F00);
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.03) 0%, rgba(255, 0, 0, 0.03) 100%), var(--surface-bg-3, #F4F4F4);
  `;
      case 'warning':
        return `
  border: 1px solid var(--accent-orange, #F00);
background: var(--background-bg-3, #F4F4F4);
&:before {
content: '';
position: absolute;
width: 100%;
height: 100%;
z-index: 1;
opacity: 0.03;
pointer-events: none;
background: var(--accent-orange, #FB9013);
}
  `;
      default:
        return `
  border: 1px solid var(--fg-4);
  background: var(--bg-3, #f4f4f4);`;
    }
  }}
`;

const FieldCardActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 16px;
  z-index: 2;
`;

const FieldCardActionBase = ({
  children,
  onClickHelp,
  action,
  style,
  testId,
  status,
  className,
  onClick,
}: {
  onClick?: MouseEventHandler;
  testId?: string;
  style?: React.CSSProperties;
  className?: string;
  status?: 'error' | 'warning' | 'success';
  children?: React.ReactNode;
  onClickHelp?: MouseEventHandler;
  action?: React.ReactNode;
}) => {
  return (
    <FieldCard
      onClick={onClick}
      status={status}
      testId={testId}
      style={style}
      className={className}
    >
      <div>
        {children}
        <FieldCardActionsWrapper>
          {action}
          {onClickHelp && (
            <>
              <InputActionSeparator />
              <IconButton
                style={{ zIndex: 3, pointerEvents: 'all' }}
                inline={true}
                onClick={onClickHelp}
                tabIndex={-1}
              >
                <HelpIcon />
              </IconButton>
            </>
          )}
        </FieldCardActionsWrapper>
      </div>
    </FieldCard>
  );
};

export const FieldCardAction = styled(FieldCardActionBase)`
  & > div {
    align-items: center;
    margin: 0 16px;
    padding: 16px 0;
    display: flex;
    flex: 1;
    z-index: 1;
  }
  padding: 0px;
  align-items: center;
  ${(props) => clickableStyle(props)}

  & > div:first-child {
    user-select: auto;
  }
  & ~ & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }

  &:has(~ &) {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    & > div {
      border-bottom: 1px solid var(--fg-4);
    }
  }
`;
