export const Home = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.65563 9.66792L10.6556 4.22055C11.4182 3.52823 12.5818 3.52823 13.3444 4.22055L19.3444 9.66792C19.7619 10.047 20 10.5847 20 11.1487V18.34C20 19.2568 19.2568 20 18.34 20H16.16C15.2432 20 14.5 19.2568 14.5 18.34V14.7368C14.5 14.1846 14.0523 13.7368 13.5 13.7368H10.5C9.94772 13.7368 9.5 14.1846 9.5 14.7368V18.34C9.5 19.2568 8.75679 20 7.84 20H5.66C4.74321 20 4 19.2568 4 18.34V11.1487C4 10.5847 4.23809 10.047 4.65563 9.66792Z"
        stroke="black"
        strokeOpacity="0.9"
        strokeWidth="2"
      />
    </svg>
  );
};
export const Products = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        y="4"
        width="6"
        height="6"
        rx="3"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
      <rect
        x="14"
        y="4"
        width="6"
        height="6"
        rx="3"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
      <rect
        x="14"
        y="14"
        width="6"
        height="6"
        rx="3"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="14"
        width="6"
        height="6"
        rx="3"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
    </svg>
  );
};
export const Library = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25403 4.05963L3.02506 19.9404"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 4V20"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M15 4V20"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20 4V20"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const Analytics = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16L6.78857 12.3604C7.45842 11.4861 8.70907 11.3179 9.58607 11.9842L10.4607 12.6487C11.3192 13.3009 12.5401 13.1553 13.2211 12.3196L20 4"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 20H20"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const Revenue = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.5C11 1.94772 11.4477 1.5 12 1.5C12.5523 1.5 13 1.94772 13 2.5V4C13 4.02432 12.9991 4.04844 12.9974 4.07233C13.8859 4.20292 14.8204 4.52464 15.6178 5.15118C16.7645 6.05213 17.5 7.48395 17.5 9.5H15.5C15.5 8.01605 14.9855 7.19787 14.3822 6.72382C13.7373 6.21715 12.8559 6 12 6C11.0596 6 10.1478 6.13744 9.50161 6.51213C8.9412 6.83707 8.50001 7.37888 8.50001 8.5C8.50001 9.23444 8.89314 9.70967 9.65809 10.1176C10.2502 10.4335 10.8842 10.6199 11.5202 10.8069L11.5202 10.8069C11.7862 10.8851 12.0525 10.9634 12.3162 11.0513L11.6838 12.9487C11.5402 12.9008 11.356 12.8467 11.1448 12.7847L11.1447 12.7847L11.1447 12.7847L11.1447 12.7847C10.4655 12.5852 9.50657 12.3035 8.71692 11.8824C7.60687 11.2903 6.50001 10.2656 6.50001 8.5C6.50001 6.67987 7.30882 5.47168 8.4984 4.78193C9.27606 4.33103 10.1701 4.12736 11.0011 4.04636C11.0004 4.03099 11 4.01554 11 4V2.5ZM12.999 19.9549C13.83 19.8739 14.724 19.6702 15.5016 19.2193C16.6912 18.5296 17.5 17.3214 17.5 15.5012C17.5 13.7357 16.3931 12.7109 15.2831 12.1189C14.4934 11.6977 13.5345 11.4161 12.8553 11.2166L12.8552 11.2165C12.644 11.1545 12.4599 11.1004 12.3162 11.0525L11.6838 12.9499C11.9475 13.0378 12.2139 13.1161 12.4798 13.1943C13.1158 13.3814 13.7498 13.5678 14.3419 13.8836C15.1069 14.2916 15.5 14.7668 15.5 15.5012C15.5 16.6223 15.0588 17.1642 14.4984 17.4891C13.8522 17.8638 12.9404 18.0012 12 18.0012C11.1441 18.0012 10.2627 17.7841 9.61782 17.2774C9.01448 16.8034 8.5 15.9852 8.5 14.5012H6.5C6.5 16.5173 7.23552 17.9491 8.38218 18.8501C9.17958 19.4766 10.1141 19.7983 11.0025 19.9289C11.0009 19.9524 11 19.9761 11 20V21.5C11 22.0523 11.4477 22.5 12 22.5C12.5523 22.5 13 22.0523 13 21.5V20C13 19.9849 12.9997 19.9698 12.999 19.9549Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );
};
export const Account = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="7"
        r="3"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
      <path
        d="M19.5665 17.8378C19.7331 18.3819 19.5871 18.8834 19.2141 19.2869C18.8254 19.7075 18.1978 20 17.5 20H6.5C5.80221 20 5.17464 19.7075 4.78593 19.2869C4.41293 18.8834 4.26692 18.3819 4.43345 17.8378C4.87724 16.388 5.66587 15.2019 6.85006 14.3698C8.03862 13.5346 9.7061 13 12 13C14.2939 13 15.9614 13.5346 17.1499 14.3698C18.3341 15.2019 19.1228 16.388 19.5665 17.8378Z"
        stroke="black"
        strokeOpacity="0.8"
        strokeWidth="2"
      />
    </svg>
  );
};
