//
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRelease, getReleaseCover, useLatestOpenBundles } from 'imddata';
import { ArtworkImage } from 'imdui';
import {
  LinkButton,
  Content,
  Body,
  Centered,
  LoadingIndicator,
} from 'imdshared';
import { LargeDashboardCard, ErrorBoundary } from 'components';

const StyledArtworkImage = styled(ArtworkImage)`
  width: 120px;
  height: 120px;
`;

const loadedContentAppear = keyframes`
  from  { opacity: 0; }
  to    { opacity: 1; }
`;

const Container = styled.div`
  padding: 12px 24px 24px;
  z-index: 1;
  display: flex;
  animation: ${loadedContentAppear} 0.3s ease-in-out;
`;

const TextColumn = styled.div`
  flex: 1;
  margin-left: 32px;
  overflow: hidden;

  > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0;
    animation: ${loadedContentAppear} 0.4s forwards ease-in-out;
  }

  > span:not(:last-child) {
    margin-bottom: 8px;
  }

  > span:nth-of-type(1) {
    animation-delay: 0.1s;
  }
  > span:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  > span:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  > span:nth-of-type(4) {
    animation-delay: 0.4s;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  border-radius: 0px;
  height: 48px;
`;

const DistributionInProgressCard = ({
  releaseId,
  bundleId,
  loading,
}: {
  releaseId?: number;
  bundleId?: number;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  const { entry: release } = useRelease({ id: releaseId, passive: !releaseId });

  const genre = release?.genre;

  if (loading) {
    return (
      <Container>
        <Centered>
          <LoadingIndicator size="large" />
        </Centered>
      </Container>
    );
  }

  if (!release) {
    return (
      <Container>
        <Body>{t('no-distribution-in-progress')}</Body>
      </Container>
    );
  }

  const artworkSrc = getReleaseCover(release);

  return (
    <>
      <Container>
        <StyledArtworkImage src={artworkSrc} shadow="medium" />

        <TextColumn>
          <Content>
            {release.defaultNameNormalized?.title || t('unknown-release')}
          </Content>

          <Body>
            {release.defaultNameNormalized?.displayArtist ||
              t('unknown-artist')}
          </Body>

          {genre && <Body>{`${t(genre.name)}`}</Body>}

          <Body>{`${t(release.releaseTypeId)}`}</Body>
        </TextColumn>
      </Container>

      <StyledLinkButton
        to={`/order/md/${bundleId}`}
        text={t('resume-distribution-at', { step: t('release-details') })}
      />
    </>
  );
};

const SelectDeliveryBundle = () => {
  const { t } = useTranslation();
  const {
    request: { loading, loaded },
    entries,
    refresh,
  } = useLatestOpenBundles();

  // Only run refresh if already loaded on mount
  useEffect(() => {
    if (loaded) {
      refresh();
    }
  }, []);

  const bundle = entries[0];
  if (loading || !bundle) return null;

  return (
    <LargeDashboardCard
      label={t('distribution-in-progress')}
      action={
        <ErrorBoundary>
          <DistributionInProgressCard
            releaseId={bundle?.releases[0]?.releaseId}
            bundleId={bundle?.id}
            loading={loading}
          />
        </ErrorBoundary>
      }
    />
  );
};

export default SelectDeliveryBundle;
