import styled from '@emotion/styled';
// import { useSettings } from 'imddata';
import Frame1 from './frame1.svg';
import Frame2 from './frame2.svg';
import Frame3 from './frame3.svg';
import type { Action } from 'imdui';
import {
  Button,
  H4,
  Card,
  CardOption,
  Chip,
  H1,
  HelperText,
  NewInput,
  OverlineText,
  FieldCard,
  HelpWindowContext,
} from 'imdui';
import { BodyM } from '@imus/services-ui/src/Text';
import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import {
  MusicMasteringIcon,
  NavHome,
  NetworkUploadIcon,
  ShareIcon,
} from '@imus/base-ui';
import { ChevronRight } from 'imdui/src/components/icon/ComponentIcons';
import { useHistory } from 'react-router';
import { GenreSelectField, EnabledField } from 'imdshared';

const Layout = styled.div`
  width: 100%;
  display: flex;
  & > div {
    flex: 1;
  }

  @media screen and (min-width: 1128px) {
    & > div {
      flex: 0.5;
    }
  }
`;

const ImageLayout = styled.div`
  justify-content: center;
  display: none;
  align-items: center;
  background: var(--bg-4, #eee);
  backdrop-filter: blur(15px);
  @media screen and (min-width: 1128px) {
    display: flex;
  }
`;

const TextBlock = styled.div`
  max-width: 360px;
  width: 100%;
  @media screen and (min-width: 1128px) {
    max-width: auto;
    width: auto;
  }
  ${H1} {
    margin-bottom: 16px;
  }
  ${BodyM} {
    display: inline-flex;
    max-width: 360px;
    color: var(--fg-2);
  }
`;

const FieldBlock = styled.div`
  width: 100%;
  max-width: 360px;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  align-items: center;

  @media screen and (min-width: 1128px) {
    align-items: flex-start;
    gap: 56px;
    padding: 56px;
  }
  background: var(--bg-2, #eee);
`;

const SimpleStepperBase = ({
  style,
  className,
  stepCount,
  activeStep,
}: {
  style?: React.CSSProperties;
  className?: string;
  stepCount: number;
  activeStep: number;
}) => {
  return (
    <div className={className} style={style}>
      {new Array(stepCount).fill(0).map((_v, idx) => {
        return (
          <svg
            key={idx}
            width="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="4"
              cy="4"
              r="4"
              fillOpacity={activeStep === idx + 1 ? '1' : '0.1'}
              fill="black"
            />
          </svg>
        );
      })}
    </div>
  );
};

const SimpleStepper = styled(SimpleStepperBase)`
  display: flex;
  gap: 12px;
`;

const NavBlock = styled.div`
  margin-top: auto;
  gap: 16px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1128px) {
    & > * {
      width: 176px;
    }
  }
`;

const AutoDmdWrapper = styled.div`
  margin: 32px 0 0 0;
  padding: 32px 0 0 0;
  border-top: 1px solid var(--fg-4);
`;

enum ActionTypes {
  CHANGE = 'CHANGE',
  CONTINUE = 'CONTINUE',
  BACK = 'BACK',
}

type Action =
  | {
      type: typeof ActionTypes.CHANGE;
      field: 'userCategory' | 'genreId' | 'artistName';
      value: any;
    }
  | { type: typeof ActionTypes.CONTINUE }
  | { type: typeof ActionTypes.BACK };

type State = {
  activeStep: 1 | 2 | 3;
  values: {
    userCategory: string[];
    genreId: number | undefined;
    artistName: string;
  };
  errors: {
    userCategory?: string;
    genreId?: string;
    artistName?: string;
  };
};

type Reducer = (s: State, a: Action) => State;

const initialState: State = {
  activeStep: 1,
  values: {
    userCategory: [],
    genreId: undefined,
    artistName: '',
  },
  errors: {},
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE: {
      const newErrors = {
        ...state.errors,
        [action.field]: undefined,
      };
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.value,
        },
        errors: newErrors,
      };
    }

    case ActionTypes.BACK: {
      switch (state.activeStep) {
        case 1:
          return state;
        case 2:
          return {
            ...state,
            activeStep: 1,
          };
        case 3:
          return {
            ...state,
            activeStep: 2,
          };
        default:
          return state;
      }
    }
    case ActionTypes.CONTINUE: {
      switch (state.activeStep) {
        case 1:
          if (state.values.userCategory.length)
            return {
              ...state,
              activeStep: 2,
            };
          else
            return {
              ...state,
              errors: {
                ...state.errors,
                userCategory: 'field-required',
              },
            };
        case 2:
          if (state.values.artistName && state.values.genreId)
            return {
              ...state,
              activeStep: 3,
            };
          else
            return {
              ...state,
              errors: {
                ...state.errors,
                artistName: 'field-required',
                genreId: 'field-required',
              },
            };
        case 3:
          return state;
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export const Onboarding = ({
  onSubmit,
}: {
  onSubmit: (r: {
    userCategory: string[];
    genreId: number;
    artistName: string;
  }) => void;
}) => {
  const [
    {
      activeStep,
      values: { userCategory, genreId, artistName },
      errors,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [autoDmdRedirect, setAutoDmdRedirect] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const handleSubmit = useCallback(
    (
      trackingEvent: string,
      trackingPayload?: {
        link_clicked: 'dmd' | 'artist hub' | 'mastering' | 'dashboard';
      }
    ) => {
      if (window.analytics) {
        window.analytics.track(trackingEvent, trackingPayload);
      }
      onSubmit({ userCategory, genreId: genreId as number, artistName });
    },
    [userCategory, artistName, genreId]
  );
  useEffect(() => {
    if (activeStep === 3 && autoDmdRedirect) {
      handleSubmit('FT Clicked ready to distribute onboarding');
      history.push('/order/md/new');
    }
  }, [activeStep, autoDmdRedirect]);
  const showHelpWindow = useContext(HelpWindowContext);
  return (
    <Layout>
      <ImageLayout>
        {activeStep === 1 && <Frame1 />}
        {activeStep === 2 && <Frame2 />}
        {activeStep === 3 && <Frame3 />}
      </ImageLayout>
      <ContentLayout>
        <SimpleStepper activeStep={activeStep} stepCount={3} />
        <TextBlock>
          <H1>{t(`onboarding-title-${activeStep}`, {})}</H1>
          <BodyM>{t(`onboarding-text-${activeStep}`, {})}</BodyM>
        </TextBlock>
        {activeStep === 1 && (
          <FieldBlock>
            <div>
              <OverlineText label={t('what-describes-you-best')} />
              <FieldCard status={errors.userCategory ? 'error' : undefined}>
                {['artist', 'label', 'band', 'producer', 'collaborator'].map(
                  (key) => {
                    return (
                      <Chip
                        testId={`OnboardingUserCategory-${key}`}
                        key={key}
                        state={
                          userCategory.includes(key) ? 'primary' : undefined
                        }
                        text={t(key)}
                        onClick={() => {
                          dispatch({
                            type: ActionTypes.CHANGE,
                            field: 'userCategory',
                            value: userCategory.includes(key)
                              ? userCategory.filter((vf) => vf !== key)
                              : [...userCategory, key],
                          });
                        }}
                      />
                    );
                  }
                )}
              </FieldCard>
              <HelperText
                errorText={
                  errors.userCategory ? t(errors.userCategory) : undefined
                }
              />
            </div>
          </FieldBlock>
        )}
        {activeStep === 2 && (
          <FieldBlock>
            <NewInput
              label={t('what-is-your-artist-name')}
              testId={`artist-name`}
              value={artistName}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-artist-name-title'),
                  t('onboarding-helptext-artist-name-description')
                );
              }}
              errorText={errors.artistName ? t(errors.artistName) : undefined}
              // @ts-ignore
              onChange={(_e, value) => {
                dispatch({
                  type: ActionTypes.CHANGE,
                  field: 'artistName',
                  value,
                });
              }}
            />
            <GenreSelectField
              label={t('what-is-your-main-genre')}
              onClickHelp={() => {
                showHelpWindow(
                  t('onboarding-helptext-genre-title'),
                  t('onboarding-helptext-genre-description')
                );
              }}
              testId={`genreId`}
              enableFeatureCheck={false}
              errorText={errors.genreId ? t(errors.genreId) : undefined}
              // @ts-ignore
              input={{
                value: genreId,
                onChange: (value) => {
                  dispatch({
                    type: ActionTypes.CHANGE,
                    field: 'genreId',
                    value,
                  });
                },
              }}
              // @ts-ignore
              meta={{
                touched: true,
                error: errors.genreId,
              }}
            />
            <AutoDmdWrapper>
              <EnabledField
                label={t('i-have-release-to-distribute')}
                description={t('i-have-release-to-distribute-text')}
                // @ts-ignore
                input={{
                  value: autoDmdRedirect,
                  onChange: (v) => setAutoDmdRedirect(!!v),
                }}
                // @ts-ignore
                meta={{}}
              />
            </AutoDmdWrapper>
          </FieldBlock>
        )}
        {activeStep === 3 && !autoDmdRedirect && (
          <FieldBlock>
            <Card>
              <CardOption
                testId="start-distributing"
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'dmd',
                  });
                  history.push('/order/md/new');
                }}
              >
                <NetworkUploadIcon />
                <H4>{t('upload-your-music', { context: 'onboarding' })}</H4>
                <div />
                <ChevronRight />
              </CardOption>
              <CardOption
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'artist hub',
                  });
                  history.push('/products/artist-hub');
                }}
              >
                <ShareIcon />
                <H4>{t('try-our-promo-tools')}</H4>
                <div />
                <ChevronRight />
              </CardOption>
              <CardOption
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'mastering',
                  });
                  history.push('/order/mastering/new');
                }}
              >
                <MusicMasteringIcon />
                <H4>{t('master-a-track')}</H4>
                <div />
                <ChevronRight />
              </CardOption>
              <CardOption
                testId="Onboarding-Continue"
                onClick={() => {
                  handleSubmit('FT Clicked step 3 onboarding', {
                    link_clicked: 'dashboard',
                  });
                }}
              >
                <NavHome />
                <H4>{t('explore-on-my-own')}</H4>
                <div />
                <ChevronRight />
              </CardOption>
            </Card>
          </FieldBlock>
        )}
        <NavBlock>
          {activeStep > 1 && (
            <Button
              text={t('back')}
              onClick={() => dispatch({ type: ActionTypes.BACK })}
            />
          )}
          {activeStep < 3 && (
            <Button
              primary={true}
              testId={`Onboarding-Continue`}
              text={t('continue')}
              onClick={() => dispatch({ type: ActionTypes.CONTINUE })}
              disabled={
                activeStep === 1
                  ? !!errors.userCategory
                  : activeStep === 2
                    ? !!errors.genreId || !!errors.artistName
                    : false
              }
            />
          )}
        </NavBlock>
      </ContentLayout>
    </Layout>
  );
};
