import type { MouseEventHandler } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import {
  Card,
  AppTitle,
  ClickableButton,
  ComponentIcons,
  BodySmall,
} from 'imdui';

type Props = {
  onClick?: MouseEventHandler;
  id?: string;
  label?: string;
  children?: React.ReactNode;
  action?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const StyledChevron = styled(ComponentIcons.ChevronRight)`
  margin-left: auto;
`;

const GridHeader = styled(AppTitle)`
  flex-shrink: 0;
  line-height: 24px;
  gap: 8px;
  display: flex;
`;

const DashboardCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  ${({ onClick }) => {
    return onClick
      ? `
      &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px var(--fg-1);
        ${StyledChevron} * {
          fill: var(--fg-1);
        }
      }
    `
      : '';
  }}

  > ${ClickableButton} {
    margin-top: auto;
    height: 48px;
    border-radius: 0px;
  }

  > ${GridHeader} {
    padding: 24px 24px 0px 24px;
    svg {
      flex-shrink: 0;
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 24px 24px 24px;
  flex: 1;
`;

const ActionWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  position: relative;
  flex: 1;
  & > button {
    min-height: 48px;
    border-radius: 0;
    margin-top: auto;
    width: 100%;
  }
`;

const DashboardCardText = styled(BodySmall)`
  color: var(--fg-2, rgba(0, 0, 0, 0.65));
`;

const DashboardCard: React.FC<Props> & {
  Chevron: typeof StyledChevron;
  Text: typeof DashboardCardText;
} = ({ id, action, label, onClick, children, className, style }: Props) => (
  <DashboardCardWrapper
    onClick={onClick}
    id={id}
    className={className}
    style={style}
  >
    {label && (
      <GridHeader>
        {label} {onClick ? <StyledChevron /> : null}
      </GridHeader>
    )}

    {children ? <ContentWrap>{children}</ContentWrap> : null}
    {action ? <ActionWrap>{action}</ActionWrap> : null}
  </DashboardCardWrapper>
);

export const LargeDashboardCard = styled(DashboardCard)`
  grid-column: 1 / -1;
  @media (min-width: 1600px) {
    grid-column: span 2;
  }
`;

DashboardCard.Text = DashboardCardText;

DashboardCard.Chevron = StyledChevron;

export default DashboardCard;
