import React, { useContext } from 'react';
import { DataContext, buildChartTheme, DataProvider } from '@visx/xychart';
import { Button, ButtonChip } from 'imdui';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { Props as ChartHeaderProps } from './ChartHeader';
import { ChartHeader } from './ChartHeader';
import { ChartsGridCard } from './ChartsGridCard';
import { LegendIcon } from './LegendItem';
import type { Props } from './LineChart';
import LineChart, { defaultConfig, disabledConfig } from './LineChart';
import { lineColors } from './styles';

function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const theme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  colors: lineColors,
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  tickLength: 2,
});

const disabledTheme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  colors: ['#ccc'],
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  tickLength: 2,
});

type TrendProps = Props &
  ChartHeaderProps & {
    onCloseEntry: (id: string) => void;
    onCloseAll: () => void;
    displayLegend: boolean;
    loading?: boolean;
    className?: string;
    style?: React.CSSProperties;
  };

const ChipGrid = styled.div`
  padding: 0 0 24px 0;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const ChartCardWithButtonLegend = ({
  verticalTicksNum,
  disabled,
  title,
  selected,
  data,
  caption,
  changeRatio,
  shops,
  selectedShop,
  onChangeShop,
  onCloseEntry,
  onCloseAll,
  displayLegend,
  className,
  loading,
  style,
}: TrendProps) => {
  const { colorScale } = useContext(DataContext);
  const { t } = useTranslation();
  return (
    <div className={className} style={style}>
      {displayLegend ? (
        <ChipGrid>
          {data &&
            Object.keys(data)?.map((id) => (
              <ButtonChip
                // TODO: fix memo component
                // eslint-disable-next-line
                iconLeft={({ style: iconStyle }) => (
                  <LegendIcon
                    style={iconStyle}
                    fill={colorScale?.(`${id}`)}
                    type="line"
                  />
                )}
                backgroundColor={addAlpha(
                  colorScale?.(`${id}`) || '#f2f2f2',
                  0.08
                )}
                key={id}
                text={data?.[id]?.[0]?.label || id}
                onClickDelete={() => {
                  if (onCloseEntry) {
                    onCloseEntry(id);
                  }
                }}
              />
            ))}
          <div>
            <Button
              text={t('clear')}
              onClick={() => {
                if (onCloseAll) {
                  onCloseAll();
                }
              }}
            />
          </div>
        </ChipGrid>
      ) : null}
      <ChartsGridCard showLoadingIndicator={true} loading={loading}>
        <ChartHeader
          title={title}
          caption={caption}
          selectedShop={selectedShop}
          shops={shops}
          changeRatio={changeRatio}
          onChangeShop={onChangeShop}
        />
        <LineChart
          disabled={disabled || loading}
          verticalTicksNum={verticalTicksNum}
          selected={selected}
          data={data}
        />
      </ChartsGridCard>
    </div>
  );
};

export const StreamsChartCard = (props: TrendProps) => (
  <DataProvider
    xScale={props.disabled ? disabledConfig.x : defaultConfig.x}
    yScale={props.disabled ? disabledConfig.y : defaultConfig.y}
    theme={props.disabled || props.loading ? disabledTheme : theme}
  >
    <ChartCardWithButtonLegend {...props} />
  </DataProvider>
);
