import type { Accept, FileRejection } from 'react-dropzone';
import { useDropzone, ErrorCode } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';
import type { WrappedFieldArrayProps } from 'redux-form';
import { formValueSelector, Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  useState,
  useCallback,
  useEffect,
  Fragment,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { Button, Caption, HelperText, Window } from 'imdui';
import { JoinTagItem } from 'imdshared/src/Next/JoinTags/JoinTagsList';
import { JoinTagAddButton } from 'imdshared/src/Next/JoinTags';
import { WindowWrapper } from './WindowWrapper';
import { JoinTagItemWrapper } from 'imdshared/src/Next/JoinTags/JoinTagsList/JoinTagItem';
import { css } from '@emotion/react';
import type { PressFile } from '@imus/artist-page-template';
import type { UploadPreview } from 'imdshared';
import { InputField, useSingelFileUploadManager } from 'imdshared';
import { useCreateEntity, useDeleteEntity, useUpdateEntity } from 'imddata';
import type { ReduxState } from 'imddata';
import { useSelector } from 'react-redux';
import prettyBytes from 'pretty-bytes';

type ArtistCollectionFieldProps = {
  artistId: number;
  defaultDescription: string;
  accept: Accept;
  dragType?: string;
  addText: string;
};

const buttonWrapperStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
`;

type FieldValue = PressFile & { id?: string };

const ArtistFileForm = reduxForm<
  FieldValue,
  { submitText: string; onSubmit: (v: FieldValue) => void }
>({
  form: 'ArtistFile',
})(({ submitText, initialValues, handleSubmit, onSubmit }) => {
  const { t } = useTranslation();

  if (!initialValues.id) {
    return null;
  }
  return (
    <>
      <Field
        name="description"
        component={InputField}
        label={t('description')}
      />
      <Button
        text={submitText}
        onClick={handleSubmit(onSubmit)}
        type="submit"
      />
    </>
  );
});

const FileUploader = ({
  file,
  artistId,
  galleryId,
  onUploaded,
  onFailed,
}: {
  artistId: number;
  galleryId: string;
  file: File;
  onUploaded: (result: UploadPreview, id?: string) => void;
  onFailed: (id: string) => void;
}) => {
  const {
    createEntry: createImage,
    request: { id },
  } = useCreateEntity({ entity: 'artistImages' });
  const { request, upload, preview } = useSingelFileUploadManager({
    handlerData: { id },
    handler: 'artistImage',
  });
  useEffect(() => {
    if (id) {
      upload(file);
    } else {
      createImage({ data: { artistId, artistGalleryId: galleryId } });
    }
  }, [id]);
  useEffect(() => {
    if (request.failed) {
      onFailed(id as string);
      return;
    }
    if (request.uploaded && preview) {
      onUploaded(preview, id as string);
    }
  }, [request.failed, request.uploaded, id]);

  return null;
};

const MAX_FILES = 10;
const MAX_SIZE = 3145728;

export const ArtistIdContext = createContext(0);

export const ArtistImagesField = ({
  meta: { form },
  fields,
  accept,
  dragType = 'artist-images',
  defaultDescription,
}: WrappedFieldArrayProps<FieldValue> & ArtistCollectionFieldProps) => {
  const artistId = useContext(ArtistIdContext);
  const galleryIdSelector = useMemo(() => formValueSelector(form), [form]);

  const galleryId = useSelector((state: ReduxState) =>
    galleryIdSelector(state, 'data.artistGalleryId')
  );
  const [formState, setFormState] = useState<null | 'add' | number>(null);
  const editing = typeof formState === 'number';
  const [uploadQueue, setUploadQueue] = useState<{ id: string; file: File }[]>(
    []
  );

  const [failedQueue, setFailedQueue] = useState<
    { id: string; file: File; errors: FileRejection['errors'] }[]
  >([]);
  const { updateEntry: updateImage } = useUpdateEntity({
    entity: 'artistImages',
  });
  const { deleteEntry } = useDeleteEntity({
    entity: 'artistImages',
  });
  const handleDrop = useCallback(
    (acceptedFiles: File[], failedFiles: FileRejection[]) => {
      if (acceptedFiles.length) {
        setUploadQueue((uq) => [
          ...uq,
          ...acceptedFiles.map((file) => ({ id: uuidv1(), file })),
        ]);
      }
      if (failedFiles.length) {
        setFailedQueue((uq) => [
          ...uq,
          ...failedFiles.map(({ file, errors }) => ({
            id: uuidv1(),
            file,
            errors,
          })),
        ]);
      }
    },
    []
  );

  const maxFiles = MAX_FILES - fields.length - uploadQueue.length;

  useEffect(() => {
    const allowedForUploadAttempt = failedQueue.filter(
      (item) =>
        item.errors.length === 1 &&
        item.errors[0].code === ErrorCode.TooManyFiles
    );
    console.log(allowedForUploadAttempt, failedQueue, maxFiles);
    if (maxFiles === allowedForUploadAttempt.length) {
      setUploadQueue((uq) => [...uq, ...allowedForUploadAttempt]);
      setFailedQueue((fq) =>
        fq.filter(
          (item) =>
            !allowedForUploadAttempt.find(
              (allowedItem) => allowedItem.id !== item.id
            )
        )
      );
    }
  }, [maxFiles, failedQueue.length]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: MAX_SIZE,
    maxFiles: maxFiles,
    onDrop: handleDrop,
    accept,
  });
  const { t } = useTranslation();
  return (
    <>
      <input {...getInputProps()} />
      <div>
        {fields.map((f, index) => {
          return (
            <>
              <JoinTagItem
                style={{
                  minHeight: '56px',
                }}
                key={index}
                index={index}
                value={{
                  label: fields.get(index).description || defaultDescription,
                  sublabel: fields.get(index)?.file?.filename || '',
                }}
                onClickItem={() => {
                  setFormState(index);
                }}
                type={dragType}
                onClickDelete={() => {
                  deleteEntry({
                    id: fields.get(index).id,
                  });
                  fields.remove(index);
                }}
                onDropItem={
                  dragType ? ({ from, to }) => fields.swap(from, to) : undefined
                }
              />
            </>
          );
        })}
        {uploadQueue.map((f, index) => {
          return (
            <Fragment key={f.id + '-item'}>
              <JoinTagItem
                style={{
                  minHeight: '56px',
                }}
                index={index}
                value={{
                  label: t('artist-image'),
                  sublabel: t('uploading'),
                }}
              />
              <FileUploader
                key={f.id + '-uploader'}
                artistId={artistId}
                galleryId={galleryId}
                file={f.file}
                onUploaded={({ size, downloadUrl }, id) => {
                  const nameParts = f.file.name.split('.');
                  setUploadQueue((uq) => uq.filter((uf) => uf.id !== f.id));
                  fields.push({
                    description: '',
                    id,
                    file: {
                      ...f.file,
                      extension: nameParts[nameParts.length - 1],
                      url: downloadUrl,
                      access: '',
                      size,
                      filename: f.file?.name || '',
                    },
                  });
                }}
                onFailed={(id) => {
                  setUploadQueue((uq) => uq.filter((uf) => uf.id !== f.id));
                  deleteEntry({ id });
                }}
              />
            </Fragment>
          );
        })}

        {failedQueue.map((f, index) => {
          return (
            <JoinTagItem
              key={f.id + '-item'}
              style={{
                minHeight: '56px',
              }}
              index={index}
              onClickDelete={() => {
                setFailedQueue((fq) => fq.filter((ff) => ff.id !== f.id));
              }}
              value={{
                label: f.file.name,
                sublabel: (
                  <Caption style={{ color: 'red' }}>
                    {t(f.errors[0]?.code, {
                      context: 'gallery-images',
                      maxFiles: 10,
                      maxSize: prettyBytes(MAX_SIZE),
                    })}
                  </Caption>
                ),
              }}
            />
          );
        })}
        <JoinTagItemWrapper isOver={false}>
          <div css={buttonWrapperStyle}>
            <JoinTagAddButton
              icon={undefined}
              disabled={!galleryId || maxFiles <= 0}
              text={t('add-image')}
              onClick={getRootProps().onClick}
            />
          </div>
        </JoinTagItemWrapper>
      </div>
      {(galleryId === null || maxFiles <= 0) && (
        <HelperText text={t('limit-reached')} />
      )}
      <Window
        lockClickOutside={true}
        title={t('artist-image')}
        isOpen={formState !== null}
        close={() => setFormState(null)}
      >
        <WindowWrapper>
          <ArtistFileForm
            submitText={editing ? t('save') : t('add')}
            initialValues={editing ? fields.get(formState) : {}}
            onSubmit={(values: FieldValue) => {
              if (editing) {
                updateImage(
                  {
                    data: { description: values.description },
                    query: { artistId },
                    id: values.id,
                  },
                  { debounce: true }
                );
                fields.splice(formState, 1, values);
                setFormState(null);
              }
            }}
          />
        </WindowWrapper>
      </Window>
    </>
  );
};
