import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { ComponentIcons, OptionTab } from 'imdui';
import type { ErrorSelector } from 'redux-form';
import { getFormSyncErrors } from 'redux-form';

const releasePageErrorSelector: ErrorSelector<FormData, any, string> =
  getFormSyncErrors;

function hasSyncErrors(errors: unknown): boolean {
  let entries = [];

  switch (typeof errors) {
    case 'undefined':
      return false;
    case 'string':
      return !!errors.length;
    case 'boolean':
      return errors;
    case 'number':
      return errors > 0;
    case 'object':
      if (errors === undefined || errors === null) break;
      entries = Array.isArray(errors) ? errors : Object.values(errors);
      break;
    default:
      break;
  }

  for (let i = 0; i < entries.length; i += 1) {
    const element = entries[i];
    const hasErr = hasSyncErrors(element);
    if (hasErr) return true;
  }

  return false;
}

export const FormTab = ({
  form,
  formSection,
  ...props
}: React.ComponentProps<typeof OptionTab> & {
  formSection: keyof FormData;
  form: string;
}) => {
  const hasErrors = useSelector((state) => {
    const syncErrors = releasePageErrorSelector(form)(state);
    return hasSyncErrors(syncErrors[formSection]);
  });
  const theme = useTheme();
  return (
    <OptionTab {...props}>
      {hasErrors ? (
        <ComponentIcons.Error
          fill={theme.state.error}
          style={{ margin: '0 8px 0 auto' }}
        />
      ) : null}
    </OptionTab>
  );
};
