import React from 'react';
import type { TemplateData } from '../types';
import ArtistSection from './ArtistSection';

type Props = {
  data: TemplateData;
};

export const ArtistInfo: React.FC<Props> = ({ data }) => (
  <>
    {data.artist.artists?.map(({ ...artist }, idx) => (
      <ArtistSection key={idx} {...artist} />
    ))}
  </>
);
