import React, {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import type { EntityModels } from 'imddata';
import { nil, useQuery, useEntry, useEntries } from 'imddata';
import { decamelize } from 'humps';
import { useTranslation } from 'react-i18next';
import {
  AlertBox,
  OverlineText,
  SecondaryButton,
  Button,
  HelpWindowContext,
  FieldWrapper,
} from 'imdui';
import { useDispatch } from 'react-redux';
import { Field, stopSubmit, reduxForm } from 'redux-form';

import type { Props as SimpleInfiniteListProps } from '../InfiniteList';
import { SimpleInfiniteList } from '../InfiniteList';
import FieldUpdatable from '../../fields/FieldUpdatable';
import { AppContext } from '../../logic';
import ConfirmationWindow from '../ConfirmationWindow';
import { NewInputField } from '../../fields/InputField';
import YesNoField from '../../fields/YesNoField';
import ArtistListItem from './ArtistListItem';
import isURL from 'validator/lib/isURL';

const StyledOverlineText = styled(OverlineText)``;

const AlertBoxArtistMismatch = styled(AlertBox)`
  margin: 8px 8px 0 8px;
`;

type ShopValue = {
  id?: string | null;
  processed?: string | boolean;
};

interface Props {
  manualDisplay?: boolean;
  input: {
    value: ShopValue;
    onChange: (value: string | null, processed?: boolean) => void;
  };

  queryString: string;
  shop: 'spotify' | 'apple-music';
}

type Artist = EntityModels.ArtistMetadata;

const Wrapper = styled.div``;

const offsetStyle = css`
  margin: 0;
`;

const infiniteListStyle = css`
  background-color: #f4f4f4;
`;
const spotifyRegex = /artist\/([a-zA-Z0-9]+)/;
// const appleRegex = /artist\/[a-zA-Z0-9]+\/([a-zA-Z0-9]+)/;

const ITEM_HEIGHT = 80;
const VISIBLE_COUNT = 4;
const rowHeight = (): number => ITEM_HEIGHT;

const NOT_FOUND_ARTIST_ACTION = 'not-found-artist-action';

const NotFoundAristAction = reduxForm<
  { id: string },
  {
    style?: React.CSSProperties;
    shop: string;
    onChangeArtist: (id: string) => void;
  }
>({ form: 'not-found-artist-form' })(({
  shop,
  handleSubmit,
  form,
  style,
  onChangeArtist,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [verifyingArtistId, setVerifyingArtistId] = useState();
  const entryQuery = useMemo(() => ({ shop }), [shop]);
  const {
    entry,
    request: { loaded, errorMessage: error },
  } = useEntry<EntityModels.ArtistMetadata>({
    entity: 'artistsMetadata',
    id: verifyingArtistId,
    storeId: `${shop}:${verifyingArtistId}`,
    query: entryQuery,
    passive: !verifyingArtistId,
  });
  const onSubmit = useCallback((values) => {
    setVerifyingArtistId(values.id);
  }, []);
  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  useEffect(() => {
    if (entry && entry.id === verifyingArtistId) {
      onChangeArtist(entry.id);
    }
    if (error) {
      setVerifyingArtistId(undefined);
      dispatch(
        stopSubmit(form, {
          id: t('artist-doesnt-exist'),
        })
      );
    }
  }, [loaded, entry, error]);
  return (
    <>
      <SecondaryButton
        style={style}
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t('set-artist-manually')}
      </SecondaryButton>

      <ConfirmationWindow
        title={t('set-artist-id-window-title')}
        onRequestClose={onRequestClose}
        loading={verifyingArtistId}
        message={t(`how-to-find-artist-in-${shop}--formatted`)}
        onConfirm={handleSubmit(onSubmit)}
        isOpen={isOpen}
      >
        <Field
          label={t('artist-id')}
          name="id"
          parse={(v: string) => {
            if (v && isURL(v)) {
              try {
                const { pathname, hostname } = new URL(v);
                if (hostname.includes('spotify')) {
                  const [, id] = spotifyRegex.exec(pathname) || ['', ''];
                  return id;
                } else if (hostname.includes('apple')) {
                  const ids = pathname.split('/');
                  const id = ids[ids.length - 1];
                  return id;
                }
              } catch (e) {
                console.error(e);
              }
              return v;
            }
            return v;
          }}
          component={NewInputField}
          style={{ padding: '0 24px' }}
        />
      </ConfirmationWindow>
    </>
  );
});

type RowType = Artist | { type: string };

export function ShopArtistIdField({
  input,
  shop,
  queryString,
  manualDisplay,
}: Props): JSX.Element {
  const app = useContext(AppContext);
  const isAdmin = app === 'admin';
  const { t } = useTranslation();
  const [displayList, setDisplayList] = useState(
    () => manualDisplay || !!input.value.id
  );
  const shopQuery = useMemo(
    () => ({
      shop,
      query: queryString,
    }),
    [queryString]
  );
  const { query, queryHash } = useQuery({ query: shopQuery });
  const entryQuery = useMemo(() => ({ shop }), [shop]);

  const { entry } = useEntry<EntityModels.ArtistMetadata>({
    entity: 'artistsMetadata',
    id: input.value.id === null ? undefined : input.value.id,
    storeId: `${shop}:${input.value.id}`,
    query: entryQuery,
    passive: !input.value.id,
  });

  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useEntries<EntityModels.ArtistMetadata>({
    entity: 'artistsMetadata',
    query,
    queryHash,
    passive: !queryHash || !displayList || !!input.value.id,
  });

  const data = useMemo(() => {
    if (!entries?.length) return entries;

    if (isAdmin) {
      return [{ type: NOT_FOUND_ARTIST_ACTION }, ...(entries || [])];
    }
    return [...(entries || []), { type: NOT_FOUND_ARTIST_ACTION }];
  }, [entries]);

  const nameMismatch =
    entry?.name &&
    queryString &&
    queryString.toLowerCase() !== entry.name.toLowerCase();
  const disabled = !isAdmin && !!input.value.processed;

  const renderArtistRow = useCallback<
    SimpleInfiniteListProps<RowType>['rowRenderer']
  >(
    ({ item, style, key }) => {
      if ('type' in item && item.type === NOT_FOUND_ARTIST_ACTION) {
        return (
          <NotFoundAristAction
            key={key}
            style={style}
            shop={shop}
            onChangeArtist={input.onChange}
          />
        );
      }
      if ('name' in item) {
        const selected = input.value.id === item.id;
        return (
          <ArtistListItem
            key={key}
            name={item.name}
            url={item.url}
            lastAlbum={item.lastAlbum}
            style={style}
            selected={selected}
            onClick={(): void => {
              if (disabled) {
                return;
              }
              if (selected) {
                input.onChange(null);
              } else {
                input.onChange(item.id);
              }
            }}
          />
        );
      }
      return null;
    },
    [input.onChange, input.value]
  );

  const toggleInput = useMemo(
    () => ({
      value: displayList
        ? true
        : input.value.id === null && !displayList
          ? false
          : undefined,
      onChange: (value: boolean) => {
        setDisplayList(value);
        if (value === false) {
          input.onChange(null);
        }
      },
    }),
    [displayList, input.value]
  );
  return (
    <Wrapper>
      {!manualDisplay && (
        <YesNoField css={offsetStyle} disabled={disabled} input={toggleInput} />
      )}

      {isAdmin && input.value.id ? (
        <Button
          text={input.value.processed ? 'Processed' : 'Mark as Processed'}
          disabled={!!input.value?.processed}
          onClick={() => {
            if (input.value.id) {
              input.onChange(input.value.id, true);
            }
          }}
        />
      ) : null}

      {displayList && (
        <div>
          <SimpleInfiniteList<RowType>
            css={infiniteListStyle}
            emptyPlaceholder={
              <NotFoundAristAction
                shop={shop}
                onChangeArtist={input.onChange}
              />
            }
            disableHeight={true}
            loading={input.value.id ? false : loading}
            loadNextPage={loadMore}
            hasNextPage={input.value.id ? false : !!hasMorePages && isAdmin}
            height={input.value.id ? ITEM_HEIGHT : VISIBLE_COUNT * ITEM_HEIGHT}
            data={input.value.id && entry ? [entry] : data}
            getRowHeight={rowHeight}
            rowRenderer={renderArtistRow}
            // TODO: what the heck? Is this to rerender list on input handler change?
            onChange={input.onChange}
          />
        </div>
      )}

      {nameMismatch && (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          <AlertBoxArtistMismatch
            title={t('artist-id-name-mismatch-warning-title')}
            text={t('artist-id-name-mismatch-warning-text')}
            type={AlertBox.Type.warning}
          />
        </motion.div>
      )}
    </Wrapper>
  );
}

const parseResult = (value: any, isAdmin: boolean) => {
  if (!value) return [];
  const shops = Object.keys(value);

  return shops.map((shopId) => {
    const id = value[shopId]?.id || nil;
    const data = isAdmin
      ? {
          id,
          processed: value[shopId]?.processed || nil,
        }
      : id;

    return [decamelize(shopId), data];
  });
};

type Value = {
  appleMusic?: ShopValue;
  spotify?: ShopValue;
};

type ArtistIdsProps = {
  input: {
    value: Value;
    onChange: (v: Value) => void;
  };
  components?: React.ComponentProps<typeof OverlineText>['components'];
  queryString: Props['queryString'];
};

export const ArtistIdContext = createContext({
  spotify: true,
  appleMusic: true,
});

const ArtistIds: React.FC<ArtistIdsProps> = ({
  input,
  components,
  queryString,
}) => {
  const contextValue = useContext(ArtistIdContext);
  const { t } = useTranslation();

  const showHelpWindow = useContext(HelpWindowContext);

  const onClickAppleHelp = useCallback(
    () =>
      showHelpWindow(
        t('artist-id-apple-music-title'),
        t('artist-id-apple-music-text')
      ),
    []
  );

  const onClickSpotifyHelp = useCallback(
    () =>
      showHelpWindow(t('artist-id-spotify-title'), t('artist-id-spotify-text')),
    []
  );

  const spotify = input.value?.spotify;

  const spotifyInput = useMemo(
    () => ({
      value: {
        id: spotify?.id,
        processed: spotify?.processed,
      },
      onChange: (value: string | null, processed?: boolean): void => {
        const result = {
          ...(input.value || {}),
          spotify: {
            processed,
            id: value,
          },
        };
        input.onChange(result);
      },
    }),
    [input]
  );

  const appleMusic = input.value?.appleMusic;

  const appleInput = useMemo(
    () => ({
      value: {
        id: appleMusic?.id,
        processed: appleMusic?.processed,
      },
      onChange: (value: string | null, processed?: boolean): void => {
        const result = {
          ...(input.value || {}),
          appleMusic: {
            processed,
            id: value,
          },
        };
        input.onChange(result);
      },
    }),
    [input]
  );

  return (
    <>
      {contextValue.spotify && (
        <FieldWrapper>
          <StyledOverlineText
            components={components}
            error={spotifyInput.value?.id === undefined}
            label={t('spotify-field')}
            onClickHelp={onClickSpotifyHelp}
          />

          <ShopArtistIdField
            shop="spotify"
            queryString={queryString}
            input={spotifyInput}
          />
        </FieldWrapper>
      )}
      {contextValue.appleMusic && (
        <FieldWrapper>
          <StyledOverlineText
            error={appleInput.value?.id === undefined}
            label={t('apple-music-field')}
            onClickHelp={onClickAppleHelp}
          />

          <ShopArtistIdField
            shop="apple-music"
            queryString={queryString}
            input={appleInput}
          />
        </FieldWrapper>
      )}
    </>
  );
};

type ArtistIdFC = React.FC<
  {
    components?: React.ComponentProps<typeof OverlineText>['components'];
    queryString: Props['queryString'];
  } & React.ComponentProps<typeof FieldUpdatable>
> & { parseValue: any };

const ArtistIdField: ArtistIdFC = ({ onChange, ...props }) => {
  const app = useContext(AppContext);

  return (
    <FieldUpdatable
      {...props}
      name="shopArtistIds"
      // @ts-ignore
      component={ArtistIds}
      onChange={(event, value): void => {
        if (onChange) {
          onChange(event, parseResult(value, app === 'admin'));
        }
      }}
    />
  );
};

ArtistIdField.parseValue = parseResult;

export default ArtistIdField;
