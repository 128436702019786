//
import React from 'react';
import { ErrorBoundary } from 'components';
import { useCurrentCustomerBalance } from 'imddata';
import { BalanceDisplay, Card } from 'imdshared';

export default function BalanceCard() {
  const { isUserCustomer, cashCreditValue, promoCreditValue } =
    useCurrentCustomerBalance();

  return (
    <Card style={{ padding: '24px' }}>
      <ErrorBoundary>
        <BalanceDisplay
          isUserCustomer={isUserCustomer}
          cashCredit={cashCreditValue}
          promoCredit={promoCreditValue}
          hasConversion={false}
        />
      </ErrorBoundary>
    </Card>
  );
}
