import React, { useState, useMemo, useEffect, useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useCurrentLocale } from 'imdshared';
import config from 'imdconfig';
import type { TemplateData } from '@imus/release-page-template';
import {
  Layout,
  ArtistInfo,
  ShopsList,
  PressKit,
  TourInfo,
} from '@imus/release-page-template';
import type { ReleasePagePageId } from 'components';
import { convertReleasePageTabToPageId } from 'components';

const releaseCoverExample = {
  url: 'https://images.unsplash.com/photo-1546347386-b4a2fb657cad?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw0MTkxfDB8MXxjb2xsZWN0aW9ufDR8OTYwNzM0N3x8fHx8Mnx8MTYxNzY5NjI2OA&ixlib=rb-1.2.1&q=85',
};

export const ReleasePageTemplatePreview: React.FC<{
  coverUrl: string;
  availableShops: string[];
  title: string;
  displayArtist: string;
  isPremium: boolean;
  isBranded?: boolean;
}> = ({
  title = 'Example Title',
  displayArtist = 'Example Artist',
  coverUrl = releaseCoverExample.url,
  availableShops,
  isBranded,
  isPremium,
}) => {
  const [selectedTab, setSelectedTab] = useState<ReleasePagePageId>('release');
  const { t, i18n } = useTranslation();
  const locale = useCurrentLocale();
  const displayedPage = convertReleasePageTabToPageId(selectedTab, isPremium);
  const data: TemplateData = useMemo(
    () => ({
      free: !isPremium,
      branded: !!isBranded,
      context: {},
      meta: {
        url: '',
        title: '',
        description: '',
      },
      release: {
        barcode: '',
        title,
        displayArtist,
        cover: { url: coverUrl, extension: '' },
        services: availableShops.map((shopId) => ({
          isVisible: true,
          name: shopId,
          url: '',
        })),
      },
      general: {
        locale,
        title,
        background: [
          {
            url: coverUrl,
            extension: '',
          },
        ],
        social: [],
      },
      tour: {
        enabled: isPremium,
        events: [
          {
            name: t('release-page-tour-1-title'),
            location: t('release-page-tour-1-location'),
            status: 'ok',
            date: '2022-05-08',
            url: '',
          },
          {
            name: t('release-page-tour-2-title'),
            location: t('release-page-tour-2-location'),
            status: 'ok',
            date: '2022-05-12',
            url: '',
          },
          {
            name: t('release-page-tour-3-title'),
            location: t('release-page-tour-3-location'),
            status: 'ok',
            date: '2022-05-13',
            url: '',
          },
        ],
      },
      press: {
        enabled: isPremium,
        pressFiles: [
          {
            title: t('release-page-demo-press-title'),
            description: t('release-page-demo-press-description'),
            file: {
              url: '',
              extension: 'pdf',
              size: 2340000,
            },
          },
        ],
      },
      artist: {
        enabled: isPremium,
        artists: [
          {
            title: t('release-page-demo-artist-title'),
            teaser: t('release-page-demo-artist-teaser'),
            text: t('release-page-demo-artist-text'),
          },
        ],
      },
      config: {
        assetsUrl: config.releaseTemplateBaseUrl,
      },
    }),
    [locale, isPremium, availableShops, isBranded]
  );

  const templateI18nRef = useRef(i18n.cloneInstance());
  useEffect(() => {
    if (locale) {
      templateI18nRef.current.changeLanguage(locale);
      templateI18nRef.current.loadNamespaces('artist-hub');
      templateI18nRef.current.setDefaultNamespace('artist-hub');
    }
  }, [locale]);

  return (
    <I18nextProvider i18n={templateI18nRef.current}>
      <Layout
        data={data}
        onNavigation={(
          event: React.MouseEvent<HTMLElement>,
          newPage: ReleasePagePageId
        ) => {
          event.preventDefault();
          event.stopPropagation();
          setSelectedTab(newPage);
        }}
        page={displayedPage}
      >
        {selectedTab === 'release' && <ShopsList data={data}></ShopsList>}
        {selectedTab === 'artist' && <ArtistInfo data={data}></ArtistInfo>}
        {selectedTab === 'tour' && <TourInfo data={data}></TourInfo>}
        {selectedTab === 'press' && <PressKit data={data}></PressKit>}
      </Layout>
    </I18nextProvider>
  );
};
