// @flow
import React, { useCallback, useContext, useState } from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { FormSection, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { HelpWindowContext, DropdownMenu, NewInputField } from 'imdshared';
import isURL from 'validator/lib/isURL';
import type { SocialProviders } from '@imus/release-page-template';
import { FieldGroup, OverlineText, Body, Button, ComponentIcons } from 'imdui';

const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const tempalateLanguagesData: Array<{
  label: string;
  value: string;
}> = [
  { label: 'Deutsch', value: 'de' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Italiano', value: 'it' },
  { label: 'Português', value: 'pt' },
];

export const TemplateLanguageField = ({
  onClickHelp,
  label,
  input,
}: WrappedFieldProps & { label: string; onClickHelp?: () => void }) => {
  return (
    <DropdownMenu
      onClickHelp={onClickHelp}
      label={label}
      {...input}
      data={tempalateLanguagesData}
    />
  );
};

const createSocialValidator = (url: RegExp) => (value: string) => {
  if (!value) return '';
  return isURL(value, {
    protocols: ['https'],
    require_protocol: true,
    require_valid_protocol: true,
    host_whitelist: [url],
  })
    ? ''
    : 'invalid-url';
};

const socialFields: Array<{
  id: SocialProviders;
  validate: (value: string) => string | boolean | undefined;
}> = [
  {
    id: 'instagram',
    validate: createSocialValidator(/(www.)?instagram.com/),
  },
  {
    id: 'facebook',
    validate: createSocialValidator(/(www.)?facebook.com/),
  },
  {
    id: 'twitter',

    validate: createSocialValidator(/(www.)?twitter.com/),
  },
  {
    id: 'youtube',

    validate: createSocialValidator(/(www.)?youtube.com/),
  },
  {
    id: 'spotify',

    validate: createSocialValidator(/(www.)?spotify.com/),
  },
  {
    id: 'apple_music',
    validate: createSocialValidator(/(www.)?music.apple.com/),
  },
  { id: 'deezer', validate: createSocialValidator(/(www.)?deezer.com/) },
  {
    id: 'soundcloud',
    validate: createSocialValidator(/(www.)?soundcloud.com/),
  },
  { id: 'bandcamp', validate: createSocialValidator(/(www.)?bandcamp.com/) },
];

export type GeneralFormData = {
  locale: string;
  title?: string;
  social: Partial<Record<SocialProviders, string>>;
};

const GeneralForm: React.FC<{ releasePageId: string }> = () => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);
  const [opened, setOpened] = useState(() => false);
  const onClickOptional = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  return (
    <FormSection name="general">
      <FieldGroup style={{ borderTop: '1px solid rgba(0,0,0,0.05)' }}>
        <OverlineText label={t('social-networks')} size="large" />
        <Subheader>
          <Body>{t('social-networks-description')}</Body>
          <Button
            style={{ margin: '0 16px 0 auto' }}
            size="small"
            icon={
              !opened ? ComponentIcons.ChevronDown : ComponentIcons.ChevronUp
            }
            text={opened ? t('close') : t('edit')}
            onClick={onClickOptional}
          />
        </Subheader>
        {opened && (
          <div style={{ marginTop: '16px' }}>
            <FormSection name="social">
              {socialFields.map(({ id, validate }) => {
                return (
                  <Field
                    testId={`ReleasePage-Links-${id}`}
                    key={id}
                    validate={validate}
                    placeholder="https://"
                    name={id}
                    label={t(id)}
                    component={NewInputField}
                  />
                );
              })}
            </FormSection>
          </div>
        )}
      </FieldGroup>

      <FieldGroup>
        <Field
          name="locale"
          label={t('page-language')}
          onClickHelp={() => {
            showHelpWindow(t('page-language'), t('page-helptext-language'));
          }}
          component={TemplateLanguageField}
        />
      </FieldGroup>
    </FormSection>
  );
};

export default GeneralForm;
