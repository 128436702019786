import styled from '@emotion/styled';
import { Content } from '../../../text';
import { Clickable } from '../../button';

export const StepDivider = styled.div`
  min-width: 5px;
  height: 1px;
  align-self: center;
  display: none;
  @media (min-width: 1200px) {
    display: block;
    min-width: 20px;
    flex: 40px;
    flex-grow: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 40px;
  }
`;

export const NumberWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin: 0 12px 0 8px;

  ${Content} {
    font-weight: 700;
  }
`;

export const Wrapper = styled(Clickable)<{
  number?: boolean;
  active: boolean;
  invalid: boolean;
}>`
  align-items: center;
  flex-shrink: 1;
  border-radius: ${({ theme }) => theme.component.radius};

  height: 40px;

  background-color: ${({ theme, active }): string =>
    active ? theme.component.card : 'transparent'};

  box-shadow: ${({ active }) =>
    active ? ' 0px 1px 2px rgba(0, 0, 0, 0.1);' : ''};

  > ${Content} {
    display: block;
    height: 20px;
    margin: ${({ number }) => (!number ? '0 8px' : '0 8px 0 0px')};
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.15s ease-in-out;
  }

  ${NumberWrapper} {
    border-radius: 50%;
  }

  ${({ number, active }) =>
    number &&
    `
    @media (max-width: 1000px) {
      > ${Content} {
      }
    }

    ${
      active &&
      `
    &:hover::after {
      opacity: 0
    }`
    }

    `}
`;
