//
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { map } from 'ramda';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import {
  SecondaryButton,
  Card,
  LinkButton,
  LoadingIndicator,
  Centered,
  Block,
  Body,
  ApplicationSettingsContext,
} from 'imdshared';
import type { Nil, EntityModels } from 'imddata';
import {
  useUpdateEntity,
  useCreateEntity,
  useOrdersOverview,
  useHasUnpaidInvoices,
  nil,
} from 'imddata';
import { PageTemplate } from 'components';
import { AcceptTermsButton } from 'screens/Order/shared/AcceptTermsButton';
import {
  AvailableVoucherNotice,
  VoucherDropdown,
  SelectedVoucherRow,
  Order,
  ItemRow,
  TotalRow,
  DeductionsCard,
  TotalsCard,
} from './components';

const PayAndVoucherBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0 0 0;
`;

const TotalsDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
  justify-content: center;
  align-items: center;
`;
const VoucherDropdownWrapper = styled.div`
  margin: 0 0 0 auto;
`;

const TotalsDividerDecor = styled.div`
  width: 50px;
  height: 2px;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const CardWithMargin = styled(Card)`
  margin-bottom: 8px;
`;

const orderRegex = /order(\d+)/;

const VoucherChip = styled.div`
  font-size: 14px;
  margin-left: auto;
  margin-right: 8px;
  margin-top: -4px;
  margin-bottom: 4px;
  top: -4px;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #fff;
  padding: 0px 8px 0px 8px;
  display: inline-flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
`;

const VoucherChipText = styled.span`
  color: ${({ theme }) => theme.accent.orange};
`;

const VoucherRowStyled = styled.div`
  display: flex;
`;

const VoucherRow = ({
  idx,
  onUseVoucher,
  orderId,
}: {
  idx: number;
  orderId: number;
  onUseVoucher?: (v: { orderId: number; voucherId: Nil }) => void;
}) => {
  const { t } = useTranslation();
  return (
    <VoucherRowStyled>
      <VoucherChip key={`${idx}:voucher:${orderId}`}>
        <VoucherChipText>{t('voucher-applied')} &bull; </VoucherChipText>{' '}
        {onUseVoucher && (
          <SecondaryButton
            style={{ fontSize: '14px', marginLeft: '4px' }}
            onClick={() => {
              onUseVoucher({
                orderId,
                voucherId: nil,
              });
            }}
          >
            {t('remove-voucher')}
          </SecondaryButton>
        )}
      </VoucherChip>
    </VoucherRowStyled>
  );
};

type VoucherOrderMap = Record<number, boolean> | null;

// TODO: Refactor to be Overview type agnostic and remove voucher dependencies
export const Overview = ({
  voucherOrderMap,
  payable = true,
  children,
  overview,
  getOverview,
  onOrderClick,
  loading,
  style,
  selectedOrderId,
  paid,
  onUseVoucher,
  isDeletable,
}: {
  voucherOrderMap?: VoucherOrderMap;
  payable?: boolean;
  children?: React.ReactNode;
  isDeletable?: (id: number) => boolean;
  overview: {
    currency: EntityModels.Overview['currency'];
    subTotal: EntityModels.Overview['subTotal'];
    totalDiscounts: EntityModels.Overview['totalDiscounts'];
    paymentFee: EntityModels.Overview['paymentFee'];
    payments: EntityModels.Overview['payments'];
    tax: EntityModels.Overview['tax'];
    taxRate: EntityModels.Overview['taxRate'];
    toPay: EntityModels.Overview['toPay'];
    totalPayments: EntityModels.Overview['totalPayments'];
    total: EntityModels.Overview['total'];
    groups: EntityModels.Overview['groups'];
    discounts: EntityModels.Overview['discounts'];
  };
  getOverview?: () => void;
  onOrderClick?: React.ComponentProps<typeof Order>['onClick'];
  loading?: boolean;
  style?: React.CSSProperties;
  selectedOrderId?: number;
  paid?: boolean;
  onUseVoucher?: (v: { voucherId: number | Nil; orderId: number }) => void;
}) => {
  const { t } = useTranslation();
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  if (!overview) {
    return null;
  }
  const { currency } = overview;

  const currencyId = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currencyId,
  });

  return (
    <div style={style} data-test-id="AllOrdersOverview">
      {Object.keys(overview.groups).map((orderKey, idx) => {
        const match = orderKey.match(orderRegex);
        const orderId = match && match[1] ? Number(match[1]) : -1;
        const order = overview.groups[orderKey];

        const hasVoucher = order.items.find(
          (orderItem) =>
            orderItem.discounts &&
            orderItem.discounts.find(({ type }) => type === 'VOUCHER')
        );
        return (
          <Fragment key={idx}>
            <Order
              onClick={voucherOrderMap ? onOrderClick : undefined}
              canBeApplied={voucherOrderMap ? voucherOrderMap[orderId] : false}
              selected={selectedOrderId === orderId}
              currency={currency}
              getOverview={getOverview}
              deletable={
                !voucherOrderMap && isDeletable && isDeletable(orderId)
              }
              id={orderId}
              order={order}
            >
              <div data-test-id={`Order-${idx}`}>
                {map((orderItem) => {
                  return (
                    <ItemRow
                      key={`${idx}:${orderItem.id}`}
                      label={t(orderItem.id, { defaultValue: orderItem.name })}
                      description={t(orderItem.id + '-description', {
                        defaultValue: orderItem.description,
                        value: orderItem.description,
                      })}
                      value={
                        orderItem.value
                          ? priceFormatter.format(Number(orderItem.value))
                          : ''
                      }
                    />
                  );
                }, order.items)}
              </div>
            </Order>
            {hasVoucher && !voucherOrderMap && (
              <VoucherRow
                idx={idx}
                onUseVoucher={onUseVoucher}
                orderId={orderId}
              />
            )}
          </Fragment>
        );
      })}

      {Object.keys(overview.groups).length > 1 && (
        <CardWithMargin>
          <TotalRow
            loading={loading}
            label={t('subtotal')}
            value={
              overview.subTotal
                ? priceFormatter.format(Number(overview.subTotal))
                : ''
            }
          />
        </CardWithMargin>
      )}

      {!paid && !voucherOrderMap && (
        <TotalsDivider>
          <TotalsDividerDecor />
        </TotalsDivider>
      )}

      {!voucherOrderMap && Object.keys(overview.discounts).length > 0 && (
        <DeductionsCard overview={overview} loading={loading} />
      )}

      {!voucherOrderMap && (
        <TotalsCard
          overview={overview}
          loading={loading}
          paid={paid}
          payable={payable}
        />
      )}

      {children}
    </div>
  );
};

export default function AllOrdersOverview() {
  const { t } = useTranslation();
  const [updatingOrderId, setUpdateingOrderId] = useState<number>();
  const [selectedVoucher, setSelectedVoucher] = useState<null | {
    customerVoucherId: number;
    voucherId: number;
  }>(null);
  const [selectedOrderForVoucher, setSelectedOrderForVoucher] =
    useState<number>();

  const handleCancelVoucher = useCallback(() => {
    setSelectedOrderForVoucher(undefined);
    setSelectedVoucher(null);
  }, []);

  const {
    request: { loaded, loading, failed: noOrders },
    refresh,
    overview,
  } = useOrdersOverview();

  const {
    createEntry: createInvoice,
    createdId: invoiceId,
    request: { creating: creatingInvoice },
  } = useCreateEntity({
    entity: 'invoices',
    query: { with: 'items' },
  });

  const {
    updateEntry: updateOrder,
    request: { updating },
  } = useUpdateEntity({ entity: 'orders', id: updatingOrderId });

  const wasUpdating = useRef(false);

  useEffect(() => {
    if (wasUpdating.current && !updating) {
      refresh();
    }

    wasUpdating.current = !!updating;
  }, [updating]);

  const handleUseVoucher = useCallback(({ voucherId, orderId }) => {
    setUpdateingOrderId(orderId);
    updateOrder({
      id: orderId,
      data: {
        voucherId,
      },
    });
  }, []);

  // Map of order eligibility for selected voucher (null if no voucher selected for application)
  const voucherOrderMap = useMemo(() => {
    if (!selectedVoucher || !overview) {
      return null;
    }

    const availableVoucher = overview.availableVouchers?.find(
      (data) => data.customerVoucherId === selectedVoucher.customerVoucherId
    );
    if (!availableVoucher) {
      return null;
    }

    const { includedOrders } = overview;

    const { orders: voucherOrders } = availableVoucher;

    return voucherOrders.reduce((acc, { orderId, applies }) => {
      const order = includedOrders.find((value) => value.id === orderId);
      if (applies)
        return {
          ...acc,
          // allow order for voucher if it doesn't have one or has different one
          [orderId]: !order || order.voucherId !== availableVoucher.voucherId,
        };
      return acc;
    }, {});
  }, [selectedVoucher, overview]);

  const {
    request: { loaded: loadedInvoices },
    hasUnpaid,
  } = useHasUnpaidInvoices();

  const isDeletable = useCallback(
    (orderId) => {
      return (
        !voucherOrderMap &&
        !!overview?.includedOrders?.find((o) => o.id === orderId)?.isDeletable
      );
    },
    [overview?.includedOrders]
  );

  return (
    <PageTemplate title={t('orders')}>
      <Helmet>
        <title>{t('page-title-orders')}</title>
      </Helmet>

      {!loaded && !noOrders && (
        <Centered key="loader">
          <LoadingIndicator size="large" />
        </Centered>
      )}

      {noOrders && (
        <>
          <Block card={true} padding={[20, 20, 20, 20]}>
            <Body>{t('you-have-no-orders')}</Body>
          </Block>

          {loadedInvoices && hasUnpaid && (
            <Block
              margin={[20, 0, 20, 0]}
              card={true}
              direction="row"
              alignItems="center"
              padding={[15, 20, 15, 20]}
            >
              <Body>{t('you-have-unpaid-invoices')}</Body>

              <LinkButton
                style={{
                  margin: '0 20px 0 auto',
                }}
                to="/account/invoices"
                text={t('click-here-to-view')}
              />
            </Block>
          )}
        </>
      )}

      {overview && (
        <>
          <Header>
            {!selectedVoucher && (
              <>
                {overview &&
                overview.availableVouchers &&
                overview.availableVouchers.length ? (
                  <AvailableVoucherNotice />
                ) : null}
                <VoucherDropdownWrapper>
                  <VoucherDropdown
                    onRegisterVoucher={() => refresh()}
                    overview={overview}
                    onChange={setSelectedVoucher}
                  />
                </VoucherDropdownWrapper>
              </>
            )}

            {selectedVoucher && selectedOrderForVoucher && (
              <SelectedVoucherRow
                orderId={selectedOrderForVoucher}
                voucherId={selectedVoucher.customerVoucherId}
                onAccept={() => {
                  handleUseVoucher({
                    voucherId: selectedVoucher.voucherId,
                    orderId: selectedOrderForVoucher,
                  });
                  setSelectedVoucher(null);
                  setSelectedOrderForVoucher(undefined);
                }}
                onCancel={handleCancelVoucher}
              />
            )}
          </Header>

          <Overview
            onOrderClick={setSelectedOrderForVoucher}
            onUseVoucher={handleUseVoucher}
            isDeletable={isDeletable}
            voucherOrderMap={voucherOrderMap}
            selectedOrderId={selectedOrderForVoucher}
            getOverview={refresh}
            overview={overview}
            loading={loading}
          >
            {overview && !selectedVoucher && overview.canBePaid ? (
              <PayAndVoucherBlock>
                <Block margin={[0, 0, 0, 'auto']}>
                  <AcceptTermsButton
                    testId="AllOrdersOverview-pay-button"
                    primary="paid"
                    disabled={creatingInvoice}
                    onClick={() =>
                      createInvoice({
                        data: {
                          orderIds: overview.includedOrders.map(({ id }) => id),
                        },
                      })
                    }
                    text={t('checkout-and-pay')}
                  />
                </Block>
              </PayAndVoucherBlock>
            ) : null}
          </Overview>
        </>
      )}

      {invoiceId && <Redirect to={`/account/invoices/${invoiceId}/pay`} />}
    </PageTemplate>
  );
}
