import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavSidebar } from 'imdshared';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useCountActiveFieldUpdateRequests,
  useCurrentCustomer,
  useCurrentCustomerSubscription,
} from 'imddata';
import { BodySmall, Clickable, ComponentIcons } from 'imdui';
import styled from '@emotion/styled';
import NavItemLink from './NavItemLink';
import NavHeader from './NavHeader';
import { FieldUpdateRequestWindowPopup } from '../FieldUpdateRequestWindowPopup';
import * as Icons from './Icons';
import { AmpIcon } from '@imus/base-ui';
import { HelpCenterButton } from './HelpCenterButton';
import { AmpsTeaserWindow } from './AmpsTeaserWindow';
import qs from 'qs';

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;

  background: #ff0000;

  flex: none;
  order: 0;
  flex-grow: 0;
`;
const FooterLink = styled(Clickable)`
  background: #fafafa;
  border-radius: 8px;

  padding: 8px 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  ${BodySmall} {
    flex: 1;
  }
`;

const FieldUpdateRequestLink = function () {
  const { t } = useTranslation();
  const count = useCountActiveFieldUpdateRequests();
  const history = useHistory();
  if (!count) return null;
  return (
    <>
      <FieldUpdateRequestWindowPopup count={count} />
      <FooterLink
        onClick={() => {
          history.push('/update-requests');
        }}
      >
        <Dot />
        <BodySmall>{t('update-requests')}</BodySmall>
        <ComponentIcons.ChevronRight />
      </FooterLink>
    </>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 24px;
  & > * {
    width: 100%;
  }
`;

function NavFooter() {
  return (
    <FooterWrapper>
      <FieldUpdateRequestLink />
      <HelpCenterButton />
    </FooterWrapper>
  );
}

const components = { NavHeader, NavFooter };

const NavGrid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  row-gap: 12px;
  padding: 40px 24px;
`;

const AmpChipWrapper = styled.div`
  position: absolute;
  padding: 8px;
  top: 0px;
  right: 0;
  cursor: pointer;
  z-index: 2;
  &:hover {
    opacity: 0.8;
  }
`;

const AmpChip = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 7px;
  background: var(--bg-1, #fff);

  color: var(--foreground-fg-1, rgba(0, 0, 0, 0.95));
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.15px;
`;

function NavDrawer() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const customerSubscription = useCurrentCustomerSubscription();
  const location = useLocation();
  const history = useHistory();
  const { entry: customer } = useCurrentCustomer();

  const creditsValue = customer?.credits?.find(
    (c) => c.currencyId === 'amp'
  )?.amount;
  const excludedFromAmps =
    customerSubscription?.subscriptionId === 'vip' ||
    customerSubscription?.subscriptionId === 'professional-unlimited' ||
    customerSubscription?.subscriptionId === 'professional-unlimited-analytics';

  const params = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  useEffect(() => {
    if (params.openAmps) {
      setIsOpen(true);
    }
  }, [params.openAmps]);

  return (
    <NavSidebar components={components}>
      <NavGrid>
        <NavItemLink
          to="/"
          matchPath="(/|/dashboard)"
          strict={true}
          text={t('dashboard')}
          testId="NavItem-Dashboard"
          icon={<Icons.Home />}
        />

        <NavItemLink
          to="/library"
          text={t('library')}
          icon={<Icons.Library />}
          testId="NavItem-Library"
        ></NavItemLink>

        <NavItemLink
          to="/products"
          matchPath="(/products)"
          text={t('products')}
          icon={<Icons.Products />}
        />

        <NavItemLink
          to="/reporting"
          text={t('analytics')}
          icon={<Icons.Analytics />}
        ></NavItemLink>

        <NavItemLink
          to="/revenue"
          text={t('revenue')}
          icon={<Icons.Revenue />}
        ></NavItemLink>

        <div style={{ position: 'relative' }}>
          <NavItemLink
            to="/account"
            text={t('account')}
            testId="NavItem-Account"
            icon={<Icons.Account />}
          />
          {!excludedFromAmps && (
            <AmpChipWrapper
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <AmpChip>
                <AmpIcon width="16px" height="16px" /> {creditsValue || 0}
              </AmpChip>
            </AmpChipWrapper>
          )}
        </div>
      </NavGrid>
      <AmpsTeaserWindow
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
          // debugger;
          if (params.openAmps) {
            history.push(location.pathname);
          }
        }}
      />
    </NavSidebar>
  );
}
export default memo(NavDrawer);
