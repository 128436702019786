import React, { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// @ts-ignore
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { FilePreviewsProvider } from 'imddata';
import { ThemeController, Centered, LoadingIndicator } from 'imdui';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import RefreshWindowController from '../RefreshWindowController';
import { useSentry } from './useSentry';

type Props = {
  store: any;
  persistor: any;
  children: React.ReactElement;
};

async function loadPolyfills() {
  if (window && typeof window.IntersectionObserver === 'undefined') {
    // @ts-ignore
    await import('intersection-observer');
  }
  if ('ResizeObserver' in window === false) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer');
    // @ts-ignore
    window.ResizeObserver = module.ResizeObserver;
  }

  if ('replaceAll' in String.prototype === false) {
    // @ts-ignore
    const module = await import('string.prototype.replaceall');
    // @ts-ignore
    String.prototype.replaceAll = module;
  }
}

loadPolyfills();

const SentryGate = ({ children }: { children: React.ReactElement }) => {
  useSentry();
  return children;
};

const AppLogicProvider = ({ store, persistor, children }: Props) => (
  <Suspense
    fallback={
      <Centered style={{ width: '100%', height: '100vh' }}>
        <LoadingIndicator size="large" />
      </Centered>
    }
  >
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemeController>
              <DndProvider backend={HTML5Backend}>
                <RefreshWindowController>
                  <StrictMode>
                    <SentryGate>
                      <FilePreviewsProvider>{children}</FilePreviewsProvider>
                    </SentryGate>
                  </StrictMode>
                </RefreshWindowController>
              </DndProvider>
            </ThemeController>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </Suspense>
);

export default AppLogicProvider;
