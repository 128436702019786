import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteRelease } from 'imddata';
import { Redirect } from 'react-router-dom';

import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';

import { Window, Button, Icons, TextFormatted, FormToolbar } from 'imdui';

const textFormattedStyles = css`
  margin: 24px;
`;

const buttonStyles = (theme: Theme) => css`
  background-color: ${theme.state.error};
  > span {
    color: white;
  }
`;

export const DeleteReleaseButton = ({ id }: { id: number | string }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const {
    deletable,
    deleteEntry,
    request: { deleting, deleted },
  } = useDeleteRelease({ id });

  return (
    <>
      <Button
        key="button"
        icon={Icons.actions.trash}
        disabled={deletable}
        onClick={() => {
          setOpen(true);
        }}
        text={t('delete')}
      />

      {deleted && <Redirect to="/library/releases" />}

      <Window
        title={t('confirm-delete-release')}
        isOpen={open}
        close={() => setOpen(false)}
      >
        <TextFormatted
          css={textFormattedStyles}
          text={t('confirm-delete-release-text')}
        />

        <FormToolbar
          rightChildren={
            <Button
              key="button"
              css={buttonStyles}
              showLoading={deleting}
              onClick={deleteEntry}
              text={t('delete-release')}
            />
          }
        />
      </Window>
    </>
  );
};
