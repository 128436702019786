import React from 'react';
import styled from '@emotion/styled';
import { Button, Content, FieldCardAction } from 'imdui';
import { useTranslation } from 'react-i18next';
import { useResendConfirmationEmail, useCurrentUser } from 'imddata';

type Props = {
  style?: React.CSSProperties;
};

const FieldCardActionStyled = styled(FieldCardAction)`
  border: 2px solid ${(props) => props.theme.state.warning};
`;

const ConfirmEmailButton: React.FC<Props> = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const { entry: user } = useCurrentUser();
  const {
    resendConfirmationEmail,
    request: { created, creating, error },
  } = useResendConfirmationEmail();

  const text = created ? t('email-sent') : t('resend-confirmation-email');
  const disabled = created || creating || error;

  if (user?.isVerified) return null;

  return (
    <FieldCardActionStyled
      action={
        <Button
          disabled={disabled}
          text={text}
          onClick={resendConfirmationEmail}
        ></Button>
      }
      className={className}
      style={style}
    >
      <Content>
        {!error && t('awaiting-email-confirmation')}

        {error &&
          error.statusCode === 422 &&
          t('email-confirmation-already-sent')}
      </Content>
    </FieldCardActionStyled>
  );
};

export default ConfirmEmailButton;
