//
import React, { memo, useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Notifications, OrdersCountProvider } from 'components';
import {
  NavDrawerContext,
  LayoutContext,
  IconButton,
  AppIcon,
  Icons,
  Badge,
} from 'imdshared';

const CountBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
`;

const NavHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 8px 0 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const StyledOrdersLink = styled(Link)`
  display: block;
  position: relative;
`;

const renderCount = (ordersCount: number) => <CountBadge count={ordersCount} />;

const Cart = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.71292 2.54231C3.24191 2.38362 3.79939 2.6838 3.95809 3.21279L4.49429 5.00014H13.5062L14.0424 3.21279L14.2562 2.50016L15.0002 2.50014L19 2.50002C19.5523 2.5 20 2.9477 20 3.49999C20 4.05227 19.5523 4.5 19 4.50002L15.7443 4.50012L15.2081 6.28749L12.9581 13.7875L12.7443 14.5001H12.0003H9.00026H6.00026H5.25623L5.04244 13.7875L2.79244 6.28749L2.04244 3.78749C1.88374 3.2585 2.18392 2.70101 2.71292 2.54231ZM12.9062 7.00014H5.09429L6.74429 12.5001H9.00026H11.2562L12.9062 7.00014ZM5.50026 21.5001C6.88097 21.5001 8.00026 20.3809 8.00026 19.0001C8.00026 17.6194 6.88097 16.5001 5.50026 16.5001C4.11955 16.5001 3.00026 17.6194 3.00026 19.0001C3.00026 20.3809 4.11955 21.5001 5.50026 21.5001ZM15.0003 19.0001C15.0003 20.3809 13.881 21.5001 12.5003 21.5001C11.1195 21.5001 10.0003 20.3809 10.0003 19.0001C10.0003 17.6194 11.1195 16.5001 12.5003 16.5001C13.881 16.5001 15.0003 17.6194 15.0003 19.0001Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
);

const CloseDrawerButton = () => {
  const { close } = useContext(NavDrawerContext);
  return <IconButton icon={Icons.arrows.back} onClick={close} />;
};

const MobileHeader = () => <CloseDrawerButton />;
const DesktopHeader = () => (
  <Link style={{ display: 'inline-flex' }} to="/dashboard">
    <AppIcon />
  </Link>
);

const NavHeader = () => {
  const { close } = useContext(NavDrawerContext);
  const { isLayoutCollapsed } = useContext(LayoutContext);
  const handleRequestClose = useMemo(
    () => (isLayoutCollapsed ? close : () => null),
    [isLayoutCollapsed]
  );

  return (
    <NavHeaderContainer>
      {isLayoutCollapsed ? <MobileHeader /> : <DesktopHeader />}

      <ButtonsWrapper>
        <span onClick={handleRequestClose}>
          <StyledOrdersLink to="/order/overview">
            <IconButton icon={Cart} />

            <OrdersCountProvider>{renderCount}</OrdersCountProvider>
          </StyledOrdersLink>
        </span>

        <Notifications onClick={handleRequestClose} />
      </ButtonsWrapper>
    </NavHeaderContainer>
  );
};

export default memo(NavHeader);
