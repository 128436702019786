import React, {
  useMemo,
  useState,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { v1 as uuid } from 'uuid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { change as changeAction } from 'redux-form';
import { connect } from 'react-redux';
import {
  AppContext,
  EntityFormContext,
  useCustomerFeatureRollout,
  useEntries,
} from 'imddata';
import {
  Button,
  HelperText,
  IconButton,
  Icons,
  SecondaryButton,
  HelpWindowContext,
  TabStripe,
  TabItem,
  SubscriptionFeatureGateButton,
} from 'imdui';
import { createSelector } from '../../helpers';
import {
  DropdownMenu,
  FieldUpdatable,
  LanguageSelectorField,
  NewInputField,
} from '../../fields';
import { useSubscriptionUpsell } from '../../logic';

const SelectDefaultLanguageId = createSelector('defaultLanguageId');

export const AllowResourcesLocalizationContext = createContext(false);

export const TrackLanguageUpdateReleaseContext = createContext(null);

const RemoveNameButton = styled(IconButton)`
  display: flex;
  margin-left: auto;
`;

const AddNameBlock = ({ fields, onChange }) => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);

  const { entries } = useEntries({ entity: 'languages' });
  const values = fields.getAll();
  const [opened, setOpened] = useState(false);
  const data = entries.map((entry) => ({
    ...entry,
    disabled: values?.find(({ languageId }) => entry.id === languageId),
  }));
  const names = fields.getAll() || [];

  return (
    <>
      <Button
        onClick={() => {
          setOpened(true);
        }}
        text={t('add-new-name')}
        style={{ display: !opened ? 'flex' : 'none', width: '100%' }}
      />
      <DropdownMenu
        style={{ display: opened ? 'block' : 'none' }}
        menuIsOpen={opened}
        data={data}
        onBlur={() => {
          setOpened(false);
        }}
        onChange={(languageId) => {
          setOpened(false);
          onChange({
            names: [...names, { languageId }],
          });
          fields.push({
            id: uuid(),
            languageId,
          });
        }}
      />

      <HelperText
        text={t('helptext-short-new-asset-name')}
        onClickHelperButton={() =>
          showHelpWindow(
            t('helptext-short-new-asset-name'),
            t('helptext-new-asset-name')
          )
        }
      />
    </>
  );
};

const NamesInputs = ({
  id,
  displayLanguageCode,
  fieldsData,
  member,
  requiredFields,
  disabled,
  fields,
  index,
  onChange,
  languageId,
}) => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);

  return (
    <>
      {fieldsData.map((fieldData) => (
        <FieldUpdatable
          key={fieldData.name}
          name={`${member}.${fieldData.name}`}
          nameKey={fieldData.name}
          testId={fieldData.testId}
          component={NewInputField}
          required={requiredFields && requiredFields.includes(fieldData.name)}
          disabled={disabled}
          floatingLabelText={t(fieldData.label)}
          languageId={displayLanguageCode ? languageId : null}
          onChange={(_, value) => {
            const { id: removeId, ...data } = fields.get(index);
            onChange({
              names: [
                ...fields
                  .getAll()
                  .filter((name) => name.id !== id)
                  .map(({ id: omitId, ...fieldValue }) => fieldValue),
                {
                  ...data,
                  languageId,
                  [fieldData.name]: value,
                },
              ],
            });
          }}
          helperText={t(fieldData.shortHelp)}
          onClickHelp={() => {
            showHelpWindow(t(fieldData.label), t(fieldData.longHelp));
          }}
        />
      ))}
    </>
  );
};

const MultiNameEditor = ({
  entity,
  member,
  fields,
  fieldsData,
  disabled,
  onChange,
  index,
  requiredFields,
  nameEditingEnabled,
}) => {
  const { t } = useTranslation();
  const { revisions, languageId, id, deliveryStatus, revision, registeredAt } =
    fields.get(index);

  const app = useContext(AppContext);

  const canAdminRemove =
    !deliveryStatus ||
    app !== 'admin' ||
    [0, 1, 3, 4, 'not_delivered', 'registered', 'failed', 'cancelled'].indexOf(
      deliveryStatus
    ) >= 0;

  const deletableRevision = revision > 1 && revisions && !registeredAt;

  const formContext = useMemo(() => ({ entity, id }), [entity, id]);

  return (
    <EntityFormContext.Provider value={formContext}>
      <FieldUpdatable
        name="defaultLanguageId"
        // TODO: Fix memo component
        // eslint-disable-next-line
        component={({ input: { value } }) => {
          const selected = languageId === value;
          return (
            <RemoveNameButton
              icon={Icons.actions.remove}
              onClick={() => {
                const withoutDeletedName = fields
                  .getAll()
                  .filter((name) => name.id !== id)
                  .map(({ id: omitId, ...fieldValue }) => fieldValue);
                if (deletableRevision) {
                  fields.splice(index, 1, revisions[0]);
                } else {
                  fields.remove(index);
                }
                onChange({
                  names: deletableRevision
                    ? [...withoutDeletedName, revisions[0]]
                    : [...withoutDeletedName],
                });
              }}
              disabled={(selected && !deletableRevision) || !canAdminRemove}
            />
          );
        }}
      />
      <NamesInputs
        id={id}
        displayLanguageCode={true}
        fields={fields}
        fieldsData={fieldsData}
        member={member}
        requiredFields={requiredFields}
        disabled={disabled || (!nameEditingEnabled && registeredAt)}
        index={index}
        onChange={onChange}
        languageId={languageId}
      />

      <FieldUpdatable
        name="defaultLanguageId"
        // TODO: Fix memo component
        // eslint-disable-next-line
        component={({ input: { value, ...input }, ...props }) => {
          const selected = languageId === value;

          return (
            <SecondaryButton
              style={{ marginRight: 'auto' }}
              active={selected}
              onClick={() => {
                if (!selected) {
                  input.onChange(languageId);
                  onChange({
                    defaultLanguageId: languageId,
                  });
                }
              }}
              {...props}
            >
              {selected ? t('default-language') : t('set-as-default-language')}
            </SecondaryButton>
          );
        }}
      />
    </EntityFormContext.Provider>
  );
};

const SingleNameEditor = ({
  entity,
  change,
  member,
  fields,
  fieldsData,
  disabled,
  onChange,
  index,
  languageLabelPrefix,
  requiredFields,
  defaultLanguageId,
  nameEditingEnabled,
  form,
}) => {
  const showHelpWindow = useContext(HelpWindowContext);
  const { t } = useTranslation();

  const { languageId, id, registeredAt } = fields.get(index);

  const formContext = useMemo(() => ({ entity, id }), [entity, id]);

  if (languageId !== defaultLanguageId) {
    return null;
  }

  return (
    <EntityFormContext.Provider value={formContext}>
      <FieldUpdatable
        style={{ marginBottom: '20px' }}
        name={`${member}.languageId`}
        entityFieldName="languageId"
        disabled={registeredAt}
        floatingLabelText={t(`${languageLabelPrefix}-language`)}
        onClickHelperButton={() => {
          showHelpWindow(
            t(`${languageLabelPrefix}-language`),
            t(`${languageLabelPrefix}-track-helptext-language`)
          );
        }}
        component={LanguageSelectorField}
        onChange={(event, value, prevValue) => {
          change(form, 'defaultLanguageId', value);
          const prevName = fields
            .getAll()
            .map(({ id: omitId, ...fieldValue }) => fieldValue)
            .find((name) => name.languageId === prevValue);
          onChange({
            defaultLanguageId: value,
            names: [
              {
                languageId: value,
                ...fieldsData.reduce(
                  (acc, fieldData) => ({
                    ...acc,
                    [fieldData.name]: prevName[fieldData.name],
                  }),
                  {}
                ),
              },
            ],
          });
        }}
      />
      <NamesInputs
        id={id}
        displayLanguageCode={false}
        fields={fields}
        fieldsData={fieldsData}
        member={member}
        requiredFields={requiredFields}
        disabled={disabled || (!nameEditingEnabled && registeredAt)}
        index={index}
        onChange={onChange}
        languageId={languageId}
      />
    </EntityFormContext.Provider>
  );
};

export const tabsStyle = css`
  margin: 0px -24px 16px;
  width: calc(100% + 48px);
`;

const NameModeSelector = ({ mode, count, setMode }) => {
  return (
    <TabStripe
      css={tabsStyle}
      underline={true}
      onChange={setMode}
      selectedTab={mode}
    >
      <TabItem
        onClick={() => {
          setMode('single');
        }}
        selected={mode === 'single'}
        text="Default Name"
      />
      <TabItem
        onClick={() => {
          setMode('multi');
        }}
        selected={mode === 'multi'}
        text={`Manage Translations (${count})`}
      />
    </TabStripe>
  );
};

const NamesField = ({
  fields,
  meta,
  change,
  fieldsData,
  onChange,
  alwaysAllowed,
  disabled,
  requiredFields,
  entity,
  languageLabelPrefix,
  className,
  style,
}) => {
  const { t } = useTranslation();
  const app = useContext(AppContext);
  const allowTranslations = useContext(AllowResourcesLocalizationContext);
  const [mode, setMode] = useState(
    app === 'admin' ? 'multi' : allowTranslations ? 'multi' : 'single'
  );

  const nameFeature = useCustomerFeatureRollout({
    feature: 'post-registration-names-editing',
    rolloutKey: 'tiered-subs',
    fallback: true,
  });

  const nameEditingEnabled = nameFeature || alwaysAllowed;

  const { open: openUpsell } = useSubscriptionUpsell();

  const hasRegisteredField = useMemo(() => {
    return !!fields.getAll()?.find((f) => !!f.registeredAt);
  }, [fields]);

  const renderMultiName = useCallback(
    (member, index, inputFields) => (
      <SelectDefaultLanguageId form={meta.form} key={index}>
        {(defaultLanguageId) => (
          <MultiNameEditor
            entity={entity}
            defaultLanguageId={defaultLanguageId}
            form={meta.form}
            disabled={disabled}
            fields={inputFields}
            onChange={onChange}
            change={change}
            fieldsData={fieldsData}
            index={index}
            member={member}
            requiredFields={requiredFields}
            languageLabelPrefix={languageLabelPrefix}
            nameEditingEnabled={nameEditingEnabled}
          />
        )}
      </SelectDefaultLanguageId>
    ),
    [fields]
  );

  const renderSingleName = useCallback(
    (member, index, inputFields) => (
      <SelectDefaultLanguageId form={meta.form} key={index}>
        {(defaultLanguageId) => (
          <SingleNameEditor
            entity={entity}
            defaultLanguageId={defaultLanguageId}
            form={meta.form}
            disabled={disabled}
            fields={inputFields}
            onChange={onChange}
            change={change}
            fieldsData={fieldsData}
            index={index}
            member={member}
            requiredFields={requiredFields}
            languageLabelPrefix={languageLabelPrefix}
            nameEditingEnabled={nameEditingEnabled}
          />
        )}
      </SelectDefaultLanguageId>
    ),
    [fields]
  );

  return (
    <div className={className} style={style}>
      {app === 'admin' && (
        <NameModeSelector count={fields.length} mode={mode} setMode={setMode} />
      )}
      {app !== 'admin' && hasRegisteredField && !nameEditingEnabled && (
        <SubscriptionFeatureGateButton
          style={{ marginBottom: '16px', width: '100%', maxWidth: '100%' }}
          label={t('name-editing-upsell')}
          action={t('upgrade')}
          onClick={() => {
            openUpsell({
              analytics: { detail: 'name' },
              section: 'music-distribution',
              feature: 'post-registration-names-editing',
            });
          }}
        />
      )}
      {mode === 'single' && <>{fields.map(renderSingleName)}</>}

      {mode === 'multi' && (
        <>
          {fields.map(renderMultiName)}
          <AddNameBlock onChange={onChange} fields={fields} />
        </>
      )}
    </div>
  );
};

export default connect(null, { change: changeAction })(NamesField);
