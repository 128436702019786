import { css } from '@emotion/react';
export const tourItemStyle = css`
  --calendar-fg-main: rgba(0, 0, 0, 0.9);
  --calendar-fg-header: ##fff;
  --calendar-bg-main: #fff;
  --calendar-bg-header: #ff5959;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 24px;
  row-gap: 8px;
`;

export const calendarStyle = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 20%;
  overflow: hidden;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  /* Text properties */
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  letter-spacing: -0.04em;
  font-feature-settings: 'cv11' on;
  color: var(--calendar-fg-main);
  background: var(--calendar-bg-main);
`;

export const calendarHeaderStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 0 3px;
  margin-bottom: 3px;
  /* Text properties */
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  font-feature-settings:
    'cv11' on,
    'cpsp' on;
  color: var(--calendar-fg-header);
  background: var(--calendar-bg-header);
`;

export const textColumnStyle = css`
  flex: 340px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const buttonStyle = css`
  flex-grow: 1;
  flex: 250px;
`;
