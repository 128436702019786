//
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const NavItemWrap = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${({ selected }) => (selected ? 'var(--bg-2)' : 'transparent')};
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  padding: 8px;
  &:hover::after {
    opacity: 0.1;
  }

  &:after {
    top: 0;
    left: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transition: opacity 0.1s;
    background-color: rgba(50, 50, 50);
    opacity: 0;
    will-change: opacity;
    height: 100%;
    width: 100%;
  }
`;
const Text = styled.div`
  font-family: Inter, Roboto;

  font-feature-settings: 'cv11' on;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;

export const NavItem = ({
  selected,
  onClick,
  icon,
  text,
  disabled,
  className,
  testId,
}: {
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
  className?: string;
  testId?: string;
}) => {
  return (
    <NavItemWrap
      selected={!!selected}
      data-test-id={testId}
      onClick={onClick}
      className={className + (disabled ? ' disabled' : '')}
    >
      <Icon>{icon}</Icon>

      <Text>{text}</Text>
    </NavItemWrap>
  );
};

const NavItemLink = ({
  to,
  icon,
  text,
  className,
  exact,
  strict,
  matchPath,
  testId,
}: {
  matchPath?: string;
  testId?: string;
  className?: string;
  text: string;
  icon: React.ReactNode;
  location?: Location;
  exact?: boolean;
  strict?: boolean;
  to: string;
}) => {
  const history = useHistory();

  return (
    <Route path={matchPath || to} exact={exact} strict={strict}>
      {({ match }: RouteComponentProps) => (
        <NavItem
          selected={!!match}
          testId={testId}
          icon={icon}
          text={text}
          onClick={() => {
            history.push(to);
          }}
          className={className}
        />
      )}
    </Route>
  );
};

export default NavItemLink;
