import React from 'react';
import Image from 'next/image';
import type { Release } from '../types/TemplateData';
import { AutosizeGrid } from '@imus/base-ui';
import styled from '@emotion/styled';
import { Text } from '@imus/services-ui';
import { css } from '@emotion/react';
import { useTranslation } from 'next-i18next';

const releaseItemStyled = css`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  gap: 12px;
  h4 {
    display: block;
    margin-bottom: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;

    width: 100%;
    height: 100%;
    padding: 8px;

    background-color: var(--fg-4);
    opacity: 0;
    border-radius: 20px 20px 10px 10px;
  }
  &:hover::before {
    opacity: 1;
  }
`;

const Cover = styled.div<{ imageSrc: string }>`
  position: relative;
  display: block;
  img {
    display: block;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 12px;
  }
`;

const ReleaseItem = ({
  release,
  onClick,
}: {
  release: Release;
  onClick: React.MouseEventHandler;
}) => {
  const { t } = useTranslation();
  const notReleased = Date.now() < new Date(release.releaseAt).valueOf();
  const coverUrl =
    release.cover?.url ||
    `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${release.pageKey}/cover.jpg`;
  return (
    <div css={releaseItemStyled} data-test-id="ReleaseItem" onClick={onClick}>
      <Cover imageSrc={coverUrl}>
        <Image width={400} height={400} src={coverUrl} alt="release-cover" />
      </Cover>
      <div>
        <Text.H4 data-test-id="ReleaseItem-title">{release.title}</Text.H4>
        <Text.BodyS>
          {release.releaseTypeId ? t(release.releaseTypeId) : null}
          {notReleased ? ` | ${t('coming-soon')}` : ''}
        </Text.BodyS>
      </div>
    </div>
  );
};

export const ReleaseGrid = ({
  releases,
  onSelect,
}: {
  releases: Array<Release>;
  onSelect: (releasePageKey: string, r: Release) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Text.H2>{t('releases')}</Text.H2>
      <AutosizeGrid gap={32} minWidth={180} maxColumnCount={3}>
        {releases.map((r, index) => (
          <ReleaseItem
            key={index}
            onClick={() => {
              onSelect(r.pageKey, r);
            }}
            release={r}
          />
        ))}
      </AutosizeGrid>
    </>
  );
};
