import { Button, Card, CardOption, H4, Window } from 'imdui';
import { useTranslation } from 'react-i18next';
import { MoneyIcon, AnalyticsIcon, ShareIcon } from '@imus/base-ui';
import styled from '@emotion/styled';
import { BodyM } from '@imus/services-ui/src/Text';
import { useEffect } from 'react';

const YearlyContent = styled.div`
  padding: 0 32px 32px;
  display: grid;
  gap: 32px;
  ${BodyM} {
    color: var(--fg-2);
  }
`;

const OptionCard = styled(Card)`
  border-radius: 16px;
  background: var(--bg-3, #f4f4f4);
`;
const Actions = styled.div`
  display: flex;
  gap: 16px;
  & > * {
    flex: 1;
  }
`;
export const WinbackYearlyWindowContent = ({
  onSubmit,
  isOpen,
  close,
  onDecline,
}: {
  isOpen: boolean;
  close: () => void;
  onSubmit: () => void;
  onDecline?: () => void;
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpen && window.analytics) {
      window.analytics.track('FT Yearly winback viewed');
    }
  }, [isOpen]);
  return (
    <>
      <Window
        title={t('redo-subscription')}
        isOpen={isOpen}
        style={{ maxWidth: '448px' }}
        close={close}
      >
        <YearlyContent>
          <BodyM>{t('redo-subscription-text')}</BodyM>
          <OptionCard>
            <CardOption>
              <MoneyIcon />
              <H4>{t('pay-lower-commission')}</H4>
              <div />
            </CardOption>
            <CardOption>
              <ShareIcon />
              <H4>{t('promote-your-brand')}</H4>
              <div />
            </CardOption>
            <CardOption>
              <AnalyticsIcon />
              <H4>{t('discover-potential')}</H4>
              <div />
            </CardOption>
          </OptionCard>
          <Actions>
            <Button
              onClick={() => {
                close();
                if (onDecline) {
                  onDecline();
                }
              }}
              text={t('keep-cancelation')}
            />
            <Button
              primary={true}
              onClick={() => {
                onSubmit();
              }}
              text={t('undo-cancelation')}
            />
          </Actions>
        </YearlyContent>
      </Window>
    </>
  );
};
