import { find } from 'ramda';
import { hasEmoji, validateArtistRoles } from '../../../helpers';

type Tag = { role: string };

const hasMainArtist = find((tag: Tag) => tag && tag.role === 'main');
const hasLyricist = find((tag: Tag) => tag && tag.role === 'lyricist');

export const validate = (
  values: any,
  {
    additionalValidation,
    targets,
    targets: {
      hasAllMetadata: {
        conditions: {
          hasCorrectCustomIsrc,
          customIsrcNotRegistered,
          hasUniqueCustomIsrc,
        } = {
          customIsrcNotRegistered: undefined,
          hasUniqueCustomIsrc: undefined,
          hasCorrectCustomIsrc: undefined,
        },
      },
    } = { hasAllMetadata: { conditions: {} } },
    ...props
  }: any
) => {
  const errors = additionalValidation
    ? additionalValidation(values, { targets, ...props })
    : {};

  const instrumentalError =
    values.isInstrumental &&
    (hasLyricist(values.contributors || []) || values.hasTraditionalLyricist)
      ? 'instrumental-track-doesnt-have-lyricist'
      : null;

  const explicitError =
    values.isInstrumental && values.hasExplicitContent
      ? 'instrumental-can-not-have-explicit-lyrics'
      : null;

  // hasUniqueCustomIsrc && hasUniqueCustomIsrc.status === 'warning'
  //       ? 'isrc-exists-in-library'
  //       :
  const uniqueIsrcError =
    hasUniqueCustomIsrc &&
    hasUniqueCustomIsrc.status === 'warning' &&
    hasUniqueCustomIsrc?.customData?.replacementId
      ? 'isrc-already-exist-in-library'
      : null;

  const alreadyRegisteredIsrc =
    customIsrcNotRegistered && customIsrcNotRegistered.status === 'failed'
      ? 'isrc-already-taken'
      : null;

  const correctCustomIsrcError =
    hasCorrectCustomIsrc && hasCorrectCustomIsrc.status === 'failed'
      ? 'incorrect-isrc'
      : null;

  errors.customIsrc = values.customIsrc
    ? correctCustomIsrcError || uniqueIsrcError || alreadyRegisteredIsrc
    : null;

  errors.contributors = instrumentalError || errors.contributors;
  errors.hasExplicitContent = explicitError;

  errors.artists =
    (values.artists?.length && !hasMainArtist(values.artists)
      ? 'add-main-artist'
      : validateArtistRoles(values)) || errors.artists;

  errors.publishers =
    values.publishers?.length === 0 &&
    values.isCoverVersion &&
    !values.hasNoPublishers
      ? 'required'
      : errors.publishers;

  errors.names = !values.names?.[0]?.title
    ? [{ title: 'required' }]
    : hasEmoji(values.names?.[0]?.title)
      ? [{ title: 'emojis-not-allowed' }]
      : errors.names;

  errors.copyrightText =
    values.copyrightText && hasEmoji(values.copyrightText)
      ? 'emojis-not-allowed'
      : errors.copyrightText;

  return errors;
};

export { default as AudioLanguageField } from './AudioLanguageField';
