import type { FormSubmitHandler } from 'redux-form';
import { FieldArray, Field, FormSection, reduxForm } from 'redux-form';
import type { ArtistCollectionFieldProps } from './components';
import {
  ArtistFilesField,
  EnabledRowField,
  Item,
  ItemCollection,
  ArtistCollectionField,
} from './components';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext, useMemo } from 'react';
import type { PressContact, PressQuote } from '@imus/artist-page-template';
import { Button, HelpWindowContext } from 'imdui';
import { InputField } from 'imdshared';
import { validateRequired } from './utils';
import { createMaxLengthValidation } from 'helpers/validation';

const PressQuoteForm = reduxForm<
  PressQuote,
  { onSubmit: FormSubmitHandler<PressQuote>; submitText: string }
>({ form: 'PressQuoteForm' })(({ handleSubmit, submitText, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Field
        name="message"
        component={InputField}
        label={t('quote-text')}
        multiline={true}
        validate={validateRequired}
      />
      <Field
        name="origin"
        component={InputField}
        label={t('quote-from')}
        validate={validateRequired}
      />
      <Button
        icon={undefined}
        text={submitText}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
});

const PressContactForm = reduxForm<
  PressContact,
  { onSubmit: FormSubmitHandler<PressContact>; submitText: string }
>({ form: 'ContactPressForm' })(({ handleSubmit, submitText, onSubmit }) => {
  const { t } = useTranslation();

  const validateName = useMemo(
    () => createMaxLengthValidation(200, t, true),
    []
  );
  const showHelp = useContext(HelpWindowContext);

  return (
    <>
      <Field
        name="name"
        component={InputField}
        label={t('contact-name')}
        validate={validateName}
      />
      <Field
        name="value"
        component={InputField}
        label={t('contact-value')}
        onClickHelp={() => {
          showHelp(t('contact-value'), t('contact-value-helptext'));
        }}
      />
      <Button
        icon={undefined}
        text={submitText}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
});

const pressKitAccept = {
  'image/jpeg': [],
  'image/png': [],
  'image/svg+xml': [],
  'text/csv': [],
  'text/plain': [],
  'application/pdf': [],
};

export function ArtistPagePressForm({ id }: { id: number }) {
  const { t } = useTranslation();
  return (
    <FormSection name="press" component={Fragment}>
      <ItemCollection>
        <Item>
          <Field
            name="enabled"
            title={t('enable-page')}
            component={EnabledRowField}
          />
        </Item>
      </ItemCollection>
      <FieldArray<ArtistCollectionFieldProps<PressContact>, PressContact>
        name="contacts"
        component={ArtistCollectionField}
        formatValueToItem={(v) => ({ label: v.name })}
        dragType={'PressContact'}
        addText={t('add-press-contact')}
        formComponent={PressContactForm}
      />

      <FieldArray
        name="pressFiles"
        accept={pressKitAccept}
        rerenderOnEveryChange={true}
        artistId={id}
        defaultDescription={t('press-file')}
        addText={t('add-press-file')}
        component={ArtistFilesField}
      />

      <FieldArray<ArtistCollectionFieldProps<PressQuote>, PressQuote>
        name="quotes"
        component={ArtistCollectionField}
        formatValueToItem={(v) => ({ label: v.origin })}
        dragType={'PressQuote'}
        addText={t('add-quote')}
        formComponent={PressQuoteForm}
      />
    </FormSection>
  );
}
